import type { Recipients } from '@assembly-web/services';
import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import {
  $applyNodeReplacement,
  type DOMExportOutput,
  type NodeKey,
  type SerializedLexicalNode,
} from 'lexical';
import type { PropsWithChildren } from 'react';
import type { Spread } from 'type-fest';

import { DepartmentList } from '../../../../DepartmentList';
import { GroupAndUserChip } from '../../../../GroupAndUserChip';
import { ProfileViewer } from '../../../../ProfileViewer/ProfileViewer';
import { BaseChipNode } from './BaseNode';
import { RemoveButton } from './RemoveButton';

export type UserChipNodeData =
  | Recipients['individuals'][number]
  | Recipients['department'][number];

export type SerializedUserChipNode = Spread<
  {
    id: string;
    name: string;
    data: UserChipNodeData;
  },
  SerializedLexicalNode
>;

function PopoverWrapper({
  data,
  children,
}: PropsWithChildren<{ data: UserChipNodeData }>) {
  if (data.type !== 'department') {
    return children;
  }

  return (
    <DepartmentList id={data.id} name={data.name}>
      {children}
    </DepartmentList>
  );
}

function ProfileCardWrapper({
  data,
  children,
}: PropsWithChildren<{ data: UserChipNodeData }>) {
  return (
    <ProfileViewer userDetails={data.type === 'member' ? data : null}>
      {children}
    </ProfileViewer>
  );
}

function Wrapper({
  children,
  data,
}: PropsWithChildren<{ data: UserChipNodeData }>) {
  const Comp = data.type === 'member' ? ProfileCardWrapper : PopoverWrapper;

  return <Comp data={data}>{children}</Comp>;
}

export class UserChipNode extends BaseChipNode {
  __data: UserChipNodeData;
  __id: string;
  __name: string;

  static clone(node: UserChipNode): UserChipNode {
    return new UserChipNode(node.__data, node.__key);
  }

  static importJSON(serializedNode: SerializedUserChipNode): UserChipNode {
    return $createUserChipNode(serializedNode.data);
  }

  constructor(data: UserChipNodeData, key?: NodeKey) {
    super(key);
    this.__id = data.type === 'member' ? data.memberID : data.name;
    this.__name = data.name;
    this.__data = data;
  }

  exportJSON(): SerializedUserChipNode {
    return {
      id: this.__id,
      name: this.__name,
      data: this.__data,
      type: 'combobox-chip',
      version: 1,
    };
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-combobox-chip', 'true');
    element.textContent = this.__data.name;
    return { element };
  }

  decorate() {
    return (
      <Wrapper data={this.__data}>
        <GroupAndUserChip
          name={this.__data.name}
          removeButton={<RemoveButton name={this.__name} node={this} />}
          {...(this.__data.type === 'member'
            ? {
                type: 'member',
                memberID: this.__data.memberID,
                memberState: this.__data.memberState,
                image: this.__data.image,
              }
            : this.__data.id === 'everyone'
              ? { type: 'everyone', count: this.__data.count }
              : { type: 'department', count: this.__data.count })}
        />
      </Wrapper>
    );
  }
}

export class UserTypeaheadOption extends MenuOption {
  __data: UserChipNodeData | Recipients['moreDepartmentOption'];

  constructor(data: UserChipNodeData | Recipients['moreDepartmentOption']) {
    super(data.type === 'member' ? data.memberID : data.name);
    this.__data = data;
  }
}

export function $createUserChipNode(
  data: UserChipNodeData | UserTypeaheadOption
): UserChipNode {
  const extractedData =
    data instanceof UserTypeaheadOption ? data.__data : data;

  if (extractedData.type === 'moreDepartmentOption') {
    throw new Error('Cannot create a chip node for more department option');
  }

  const chipNode = new UserChipNode(extractedData);
  return $applyNodeReplacement(chipNode);
}
