import type {
  AssemblyCurrency,
  FlowPostResponse,
  MemberDetails,
  ReactionDetails,
  RepliesResponse,
  UserDetails,
} from '@assembly-web/services';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { useMemo, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import type { AnnouncementBannerProps, PostCardActions } from '../../..';
import {
  classNames,
  getRespondentForAnonymousFlowPost,
  HorizontalRule,
  useToolbarState,
} from '../../..';
import type { ToolbarItem } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { Toolbar } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { AnnouncementBanner } from '../../Shared/PostCard/AnnouncementBanner';
import { PostCardBody } from '../../Shared/PostCard/PostCardBody';
import { PostCardFooter } from '../../Shared/PostCard/PostCardFooter';
import { PostCardHeader } from '../../Shared/PostCard/PostCardHeader';

const messages = defineMessages({
  goToFullPost: {
    defaultMessage: 'Go to full post',
    id: 'WUU79O',
  },
});

export type PostCardProps = {
  currency: AssemblyCurrency;
  flowResponse: FlowPostResponse;
  primaryToolbarItems: ToolbarItem[];
  reactions: ReactionDetails[];
  repliesData?: RepliesResponse;
  userDetails?: UserDetails;
  showFullPost?: boolean;
  canShowAnnouncements?: boolean;
  onReactionClick: (emoji: ReactionDetails) => void;
  onFileClick: (responseBlock: {
    blockId: string;
    fileName: string;
    fileType: string;
  }) => void;
  onPostCardHeaderClick: (action: PostCardActions, id: string) => void;
  onPostCardBodyClick: (action: PostCardActions, id: string) => void;
  onToolbarItemClick: (args: ToolbarItem) => void;
  onResponseToolbarItemClick?: (responseBlock: {
    blockId: string;
    fileName: string;
    itemId: string;
  }) => void;
  onReplyButtonClick: () => void;
  onAnnouncementDescriptionHover?: (args: AnnouncementBannerProps) => void;
  onGotoFullPostClick?: () => void;
  onMemberClick: (memberId: string) => void;
};

export function CarouselPostCard(props: PostCardProps) {
  const sectionRef = useRef<HTMLDivElement | null>(null);

  const { formatMessage } = useIntl();
  const {
    onMemberClick,
    flowResponse,
    reactions,
    repliesData,
    userDetails,
    onPostCardHeaderClick,
    onPostCardBodyClick,
    currency,
    onFileClick,
    onResponseToolbarItemClick,
    primaryToolbarItems,
    onToolbarItemClick,
    onReactionClick,
    onReplyButtonClick,
    showFullPost,
    canShowAnnouncements,
    onAnnouncementDescriptionHover,
    onGotoFullPostClick,
  } = props;

  let {
    respondent,
    createdAt,
    flow: { flowId },
    visibility,
    responses,
  } = flowResponse;

  respondent = respondent ?? getRespondentForAnonymousFlowPost();
  const isRecognition = flowId === 'recognition';

  const {
    flow: {
      name: flowName,
      icon: { value: flowIcon },
    },
  } = flowResponse;

  const currentUser = useMemo(() => {
    return userDetails
      ? ({
          memberID: userDetails.member.memberId,
          name: userDetails.member.profile.firstName,
          image: userDetails.member.profile.image,
          firstName: userDetails.member.profile.firstName,
          lastName: userDetails.member.profile.lastName,
          username: userDetails.member.profile.username,
          pointsGiven: userDetails.member.pointsGiven,
          totalPointsGiven: userDetails.member.totalPointsGiven,
          memberState: userDetails.member.memberState,
          role: userDetails.member.role,
          email: userDetails.member.email,
        } as MemberDetails)
      : undefined;
  }, [userDetails]);

  const showAnnouncementsBanner = canShowAnnouncements && flowResponse;

  const { getContainerProps, getToolbarProps } = useToolbarState();

  return (
    <div
      className={classNames(
        'group relative block cursor-auto overflow-hidden rounded-lg border border-gray-5 bg-gray-1',
        {
          'h-auto': showFullPost,
          'h-[308px]': !showFullPost,
        }
      )}
      ref={sectionRef}
      {...getContainerProps()}
    >
      {canShowAnnouncements && flowResponse.activeAnnouncement ? (
        <AnnouncementBanner
          color={flowResponse.activeAnnouncement.color}
          title={flowResponse.activeAnnouncement.title}
          description={flowResponse.activeAnnouncement.description}
          onAnnouncementDescriptionHover={onAnnouncementDescriptionHover}
        />
      ) : null}
      <div
        className={classNames('absolute right-[10px] top-3 m-0 w-16 p-1', {
          'top-[48px]': showAnnouncementsBanner,
        })}
      >
        <Toolbar
          primaryToolbarItems={primaryToolbarItems}
          secondaryToolbarItems={[]}
          {...getToolbarProps({
            onMenuItemClick(args) {
              onToolbarItemClick(args);
            },
          })}
        />
      </div>
      <div className="px-4 pb-2 pt-4">
        <PostCardHeader
          respondent={respondent}
          createdAt={createdAt}
          visibility={visibility}
          onPostCardHeaderClick={onPostCardHeaderClick}
          isEdited={Boolean(isRecognition) && responses[0].edited}
          flowId={flowId}
          flowName={flowName}
          flowIcon={flowIcon}
          showFlowDetails={false}
        />
        <div className={twMerge(!showFullPost && 'h-[200px] overflow-hidden')}>
          <PostCardBody
            onMemberClick={onMemberClick}
            onPostCardBodyClick={onPostCardBodyClick}
            flowResponse={flowResponse}
            currency={currency}
            onFileClick={onFileClick}
            onBlockToolbarItemClick={onResponseToolbarItemClick}
            showInCarousel
          />
        </div>
        {repliesData && currentUser ? (
          <>
            <HorizontalRule />
            <PostCardFooter
              showDraft={false}
              reactions={reactions}
              repliesData={repliesData}
              currentMember={currentUser}
              onReactionClick={onReactionClick}
              onReplyButtonClick={onReplyButtonClick}
              hideReplies={flowResponse.hideReplies ?? false}
              hideReactions={flowResponse.hideReactions ?? false}
            />
          </>
        ) : null}
      </div>
      {!showFullPost && (
        <button
          className="absolute bottom-[1px] left-[1px] right-0 flex h-[42px] w-[calc(100%-2px)] items-center justify-center bg-gray-1 text-sm text-gray-8 underline shadow-md-up"
          onClick={onGotoFullPostClick}
        >
          <span>{formatMessage(messages.goToFullPost)}</span>
          <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
        </button>
      )}
    </div>
  );
}
