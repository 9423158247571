// NOTE: the values of the enum are sent to the server, so don't change them
export enum SSOProvider {
  ADP = 'adp',
  Google = 'gSuite',
  Office365 = 'office365',
  Slack = 'slack',
  Lifion = 'lifion',
}

export function getProviderName(provider: SSOProvider) {
  return {
    [SSOProvider.Google]: 'Google',
    [SSOProvider.Office365]: 'Office 365',
    [SSOProvider.Slack]: 'Slack',
    [SSOProvider.ADP]: 'ADP Lyric',
    [SSOProvider.Lifion]: 'ADP Lyric',
  }[provider];
}

export function lookupSSOProvider(provider?: string) {
  return Object.values(SSOProvider).find(
    (value) => value.toLowerCase() === provider?.toLowerCase()
  );
}
