import { useMemo } from 'react';
import { type UIMatch, useMatches } from 'react-router-dom';

export function useRecognitionRoute() {
  const matches = useMatches();
  const lastMatch = matches[matches.length - 1] as UIMatch<
    unknown,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
    unknown | { route: 'recognition' }
  >;

  return (
    typeof lastMatch.handle === 'object' &&
    lastMatch.handle !== null &&
    'route' in lastMatch.handle &&
    lastMatch.handle.route === 'recognition'
  );
}

export function useRecognitionParticipationRoute() {
  const matches = useMatches();
  const lastMatch = matches[matches.length - 1];

  return useMemo(() => {
    return {
      isCreateFlow: lastMatch.pathname.includes('/flows/recognition/answer'),
      isEditFlow:
        lastMatch.pathname.includes('/flows/recognition/edit') &&
        Boolean(lastMatch.params.postId),
      editPostId: lastMatch.params.postId ?? '',
    };
  }, [lastMatch]);
}
