import './styles.css';

import type {
  FileUploadMetaData,
  FileUploadOptions,
} from '@assembly-web/services';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import type { UppyFile } from '@uppy/core';
import { useUppyEvent, useUppyState } from '@uppy/react';
import { useCallback, useMemo, useState } from 'react';
import { Button, DropZone, FileTrigger } from 'react-aria-components';
import { twJoin } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { defaultUploadOptions } from './config';
import { useUppyStore } from './useUploadStore';

export function useFileUpload({
  key,
  onFileAdded,
  getUploadUrlForFile,
  onRestrictionsError,
  uploadOptions: userUploadOptions = {},
  cropperOptions: userCropperOptions = {},
}: FileUploadOptions) {
  const [showImageEditor, setShowImageEditor] = useState(false);

  const { createOrGetUppyInstance } = useUppyStore();

  const uppyInstance = useMemo(() => {
    return createOrGetUppyInstance({
      key,
      getUploadUrlForFile,
      uploadOptions: userUploadOptions,
      cropperOptions: userCropperOptions,
    });
  }, [
    key,
    userUploadOptions,
    userCropperOptions,
    getUploadUrlForFile,
    createOrGetUppyInstance,
  ]);

  const uppyFiles = useUppyState(
    uppyInstance,
    (state) => state.files
  ) as Record<string, UppyFile<FileUploadMetaData, Record<string, never>>>;

  const uppyValuesArray = useMemo(() => {
    return Object.values(uppyFiles);
  }, [uppyFiles]);

  useUppyEvent(uppyInstance, 'file-added', (file) => {
    file.meta.location = key;

    if (file.data instanceof File) {
      onFileAdded?.(file.data);
    }
  });

  useUppyEvent(uppyInstance, 'file-editor:cancel', (file) => {
    const uploadOptions = {
      ...defaultUploadOptions,
      ...userUploadOptions,
    };

    if (!uploadOptions.allowMultipleFiles) {
      uppyValuesArray.forEach((existingFile) => {
        if (existingFile.id === file.id) {
          uppyInstance.removeFile(existingFile.id);
        }
      });
    }

    uppyInstance.upload();
  });

  useUppyEvent(uppyInstance, 'file-editor:complete', (file) => {
    const uploadOptions = {
      ...defaultUploadOptions,
      ...userUploadOptions,
    };

    if (!uploadOptions.allowMultipleFiles) {
      uppyValuesArray.forEach((existingFile) => {
        if (existingFile.id !== file.id) {
          uppyInstance.removeFile(existingFile.id);
        }
      });
    }

    uppyInstance.upload();
  });

  useUppyEvent(uppyInstance, 'restriction-failed', async (_, error) => {
    setShowImageEditor(false);
    if (onRestrictionsError) {
      onRestrictionsError(error);
    }
  });

  useUppyEvent(uppyInstance, 'error', async (error) => {
    setShowImageEditor(false);
    if (onRestrictionsError) {
      onRestrictionsError(error);
    }
  });

  const {
    autoUpload,
    allowMultipleFiles,
    showImageEditor: showUppyImageEditor,
  } = useMemo(() => {
    return {
      ...defaultUploadOptions,
      ...userUploadOptions,
    };
  }, [userUploadOptions]);

  const DragAndDrop = useCallback(
    ({
      title,
      subTitle,
      disabled = false,
    }: {
      title: string;
      subTitle: string;
      disabled?: boolean;
    }) => {
      const [isDropping, setIsDropping] = useState(false);

      return (
        <DropZone
          className={twJoin(
            'flex w-full rounded-lg border-2 border-dashed border-primary-6 p-6 text-center',
            isDropping ? 'bg-gray-3' : 'bg-gray-1',
            disabled && 'cursor-not-allowed opacity-50'
          )}
          onDropEnter={() => {
            setIsDropping(true);
          }}
          onDropExit={() => {
            setIsDropping(false);
          }}
          onDrop={(event) => {
            if (showUppyImageEditor) {
              setShowImageEditor(true);
            }

            setTimeout(async () => {
              for (const item of event.items) {
                if (item.kind === 'file') {
                  const file = await item.getFile();
                  uppyInstance.addFile({
                    data: file,
                    name: file.name,
                    type: file.type,
                  });
                }
              }

              if (autoUpload) {
                await uppyInstance.upload();
              }
            }, 0);
          }}
        >
          <FileTrigger
            allowsMultiple={allowMultipleFiles}
            onSelect={async (files) => {
              if (showUppyImageEditor) {
                setShowImageEditor(true);
              }
              setTimeout(async () => {
                if (files instanceof FileList) {
                  for (const file of files) {
                    uppyInstance.addFile({
                      data: file,
                      name: file.name,
                      type: file.type,
                    });
                  }

                  if (autoUpload) {
                    await uppyInstance.upload();
                  }
                }
              }, 0);
            }}
          >
            <Button
              className={twJoin(
                'w-full',
                disabled && 'cursor-not-allowed opacity-50'
              )}
              isDisabled={disabled}
            >
              <div className="flex items-center justify-center gap-2">
                <ArrowUpTrayIcon className="h-4 w-4 text-primary-6" />
                <TextStyle variant="sm-medium" className="text-gray-8">
                  {title}
                </TextStyle>
              </div>
              <TextStyle variant="xs-regular" className="text-gray-8">
                {subTitle}
              </TextStyle>
            </Button>
          </FileTrigger>
        </DropZone>
      );
    },
    [allowMultipleFiles, autoUpload, showUppyImageEditor, uppyInstance]
  );

  return {
    add: useCallback(
      (file: File) => uppyInstance.addFile(file),
      [uppyInstance]
    ),
    clear: useCallback(() => {
      uppyInstance.clear();
    }, [uppyInstance]),
    removeFile: useCallback(
      (fileId: string) => uppyInstance.removeFile(fileId),
      [uppyInstance]
    ),
    upload: useCallback(() => uppyInstance.upload(), [uppyInstance]),
    DragAndDrop,
    showImageEditor,
    setShowImageEditor,
    files: uppyValuesArray,
  };
}
