import {
  type AriaAttributes,
  type ButtonHTMLAttributes,
  type DetailedHTMLProps,
  forwardRef,
  type Ref,
} from 'react';
import { twMerge } from 'tailwind-merge';

import { Tooltip } from '../Feedback/Tooltip';

export const IconButtonVariations = [
  'primary',
  'secondaryEmphasized',
  'secondaryLite',
  'tertiaryEmphasized',
  'tertiaryLite',
  'danger',
  'success',
] as const;

export const ButtonSize = ['small', 'large', 'xSmall', 'xLarge'] as const;

export type IconButtonProps = Omit<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'ref'
> &
  AriaAttributes & {
    variation?: (typeof IconButtonVariations)[number];
    size?: (typeof ButtonSize)[number];
    tooltip?: string;
  };

export const IconButton = forwardRef(
  (props: IconButtonProps, ref: Ref<HTMLButtonElement>) => {
    const {
      className,
      children,
      disabled = false,
      variation = 'primary',
      size = 'large',
      tooltip,
      ...buttonProps
    } = props;

    const isPrimaryVariant = variation === 'primary';
    const isSecondaryEmphasized = variation === 'secondaryEmphasized';
    const isSecondaryLite = variation === 'secondaryLite';
    const isTertiaryEmphasizedVariant = variation === 'tertiaryEmphasized';
    const isTertiaryLiteVariant = variation === 'tertiaryLite';
    const isDangerVariant = variation === 'danger';
    const isSuccessVariant = variation === 'success';

    const isSizeLarge = size === 'large';
    const isSizeSmall = size === 'small';
    const isSizeXSmall = size === 'xSmall';
    const isSizeXLarge = size === 'xLarge';

    const buttonClasses = twMerge(
      'inline-flex items-center justify-center gap-2 rounded-[8px] focus:bg-gray-3 enabled:hover:bg-gray-3 disabled:cursor-not-allowed dark:bg-gray-9 dark:hover:bg-gray-8 dark:focus:bg-gray-8',
      isSizeLarge && 'h-10 w-10 p-[7px]',
      isSizeSmall && 'h-8 w-8 p-[3px]',
      isSizeXSmall && 'h-6 w-6 p-[3px]',
      isSizeXLarge && 'h-8 w-8 p-0',
      isPrimaryVariant &&
        !disabled &&
        'border border-primary-6 bg-primary-6 text-gray-1 hover:border-transparent hover:!bg-primary-7 focus:!bg-primary-7 dark:border-none dark:bg-primary-4 dark:text-primary-9 dark:hover:!bg-primary-3 dark:focus:!bg-primary-5 dark:focus:!ring-primary-4',
      isSecondaryEmphasized &&
        'border border-primary-6 bg-gray-1 text-primary-6 dark:border-primary-3 dark:text-primary-3',
      isSecondaryEmphasized &&
        disabled &&
        '!border border-solid !border-gray-5 bg-gray-2',
      isSecondaryLite &&
        'border border-gray-5 bg-gray-1 text-gray-8 dark:border-gray-4 dark:text-gray-2 ',
      isTertiaryEmphasizedVariant &&
        'bg-gray-1 font-normal text-primary-6 dark:text-primary-3',
      isTertiaryLiteVariant &&
        'bg-gray-1 font-normal text-gray-8 dark:text-gray-2 ',
      isDangerVariant &&
        !disabled &&
        'bg-error-7 text-gray-1 focus:ring-error-8 enabled:hover:!bg-error-8 focus:!bg-error-8',
      isSuccessVariant &&
        !disabled &&
        'bg-success-7 text-gray-1 focus:ring-success-8 enabled:hover:!bg-success-8 focus:!bg-success-8',
      disabled &&
        'bg-gray-2 !text-gray-7 dark:!bg-gray-8 dark:!text-gray-6 dark:hover:!bg-gray-8',
      className
    );

    return tooltip ? (
      <Tooltip tooltipText={tooltip}>
        <button
          className={buttonClasses}
          disabled={disabled}
          aria-label="iconButton"
          ref={ref}
          {...buttonProps}
        >
          {children}
        </button>
      </Tooltip>
    ) : (
      <button
        className={buttonClasses}
        disabled={disabled}
        aria-label="iconButton"
        ref={ref}
        {...buttonProps}
      >
        {children}
      </button>
    );
  }
);

IconButton.displayName = 'IconButton';
