import { defineMessages, useIntl } from 'react-intl';

import { TextStyle } from '../Feedback/TextStyle';

const messages = defineMessages({
  percentage: {
    defaultMessage: '{percentage}%',
    id: 'qnonu0',
  },
});

export function GaugeChart({
  percentage,
  subtitle,
}: {
  percentage: number;
  subtitle: string;
}) {
  const { formatMessage } = useIntl();
  const arc = describeArc(100, 100, 90, 0, percentage * 1.8);
  const progressBarColor = percentage > 25 ? '#ADC6FF' : '#FFBB96';
  const gaugeColor = percentage > 25 ? '#E8E8E8' : '#FFF2E8';

  return (
    <div className="relative flex flex-col items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="200"
        height="110"
        viewBox="0 0 200 110"
        fill="none"
      >
        <path
          d="M190 100C190 88.181 187.672 76.4778 183.149 65.5585C178.626 54.6392 171.997 44.7177 163.64 36.3604C155.282 28.0031 145.361 21.3738 134.442 16.8508C123.522 12.3279 111.819 10 100 10C88.181 9.99999 76.4779 12.3279 65.5585 16.8508C54.6392 21.3737 44.7177 28.0031 36.3604 36.3604C28.0031 44.7176 21.3738 54.6391 16.8509 65.5584C12.3279 76.4777 10 88.181 10 100"
          stroke={gaugeColor}
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d={arc}
          stroke={progressBarColor}
          strokeWidth="20"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div className="absolute left-1/2 top-1/2 mt-4 flex w-32 -translate-x-1/2 -translate-y-1/2 transform flex-col items-center">
        <TextStyle as="span" variant="3xl-medium" className="position">
          {formatMessage(messages.percentage, { percentage })}
        </TextStyle>
        <TextStyle
          as="span"
          variant="xs-regular"
          subdued
          className="-mt-1 text-center"
        >
          {subtitle}
        </TextStyle>
      </div>
    </div>
  );
}

function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) {
  const angleInRadians = ((angleInDegrees - 180) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(' ');

  return d;
}
