import type { GifRatingsProps } from '@assembly-web/services';
import * as Popover from '@radix-ui/react-popover';

import { useRefContainer } from '../../../../../context/RefContext';
import { IconButton } from '../../../../../DesignSystem/Inputs/IconButton';
import { FormattingIcon } from '../../../../assets/icons';
import { EmojiButton } from '../../base/components/EmojiButton';
import { GIFButton } from '../../base/components/GIFButton';
import { MentionButton } from '../../base/components/MentionButton';
import { RepliesHeaderPlugin } from '../../RepliesEditor/plugins/RepliesHeaderPlugin';

export type FooterProps = {
  gifRating: GifRatingsProps;
  giphyAPIKey: string;
};

function Formatter() {
  const container = useRefContainer();

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <IconButton variation="tertiaryLite" size="large">
          <img className="h-6 w-6" src={FormattingIcon} alt="" />
        </IconButton>
      </Popover.Trigger>
      <Popover.Portal container={container}>
        <Popover.Content
          className="z-50 bg-gray-1"
          align="start"
          side="top"
          sideOffset={8}
        >
          <RepliesHeaderPlugin
            isVisible
            hideBottomBorder
            className="rounded shadow-md-down"
            dropdownAlignmentOptions={{ side: 'top', align: 'end' }}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

export function Footer({ gifRating, giphyAPIKey }: FooterProps) {
  return (
    <footer className="mt-auto flex items-center justify-between">
      <div className="flex items-center gap-1">
        <Formatter />
        <GIFButton {...{ gifRating, giphyAPIKey }} />
        <EmojiButton />
        <MentionButton />
      </div>
    </footer>
  );
}
