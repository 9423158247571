import { queryOptions, useQuery } from '@tanstack/react-query';

import { APIEndpoints } from '../../APIEndpoints';
import { assemblyAPI } from '../../assemblyAPI';

export function getDepartmentsQuery({
  forGroup = 'recognition',
  searchTerm = '',
}: { searchTerm?: string; forGroup?: string } = {}) {
  return queryOptions({
    queryKey: ['getDepartments', searchTerm, forGroup],
    queryFn: async ({ signal }) => {
      const { data } = await assemblyAPI.get<{
        data: {
          id: string;
          name: string;
          memberCount: number;
        }[];
      }>(APIEndpoints.getGroups, {
        params: {
          limit: 99,
          search: searchTerm,
          for: forGroup,
        },
        signal,
      });

      return data.data;
    },
  });
}

export const useGetDepartmentsQuery = ({
  search,
  for: forGroup,
}: {
  for?: string;
  search?: string;
} = {}) => {
  return useQuery(getDepartmentsQuery({ forGroup, searchTerm: search }));
};
