import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { useTimeOfDay } from '../../hooks/useTimeOfDay';

export type GreetingProps = {
  firstName: string;
  hour: number;
};

const message = defineMessages({
  greeting: {
    defaultMessage:
      '{timeOfDay, select, morning {<emoji>☕</emoji>} afternoon {<emoji>☀️</emoji>} evening {<emoji>🌙</emoji>} other {<emoji>🌙</emoji>}} Good {timeOfDay, select, morning {Morning} afternoon {Afternoon} evening {Evening} other {Night}}, {firstName}!',
    id: '7fViJf',
  },
});

export function Greeting({
  firstName,
  hour,
}: {
  firstName: string;
  hour: number;
}) {
  const { formatMessage } = useIntl();

  const timeOfDay = useTimeOfDay(hour);

  return (
    <TextStyle variant="lg-bold">
      {formatMessage(message.greeting, {
        firstName,
        timeOfDay,
        emoji: (content: ReactNode) => (
          <span role="presentation" className="font-['Segoe_UI_Emoji']">
            {content}
          </span>
        ),
      })}
    </TextStyle>
  );
}
