import type { PropsWithChildren, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

function Container({ children }: PropsWithChildren) {
  return <footer className="flex flex-col gap-4 px-6 pb-4">{children}</footer>;
}

function PointsStatus({
  currency,
  totalPoints,
  totalRecipients,
}: {
  currency: ReactNode;
  totalPoints: number;
  totalRecipients: ReactNode;
}) {
  return (
    <div className="flex-shrink-0 self-end text-sm font-normal text-gray-8">
      <FormattedMessage
        defaultMessage="Giving {currency} <b>{totalPoints}</b> to <b>{totalRecipients} people</b>"
        id="QUB44g"
        tagName="p"
        values={{
          currency,
          totalPoints,
          totalRecipients,
          b: (chunks: ReactNode) => {
            return <span className="font-bold">{chunks}</span>;
          },
        }}
      />
    </div>
  );
}

function ActionRow({ children }: PropsWithChildren) {
  return (
    <div className="flex flex-col items-center justify-center gap-4 md:flex-row md:justify-between">
      {children}
    </div>
  );
}

export const Footer = {
  ActionRow,
  Container,
  PointsStatus,
};
