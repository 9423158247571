import { createContext, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  FilePreviewer,
  messages,
  type PreviewerFileType,
} from './FilePreviewer';

type ModalProps = {
  file: Omit<PreviewerFileType, 'goToPostTooltip'>;
  showGoToPost?: boolean;
  isChallengeClaim?: boolean;
};

export const FilePreviewerContext = createContext<{
  openModal: (props: ModalProps) => void;
}>({
  openModal: () => {},
});

export const FilePreviewerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { formatMessage } = useIntl();

  const [modalProps, setModalProps] = useState<ModalProps | undefined>();

  const closeModal = () => setModalProps(undefined);

  const handleGoToPost = () => {
    modalProps?.file.handleGoToPost();
    closeModal();
  };

  return (
    <FilePreviewerContext.Provider value={{ openModal: setModalProps }}>
      {modalProps?.file ? (
        <FilePreviewer
          file={{
            ...modalProps.file,
            handleGoToPost,
            goToPostTooltip: formatMessage(
              modalProps.isChallengeClaim
                ? messages.goToChallengeClaim
                : messages.goToPost
            ),
            handleSharedInClick: () => {
              modalProps.file.handleSharedInClick();
              closeModal();
            },
          }}
          isOpen
          onClose={closeModal}
        />
      ) : null}
      {children}
    </FilePreviewerContext.Provider>
  );
};
