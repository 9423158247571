export const SocketEvents = {
  flow: {
    archived: 'FLOW_ARCHIVED',
    unarchived: 'FLOW_UNARCHIVED',
    published: 'FLOW_PUBLISHED',
    read: 'FLOW_FEED_VISITED',
    edited: 'FLOW_EDITED',
  },

  collection: {
    created: 'COLLECTION_CREATED',
    updated: 'COLLECTION_UPDATED',
    deleted: 'COLLECTION_DELETED',

    item: {
      updated: 'COLLECTION_ITEM_UPDATED',
      deleted: 'COLLECTION_ITEM_DELETED',
    },

    access: {
      deleted: 'COLLECTION_ACCESS_DELETED',
      added: 'COLLECTION_ACCESS_ADDED',
    },
  },

  points: {
    given: 'GIVEN_POINTS',
    redeemed: 'POINTS_REDEEMED',
    recieved: 'RECEIVED_POINTS',
  },
} as const;
