import { isUserAdmin, isUserManager } from './inviteUserUtils';
import {
  AllowanceType,
  type Member,
  type MemberAPIResponse,
  MemberStatus,
  PrivateMessageAndImpactLevelValues,
} from './types/domain';

export function canUserDeletePost(userDetails: MemberAPIResponse) {
  const {
    member: { role },
    assembly: {
      settings: {
        canDeletePosts: { enabled },
      },
    },
  } = userDetails;

  return isUserAdmin({ role }) && enabled;
}

export const isPostImpactSettingEnabledForCurrentUser = (
  settingValue: PrivateMessageAndImpactLevelValues,
  currentUser: MemberAPIResponse
) => {
  if (
    settingValue === PrivateMessageAndImpactLevelValues.Everyone ||
    isUserAdmin({ role: currentUser.member.role })
  ) {
    return true;
  }
  if (
    settingValue === PrivateMessageAndImpactLevelValues.ManagerOnly &&
    isUserManager(currentUser.member)
  ) {
    return true;
  }
  if (
    settingValue ===
    PrivateMessageAndImpactLevelValues.ManagerAndCustomAllowancesOnly
  ) {
    if (isUserManager(currentUser.member)) {
      return true;
    }
    const allowanceType = currentUser.member.allowance.type;
    if (allowanceType === AllowanceType.Custom) {
      return true;
    }
    return false;
  }
  if (
    settingValue === PrivateMessageAndImpactLevelValues.AdminsOnly &&
    isUserAdmin({ role: currentUser.member.role })
  ) {
    return true;
  }
  return false;
};

export function getUserMaxGivingPointsPerPost(userDetails: MemberAPIResponse) {
  const {
    member: {
      allowance: { points },
      pointsLeftThisCycle,
    },
    assembly: {
      settings: {
        postImpactLevel: {
          value: { customType, levels },
        },
      },
    },
  } = userDetails;
  const canGiveCustomPoints = isPostImpactSettingEnabledForCurrentUser(
    customType,
    userDetails
  );
  let percentage;
  if (canGiveCustomPoints) {
    percentage = 100;
  } else {
    percentage = levels[4].percentage;
  }
  return Math.min(
    Math.floor(pointsLeftThisCycle),
    Math.floor((points * percentage) / 100)
  );
}

export function hasPermissionToGiveRecognition(member: Member) {
  const { status, allowance } = member;
  const isObserverOrReceiver =
    status.includes(MemberStatus.Observer) ||
    status.includes(MemberStatus.Receiver);

  const hasNoAllowance = allowance.type === AllowanceType.NoAllowance;
  const hasCustomAllowanceWithZeroPoints =
    allowance.type === AllowanceType.Custom && allowance.points === 0;

  return !(
    isObserverOrReceiver ||
    hasNoAllowance ||
    hasCustomAllowanceWithZeroPoints
  );
}

export function getMemberDetailsFromUserDetails(
  currentUserDetails: Member
): Omit<Member, 'anonymousIdentifier'> {
  return {
    memberID: currentUserDetails.memberId,
    name: currentUserDetails.profile.firstName,
    image: currentUserDetails.profile.image,
    firstName: currentUserDetails.profile.firstName,
    lastName: currentUserDetails.profile.lastName,
    username: currentUserDetails.profile.username,
    pointsGiven: currentUserDetails.pointsGiven,
    totalPointsGiven: currentUserDetails.totalPointsGiven,
    memberState: currentUserDetails.memberState,
    role: currentUserDetails.role,
    email: currentUserDetails.email,
    memberId: currentUserDetails.memberId,
    status: currentUserDetails.status,
    state: currentUserDetails.state,
    profile: currentUserDetails.profile,
    timeZone: currentUserDetails.timeZone,
    managerIds: currentUserDetails.managerIds,
    reportIds: currentUserDetails.reportIds,
    activatedAt: currentUserDetails.activatedAt,
    isFreeEmail: currentUserDetails.isFreeEmail,
    pointsEarned: currentUserDetails.pointsEarned,
    pointsLeftThisCycle: currentUserDetails.pointsLeftThisCycle,
    allowance: currentUserDetails.allowance,
    permissions: currentUserDetails.permissions,
  };
}
