import type { PreviewerFileType } from '../FilePreviewer';

export const useGetFileDetails = ({
  fileName,
  fileSize,
  locationUrl,
}: PreviewerFileType) => {
  const isImageUrl = /\.(jpe?g|png|gif|bmp|webp)$/i.test(fileName);
  const isVideo = /\.(mp4|m4v|mov|wmv|avi|mpg|ogv|3gp|3g2|ogg|webm)$/i.test(
    fileName
  );
  const isPDF = /\.(pdf)$/i.test(fileName);

  return {
    src: locationUrl,
    fileName,
    isImageUrl,
    fileSize,
    isVideo,
    isPDF,
  };
};
