import {
  Arrow,
  Content,
  Portal,
  Provider,
  Root,
  Trigger,
} from '@radix-ui/react-tooltip';
import { type ReactNode, useContext } from 'react';
import { twMerge } from 'tailwind-merge';

import { TooltipEnabledContext } from '../../context/TooltipEnabledContext';

export type TooltipProps = {
  children: ReactNode;
  tooltipText?: ReactNode;
  side?: Parameters<typeof Content>[0]['side'];
  align?: Parameters<typeof Content>[0]['align'];
  contentClassName?: string;
  textClassName?: string;
  showArrow?: boolean;

  /** Whether the tooltip should be open by default. Useful for debugging during development */
  defaultOpen?: boolean;
};

export function Tooltip({
  children,
  tooltipText,
  side,
  align,
  contentClassName,
  textClassName,
  showArrow,
  defaultOpen,
}: TooltipProps) {
  const isToolTipEnabled = useContext(TooltipEnabledContext);

  if (!tooltipText || !isToolTipEnabled) {
    return <>{children}</>;
  }

  return (
    <Provider delayDuration={100}>
      <Root defaultOpen={defaultOpen}>
        <Trigger asChild>{children}</Trigger>
        <Portal>
          <Content
            className={twMerge(
              'z-50 inline-flex max-w-[250px] items-center rounded-md bg-gray-9 px-4 py-2 text-xs text-gray-1 shadow-2xl-down',
              contentClassName
            )}
            side={side}
            align={align}
            sideOffset={6}
          >
            {Boolean(showArrow) && <Arrow />}
            <span className={twMerge('break-word', textClassName)}>
              {tooltipText}
            </span>
          </Content>
        </Portal>
      </Root>
    </Provider>
  );
}
