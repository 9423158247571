import type { AssemblyCurrency, GifRatingsProps } from '@assembly-web/services';
import {
  CheckIcon,
  PaperAirplaneIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { $getRoot, COMMAND_PRIORITY_EDITOR } from 'lexical';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../../../DesignSystem/Feedback/Tooltip';
import { Button } from '../../../../../DesignSystem/Inputs/Button';
import { FormattingIcon } from '../../../../assets/icons';
import { useDeviceInfo } from '../../../../hooks/useDeviceInfo';
import { BoostButton } from '../../base/components/BoostButton';
import { EmojiButton } from '../../base/components/EmojiButton';
import { GIFButton } from '../../base/components/GIFButton';
import { MentionButton } from '../../base/components/MentionButton';
import { ToggleButton } from '../../base/components/ToggleButton';
import { POINTS_EXHAUSTED_COMMAND } from '../../base/plugins/MentionsPlugin';
import { getSerializedDataFromEditor } from '../../base/utils';
import { resetEditor } from '../../base/utils/resetEditor';
import type { BoostOptions } from '../RepliesEditor';

export type RepliesValidatorError = 'HAS_PROFANITY' | 'MAX_LENGTH_REACHED';

export type Boost = {
  points: number;
  memberId: string;
};

export type EditorData = {
  json: string;
  html: string;
  boost?: Boost[];
  plainText: string;
  gifUrls?: string[];
  linkUrls?: string[];
  mentionIds?: string[];
  errors?: RepliesValidatorError[];
};

type RepliesFooterPluginProps = {
  value?: string;
  disabled?: boolean;
  giphyAPIKey: string;
  isEditing?: boolean;
  onCancelClick: () => void;
  gifRating: GifRatingsProps;
  currency?: AssemblyCurrency;
  disablePostButton?: boolean;
  isAnonymousReply?: boolean;
  hideToolbarButtons: boolean;
  postButtonTooltipText?: string;
  isFormattingToolbarVisible: boolean;
  onReplyClick: (data: EditorData) => void;
  validator: (text: string) => RepliesValidatorError[];
  setIsFormattingToolbarVisible: (show: boolean) => void;
  boostOptions: BoostOptions;
};

type FormatToggleProps = Pick<
  RepliesFooterPluginProps,
  'disabled' | 'isFormattingToolbarVisible' | 'setIsFormattingToolbarVisible'
>;

const messages = defineMessages({
  toggleFormattingToolbarLabel: {
    defaultMessage:
      '{formatting, select, true {Hide} false {Show} other {Show}} formatting toolbar',
    id: 'rhwxFZ',
  },
  replyButtonLabel: {
    defaultMessage: 'Reply Button',
    id: '9xyhVm',
  },
  shiftEnterLabel: {
    defaultMessage: 'Shift + Return for a line break',
    id: 'uXBahx',
  },
});

function PostReplyButton({
  postButtonTooltipText,
  disablePostButton,
  disabled,
  handleReplyClick,
  hasText,
}: Pick<
  RepliesFooterPluginProps,
  'postButtonTooltipText' | 'disablePostButton' | 'disabled'
> & {
  hasText: boolean;
  handleReplyClick: () => void;
}) {
  const { formatMessage } = useIntl();
  if (postButtonTooltipText) {
    return (
      <Tooltip tooltipText={postButtonTooltipText}>
        <Button
          variation="primary"
          onClick={handleReplyClick}
          className="h-10 w-10 !p-2"
          disabled={true}
          aria-label={postButtonTooltipText}
        >
          <PaperAirplaneIcon className="h-6 w-6" />
        </Button>
      </Tooltip>
    );
  }
  return (
    <Button
      variation="primary"
      onClick={handleReplyClick}
      className="h-10 w-10 !p-2"
      disabled={disablePostButton ?? disabled ?? !hasText}
      aria-label={formatMessage(messages.replyButtonLabel)}
    >
      <PaperAirplaneIcon className="h-6 w-6" />
    </Button>
  );
}

function EditPostButtons({
  handleCancelClick,
  handleReplyClick,
}: {
  handleReplyClick: () => void;
  handleCancelClick: () => void;
}) {
  const { formatMessage } = useIntl();
  return (
    <div className="flex gap-2">
      <Button
        variation="danger"
        onClick={handleCancelClick}
        className="h-10 w-10 !p-2"
        aria-label={formatMessage(messages.replyButtonLabel)}
      >
        <XMarkIcon className="h-6 w-6" />
      </Button>
      <Button
        variation="success"
        onClick={handleReplyClick}
        className="h-10 w-10 bg-success-7 !p-2 focus:bg-success-8 enabled:hover:bg-success-8"
        aria-label={formatMessage(messages.replyButtonLabel)}
      >
        <CheckIcon className="h-6 w-6 stroke-gray-1" />
      </Button>
    </div>
  );
}

function FormatToggle({
  disabled,
  isFormattingToolbarVisible,
  setIsFormattingToolbarVisible,
}: FormatToggleProps) {
  const { formatMessage } = useIntl();

  const [editor] = useLexicalComposerContext();

  const formattingToolbarLabel = formatMessage(
    messages.toggleFormattingToolbarLabel,
    {
      formatting: isFormattingToolbarVisible,
    }
  );

  return (
    <Tooltip tooltipText={formattingToolbarLabel}>
      <ToggleButton
        aria-label={formatMessage(messages.toggleFormattingToolbarLabel, {
          formatting: isFormattingToolbarVisible,
        })}
        disabled={disabled ?? editor.getEditorState().isEmpty()}
        onPressedChange={(pressed) => {
          setIsFormattingToolbarVisible(pressed);
        }}
        className={twMerge(
          'h-10 w-10 rounded-lg !p-2 hover:bg-gray-3',
          isFormattingToolbarVisible && 'bg-gray-4'
        )}
      >
        <img className="h-6 w-6" src={FormattingIcon} alt="" />
      </ToggleButton>
    </Tooltip>
  );
}

export function RepliesFooterPlugin({
  disabled,
  currency,
  gifRating,
  isEditing,
  validator,
  giphyAPIKey,
  onReplyClick,
  onCancelClick,
  isAnonymousReply,
  disablePostButton,
  hideToolbarButtons,
  postButtonTooltipText,
  isFormattingToolbarVisible,
  setIsFormattingToolbarVisible,
  boostOptions,
}: Readonly<RepliesFooterPluginProps>) {
  const [editor] = useLexicalComposerContext();
  const { formatMessage } = useIntl();

  const [hasText, setHasText] = useState(false);
  const [boostExhausted, setBoostExhausted] = useState(false);

  const { deviceType } = useDeviceInfo();

  const handleReplyClick = () => {
    editor.update(() => {
      const {
        html,
        json,
        mentionIds,
        plainText,
        gifUrls,
        linkUrls,
        boostedUsers,
      } = getSerializedDataFromEditor(editor);

      const errors = validator(plainText);
      if (errors.length === 0) {
        resetEditor(editor);
      }
      onReplyClick({
        json,
        html,
        plainText,
        mentionIds,
        errors,
        gifUrls,
        linkUrls,
        boost: boostedUsers,
      });
    });
  };

  const handleCancelClick = () => {
    resetEditor(editor);
    onCancelClick();
  };

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        const stringifiedEditorState = JSON.stringify(editorState.toJSON());
        const parsedEditorState = editor.parseEditorState(
          stringifiedEditorState
        );
        const editorStateTextString = parsedEditorState.read(() =>
          $getRoot().getTextContent()
        );

        setHasText(editorStateTextString.length > 0);
      }),
      editor.registerCommand(
        POINTS_EXHAUSTED_COMMAND,
        ({ exhausted }) => {
          setBoostExhausted(exhausted);

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  const canShowShiftEnterLabel =
    !disabled && !hideToolbarButtons && !isEditing && deviceType !== 'mobile';

  return (
    <div
      className={twJoin(
        'flex flex-shrink-0 items-center justify-between',
        disabled && 'bg-gray-2'
      )}
    >
      <div>
        {Boolean(canShowShiftEnterLabel) && (
          <TextStyle
            variant="xs-regular"
            className="pointer-events-none ml-2 mt-3 select-none text-gray-8"
          >
            {formatMessage(messages.shiftEnterLabel)}
          </TextStyle>
        )}
      </div>
      <div className="flex justify-end gap-x-2 px-1 py-1">
        {!hideToolbarButtons && (
          <>
            <MentionButton disabled={disabled} />
            {!boostOptions.hideBoost && (
              <BoostButton
                {...{
                  boostExhausted,
                  boostOptions,
                  currency,
                  disabled,
                  isAnonymousReply,
                }}
              />
            )}
            <EmojiButton disabled={disabled} />
            <GIFButton {...{ gifRating, giphyAPIKey, disabled }} />
            <FormatToggle
              {...{
                disabled,
                isFormattingToolbarVisible,
                setIsFormattingToolbarVisible,
              }}
            />
          </>
        )}
        {isEditing ? (
          <EditPostButtons
            handleReplyClick={handleReplyClick}
            handleCancelClick={handleCancelClick}
          />
        ) : (
          <PostReplyButton
            hasText={hasText}
            disabled={hideToolbarButtons}
            handleReplyClick={handleReplyClick}
            disablePostButton={disablePostButton}
            postButtonTooltipText={postButtonTooltipText}
          />
        )}
      </div>
    </div>
  );
}
