import type { CreateRecognitionPayload } from '../hooks/mutation/useSaveRecognitionPost';
import { getMemberDetailsFromUserDetails } from '../memberUtils';
import type { Icon, MemberAPIResponse } from '../types/domain';
import {
  type MemberDetails,
  MemberState,
  VisibilityTypes,
} from '../types/flowTypes';
import type {
  GroupOrUserChipData,
  PostResponseRecipients,
} from '../types/recognitionParticipation';
import { LegacyPostTypes } from '../types/recognitionPostTypes';
import type {
  AssemblyBaseSearchResult,
  PostSearchResult,
} from '../types/searchIndex';

export const getPostCardData = ({
  request,
  currentUser,
  postId,
}: {
  request: CreateRecognitionPayload;
  currentUser: MemberAPIResponse;
  postId?: string;
}): PostSearchResult => {
  const { member, assembly } = currentUser;
  const {
    toCache: { recipient },
    toServer: { payload },
  } = request;
  const id = crypto.randomUUID();
  const postCardData = {
    id: postId ?? id,
    score: 2,
    employerId: assembly.assemblyId,
    name: payload.message,
    source: 'assembly' as AssemblyBaseSearchResult['source'],
    type: 'post' as PostSearchResult['type'],
    state: 'ACTIVE',
    content: payload.message,
    tags: ['post'],
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    cardDetails: {
      post: {
        message: payload.message,
        currencyName: assembly.currency.name,
        messageHtml: payload.messageHtml,
        postID: postId ?? id,
        assemblyID: assembly.assemblyId,
        type: 'recognition' as LegacyPostTypes.Recognition,
        from: getMemberDetailsFromUserDetails(member),
        to: recipient.map((member) => {
          return {
            ...member,
            pointsGiven: 0,
            username: member.name,
            totalPointsGiven: 0,
            firstName:
              'firstName' in member
                ? member.firstName
                : member.name.split(' ')[0],
            lastName:
              'lastName' in member
                ? member.lastName
                : member.name.split(' ')[1],
            memberID: 'memberId' in member ? member.memberId : '',
            memberState: MemberState.Active,
            role: [],
          } as MemberDetails;
        }),
        taggedUsers: [],
        reactions: [],
        commentsCount: 0,
        pointsEach: payload.pointsEach,
        isPrivate: payload.isPrivate ?? false,
        isDeleted: false,
        gifURL: '',
        imageURL: '',
        badges: [],
        coreValue: payload.coreValue ?? '',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        kind: 'INTERNAL',
        editedAt: false,
        hasReplyDraft: false,
        recipient: {
          criteriaId: '',
          ...recipient.reduce(
            (acc, item) => {
              if (item.type === 'department') {
                if (item.id === 'everyone') {
                  return {
                    ...acc,
                    everyone: {
                      count: item.count,
                    },
                  };
                }
                return {
                  ...acc,
                  departments: [
                    ...acc.departments,
                    {
                      name: item.name,
                      count: item.count,
                    },
                  ],
                };
              }
              return {
                ...acc,

                individuals: [
                  ...acc.individuals,
                  { ...item, memberID: item.memberID } as MemberDetails,
                ],
              };
            },
            {
              departments: [] as {
                name: string;
                count: number;
              }[],
              individuals: [] as MemberDetails[],
            }
          ),
        },
        tags: ['post'],
      },
      responseId: 'test',
      instanceId: '',
      responses: [],
      flow: {
        name: '',
        flowId: '',
        kind: '',
        icon: {
          kind: 'HEX_CODE' as Icon['kind'],
          value: 'string;',
        },
      },
      // TODO: FlowResponse props below
      respondent: null,
      reactions: [],
      replySummary: {
        count: 0,
        lastRepliedAt: '',
        respondentsCount: 0,
        initialRespondents: [],
      },
      visibility: VisibilityTypes.Everyone,
      isPrivate: false,
      commentsCount: 0,
      createdAt: '',
      canDelete: false,
      canEdit: false,
      kind: '',
      isBoostAllowed: false,
    },
    _meta: {
      to: recipient.map((member) =>
        'memberId' in member
          ? {
              fullName: member.name,
              id: member.memberId ?? member.memberID,
            }
          : {
              fullName: '',
              id: '',
            }
      ),
      type: LegacyPostTypes.Recognition,
      gifURL: '',
      fromRef: {
        entityId: member.memberId,
      },
      collectionsRef: [],
      coreValue: payload.coreValue,
      from: {
        id: member.memberId,
        fullName: member.name,
      },
      toRef: recipient.map((member) =>
        'memberId' in member ? member.memberId : ''
      ),
      pointsEach: payload.pointsEach,
      entityId: postId ?? id,
      message: payload.message,
    },
    highlight: {},
  };
  return postCardData;
};

export function parsePostResponseRecipients(
  recipients: PostResponseRecipients | undefined
): GroupOrUserChipData[] {
  const hasRecipients = Boolean(
    recipients &&
      (recipients.everyone?.count ||
        recipients.departments?.length ||
        recipients.individuals?.length)
  );

  if (!hasRecipients) {
    return [];
  }

  return [
    ...(recipients?.everyone?.count
      ? [
          {
            type: 'department',
            id: 'everyone',
            count: recipients.everyone.count,
            name: 'everyone',
          } satisfies GroupOrUserChipData,
        ]
      : []),
    ...(recipients?.departments ?? []).map(
      (department) =>
        ({
          type: 'department',
          name: department.name,
          id: department.name,
          count: department.count,
        }) satisfies GroupOrUserChipData
    ),
    ...(recipients?.individuals ?? []).map(
      (individual) =>
        ({
          type: 'member',
          ...individual,
        }) satisfies GroupOrUserChipData
    ),
  ];
}
