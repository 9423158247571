import type {
  RecognitionDraftResponse,
  SaveRecognitionDraftPayload,
} from './recognitionParticipation';

type ReplyDraftsResponse = {
  draft: {
    entityType: 'REPLY';
    status: 'ACTIVE';
    replyData:
      | {
          gifUrl: string;
          postId: string;
          taggableUsers: string[];
          kind: 'RESPONSE';
          messageTokens: string;
          boost: {
            members: string[];
            points: string;
          };
        }
      | {
          gifUrl: string;
          challengeId: string;
          taggableUsers: string[];
          kind: 'CHALLENGE';
          messageTokens: string;
          boost: {
            members: string[];
            points: string;
          };
        };
  };
};

type EmptyDraftsResponse = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  draft: {};
};

export type GetDraftsResponse =
  | ReplyDraftsResponse
  | RecognitionDraftResponse
  | EmptyDraftsResponse;

export enum EntityType {
  Recognition = 'RECOGNITION',
}
export type SaveDraftPayload = SaveRecognitionDraftPayload;
