import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

type Tab = 'all' | 'unreads';

const messages = defineMessages({
  all: {
    defaultMessage: 'All',
    id: 'zQvVDJ',
  },
  unreads: {
    defaultMessage:
      '{unreadCount, plural, =0 {Unreads} other {Unreads {unreadCount}}}',
    id: '1v00rU',
  },
});

export function ActivityTabs({
  unreadCount,
  selectedTab,
  onTabChange,
  isTabsLoading = false,
}: {
  unreadCount: number;
  selectedTab: Tab;
  isTabsLoading?: boolean;
  onTabChange: (tab: Tab) => void;
}) {
  const { formatMessage } = useIntl();

  const gradientClasses = 'rounded-lg';

  const tabs = useMemo(
    () => [
      { label: formatMessage(messages.all), value: 'all' },
      {
        label: formatMessage(messages.unreads, { unreadCount }),
        value: 'unreads',
      },
    ],
    [formatMessage, unreadCount]
  );
  return (
    <div className={twMerge('py-4', gradientClasses)}>
      <div className="inline-block">
        {isTabsLoading ? (
          <div className="flex h-[32px] w-fit animate-pulse items-center rounded-lg border-[1px] border-gray-5 bg-gray-1 px-3 py-2">
            <ContentLoader
              speed={2}
              width={72}
              height={12}
              viewBox="0 0 72 12"
              backgroundColor="#d9d9d9"
            >
              <rect x="0" y="0" rx="3" ry="3" width="72" height="12" />
            </ContentLoader>
          </div>
        ) : (
          <ToggleGroup.Root
            className="mb-1 flex text-base font-medium"
            defaultValue={selectedTab}
            onValueChange={onTabChange}
            orientation="horizontal"
            type="single"
          >
            {tabs.map(({ value, label }) => (
              <ToggleGroup.Item
                data-testid={`activity-tab-button-${value}`}
                id={`activity-tab-button-${value}`}
                key={value}
                value={value}
                className={twMerge(
                  'mx-1 rounded-lg border-[1px] bg-gray-1 px-3 py-1 text-gray-9 first:ml-0',
                  'focus-visible:border-gray-9 focus-visible:outline-none focus-visible:ring focus-visible:ring-primary-6 focus-visible:ring-offset-1',
                  'data-[state=off]:border-gray-5 data-[state=off]:hover:bg-gray-3',
                  'data-[state=on]:border-gray-9 data-[state=on]:bg-gray-9 data-[state=on]:text-gray-1'
                )}
              >
                {label}
              </ToggleGroup.Item>
            ))}
          </ToggleGroup.Root>
        )}
      </div>
    </div>
  );
}
