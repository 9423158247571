import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';

export function PreventAutoFocusPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.setEditable(false);

    requestAnimationFrame(() => {
      editor.setEditable(true);
    });
  }, [editor]);

  return null;
}
