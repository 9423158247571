import type { FileType, FolderColor } from '@assembly-web/services';
import { mapHexCodeToEmoticon } from '@assembly-web/services';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import type { ReactElement } from 'react';
import { useState } from 'react';
import { type MessageDescriptor, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import type { SelectedOption } from '../../DesignSystem/DateRangePicker/DateRangePicker';
import {
  DateRangePicker,
  options,
} from '../../DesignSystem/DateRangePicker/DateRangePicker';
import { TextStyle } from '../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../DesignSystem/Inputs/Button';
import { Checkbox } from '../../DesignSystem/Inputs/Checkbox';
import { IconButton } from '../../DesignSystem/Inputs/IconButton';
import { getFolderStyles } from '../Shared/EmojiColorPicker/utils';
import type {
  BaseSelectableOption,
  SelectableOptionProps,
} from './SelectableList';
import { SelectableList } from './SelectableList';

type BaseInFilterOption = { id: string; value: string };

export type FlowFilterOption = BaseInFilterOption & { emoticon: string };

export type FileFilterOption = BaseInFilterOption & {
  imgUrl: string;
  type: FileType;
};

export type CollectionsOption = BaseInFilterOption & {
  emoticon: string;
  colorName: FolderColor;
};

export type AppFilterOption = BaseInFilterOption & { imgUrl: string };

export type PrimaryOptions = Record<string, FilterOption>;

export type FilterOption = {
  description?: string;
  value: string;
  options: (
    | FlowFilterOption
    | FileFilterOption
    | AppFilterOption
    | CollectionsOption
  )[];
  selectedOptions?: (
    | FlowFilterOption
    | FileFilterOption
    | AppFilterOption
    | CollectionsOption
  )[];
  selectedOption?: SelectedOption | null;
};

export type NestedFilterListProps<
  T extends BaseSelectableOption = SelectableOptionProps,
> = {
  isLoading?: boolean;
  onLoadMore?: () => void;
  isFetchingNextPage?: boolean;
  onSearchChange?: (value: string) => void;
  onMenuSelected?: (menuItem: string) => void;
  onDoneClick?: (currentSelectedOptions: SelectableOptionProps[]) => void;
  hasNextPage?: boolean;
  onClearAllClick?: () => void;
  showCTAs?: boolean;
  showSearch?: boolean;
  primaryOptions: PrimaryOptions;
  onSelect?: (selectedOption: T) => void;
  footer?: ReactElement;
  menuHeader?: ReactElement;
  onClose?: () => void;
  className?: {
    container?: string;
    header?: {
      title?: string;
      backIcon?: string;
    };
    selectableList?: string;
  };
  displaySelectedOption?: boolean;
  onBackButtonClick?: () => void;
  onDateOptionSelect?: (optionId: string) => void;
  datePicker?: ReactElement;
  duration?: string;
};

const NestedHeader = (props: {
  selectedMenuItem: FilterOption;
  onClose?: () => void;
  onBackButtonClick?: () => void;
  className?: {
    title?: string;
    backIcon?: string;
  };
}) => {
  const { className, selectedMenuItem, onBackButtonClick, onClose } = props;

  return (
    <div className="flex gap-2 px-2">
      <IconButton
        size="xSmall"
        onClick={onBackButtonClick}
        variation="tertiaryLite"
      >
        <ArrowLeftIcon className="h-4 w-4" aria-hidden="true" />
      </IconButton>
      <div className="flex flex-col">
        <TextStyle className={twMerge('text-gray-9', className?.title)}>
          {selectedMenuItem.value}
        </TextStyle>
        {Boolean(selectedMenuItem.description) && (
          <TextStyle variant="xs-regular" className="text-gray-8">
            {selectedMenuItem.description}
          </TextStyle>
        )}
        {Boolean(onClose) && (
          <IconButton
            size="xSmall"
            variation="tertiaryLite"
            className="absolute right-2 top-2"
            onClick={onClose}
          >
            <XMarkIcon className="h-4 w-4 stroke-gray-8" />
          </IconButton>
        )}
      </div>
    </div>
  );
};

function formatDateOption(params?: SelectedOption | null) {
  if (!params) return;
  const { id, value } = params;
  const start = value?.start
    ? dayjs(value.start).format('YYYY-MM-DD')
    : undefined;
  const end = value?.end ? dayjs(value.end).format('YYYY-MM-DD') : undefined;
  if (!start || !end) return { id };
  return { id, value: { start, end } };
}

export function NestedFilterList({
  footer,
  menuHeader,
  isLoading = false,
  onLoadMore,
  onDoneClick,
  onClearAllClick,
  onSearchChange,
  onMenuSelected,
  isFetchingNextPage,
  hasNextPage,
  showCTAs,
  showSearch,
  primaryOptions,
  onClose,
  onSelect,
  className,
  displaySelectedOption,
  onBackButtonClick,
  onDateOptionSelect,
  datePicker,
  duration,
}: NestedFilterListProps) {
  const { formatMessage } = useIntl();
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(-1);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');
  const [isDatePickerPage, setIsDatePickerPage] = useState(false);

  const selectedDateOption =
    'dateRange' in primaryOptions
      ? options.find(
          (option) =>
            option.id === primaryOptions['dateRange'].selectedOption?.id
        )
      : undefined;

  const primaryOptionsMap = new Map(Object.entries(primaryOptions));
  const primaryOptionsKeys = Array.from(primaryOptionsMap.keys());

  const handleMenuSelected = (selectedFilter: string) => {
    onMenuSelected?.(selectedFilter);
    setSelectedMenuItem(selectedFilter);
  };

  const handleOnBackButtonClick = () => {
    handleMenuSelected('');
    onBackButtonClick?.();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedOptionIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === 'ArrowDown' || e.key === 'Tab') {
      e.preventDefault();
      setFocusedOptionIndex((prevIndex) =>
        prevIndex < primaryOptionsKeys.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'Enter' || e.code === 'Space') {
      e.preventDefault();
      handleMenuSelected(primaryOptions[focusedOptionIndex].value);
    }
  };

  const Option = ({ option }: { option: SelectableOptionProps }) => {
    return (
      <div className="flex items-center gap-2">
        {'emoticon' in option && (
          <div className="px-[1px]">
            {mapHexCodeToEmoticon(option.emoticon as 'string')}
          </div>
        )}
        {'imgUrl' in option && (
          <img
            src={option.imgUrl as string}
            alt={option.id}
            className="h-4 w-4"
          />
        )}
        <TextStyle
          variant="sm-regular"
          className={twMerge(
            'line-clamp-1 text-gray-8',
            selectedMenuItem === 'Collections' && 'rounded-lg px-2',
            'colorName' in option &&
              getFolderStyles(option.colorName as FolderColor).background
          )}
        >
          {option.value}
        </TextStyle>
      </div>
    );
  };

  return (
    <section className={className?.container}>
      <div
        className={twMerge(
          'box-border flex w-full flex-col rounded-lg outline-none',
          !selectedMenuItem && 'py-1'
        )}
      >
        <div onKeyDown={handleKeyDown} tabIndex={0} role="menu">
          {!selectedMenuItem && menuHeader}
          {!selectedMenuItem &&
            primaryOptionsKeys.map((option, index) => (
              <Button
                isFullWidth
                key={index}
                className={twMerge(
                  'group flex h-10 cursor-pointer items-center justify-between rounded-none px-3 py-2',
                  index === focusedOptionIndex && 'rounded-md bg-gray-4'
                )}
                variation="tertiaryLite"
                role="menuitem"
                aria-label={primaryOptions[option].value}
                onClick={() => handleMenuSelected(option)}
              >
                <TextStyle
                  variant={
                    (primaryOptions[option].selectedOption &&
                      (selectedDateOption?.id !== 'custom' ||
                        primaryOptions[option].selectedOption.value?.start ||
                        primaryOptions[option].selectedOption.value?.end)) ||
                    (primaryOptions[option].selectedOptions?.length ?? 0) > 0
                      ? 'sm-medium'
                      : 'sm-regular'
                  }
                  className="text-gray-8"
                >
                  {primaryOptions[option].value}
                  {(primaryOptions[option].selectedOptions?.length ?? 0) >
                    0 && (
                    <>
                      :{' '}
                      {displaySelectedOption &&
                      primaryOptions[option].selectedOptions?.length === 1
                        ? primaryOptions[option].selectedOptions[0].value
                        : `${primaryOptions[option].selectedOptions?.length} selected`}
                    </>
                  )}
                  {Boolean(primaryOptions[option].selectedOption) && (
                    <>
                      {selectedDateOption?.id === 'custom' ? (
                        <>
                          {duration ||
                          primaryOptions[option].selectedOption?.value?.start ||
                          primaryOptions[option].selectedOption?.value?.end ? (
                            <>
                              {duration
                                ? `: ${duration}`
                                : `: ${dayjs(
                                    primaryOptions[option].selectedOption?.value
                                      ?.start
                                  ).format('MM-DD-YYYY')} - ${dayjs(
                                    primaryOptions[option].selectedOption?.value
                                      ?.end
                                  ).format('MM-DD-YYYY')}`}
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {': '}
                          {formatMessage(
                            selectedDateOption?.label as MessageDescriptor
                          )}
                        </>
                      )}
                    </>
                  )}
                </TextStyle>
                <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
              </Button>
            ))}
        </div>

        {Boolean(selectedMenuItem) && (
          <>
            {selectedMenuItem === 'dateRange' ? (
              <>
                {!isDatePickerPage && (
                  <NestedHeader
                    selectedMenuItem={primaryOptions[selectedMenuItem]}
                    onClose={onClose}
                    onBackButtonClick={handleOnBackButtonClick}
                    className={className?.header}
                  />
                )}
                <DateRangePicker
                  onOptionSelect={onDateOptionSelect}
                  selectedOption={formatDateOption(
                    primaryOptions[selectedMenuItem].selectedOption
                  )}
                  showFooter={false}
                  datePicker={datePicker}
                  className={{
                    title: twMerge(className?.header?.title, 'mb-2'),
                    header: '-mt-1 pt-0',
                  }}
                  onClose={onClose}
                  onSetShowCalendar={setIsDatePickerPage}
                  duration={duration}
                />
              </>
            ) : (
              <SelectableList<
                FlowFilterOption | AppFilterOption | FileFilterOption
              >
                showCTAs={showCTAs}
                showSearch={showSearch}
                placeholder="Search"
                isLoading={isLoading}
                onLoadMore={onLoadMore}
                onSearchChange={onSearchChange}
                onSelect={onSelect}
                nestedDropdownHeader={
                  <NestedHeader
                    selectedMenuItem={primaryOptions[selectedMenuItem]}
                    onClose={onClose}
                    onBackButtonClick={handleOnBackButtonClick}
                    className={className?.header}
                  />
                }
                options={primaryOptions[selectedMenuItem].options}
                selectedOptions={
                  primaryOptions[selectedMenuItem].selectedOptions ?? []
                }
                onDoneClick={(currentSelectedOptions) => {
                  handleMenuSelected('');
                  onDoneClick?.(currentSelectedOptions);
                }}
                onClearAllClick={() => {
                  handleMenuSelected('');
                  onClearAllClick?.();
                }}
                renderOption={(option) => <Option option={option} />}
                isFetchingNextPage={isFetchingNextPage}
                hasNextPage={hasNextPage}
                loader={
                  <div
                    className="absolute flex animate-pulse items-center gap-2 px-2 py-2"
                    role="menuitem"
                  >
                    <Checkbox
                      disabled
                      name="selectable-option-loader"
                      aria-label="loader"
                    />
                    <div className="h-[16px] w-[132px] rounded-full bg-gray-5"></div>
                  </div>
                }
                className={className?.selectableList}
              />
            )}
          </>
        )}
        {footer}
      </div>
    </section>
  );
}
