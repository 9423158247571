import type { ThemePreset } from '@assembly-web/services';
import { ThemePresets } from '@assembly-web/services/lib/types/domain';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../../DesignSystem/Inputs/Button';
import { V3Modal } from '../V3Modal';

const Themes = {
  Primary: {
    label: 'Neutral (default)',
    id: ThemePresets.Neutal,
    primary: 'primary-3',
    secondary: 'gray-4',
    tertiary: 'gray-2',
  },
  Cyan: {
    label: 'Cyan',
    id: ThemePresets.Cyan,
    primary: 'cyan-4',
    secondary: 'cyan-2',
    tertiary: 'cyan-1',
  },
  Blue: {
    label: 'Blue',
    id: ThemePresets.Blue,
    primary: 'primary-3',
    secondary: 'primary-2',
    tertiary: 'primary-1',
  },
  LightGreen: {
    label: 'Light green',
    id: ThemePresets.LightGreen,
    primary: 'success-4',
    secondary: 'success-2',
    tertiary: 'success-1',
  },
  Orange: {
    label: 'Orange',
    id: ThemePresets.Orange,
    primary: 'warning-6',
    secondary: 'warning-3',
    tertiary: 'warning-1',
  },
  Red: {
    label: 'Red',
    id: ThemePresets.Red,
    primary: 'error-5',
    secondary: 'error-2',
    tertiary: 'error-1',
  },
  Pink: {
    label: 'Pink',
    id: ThemePresets.Pink,
    primary: 'magenta-4',
    secondary: 'magenta-2',
    tertiary: 'magenta-1',
  },
  Purple: {
    label: 'Purple',
    id: ThemePresets.Purple,
    primary: 'purple-4',
    secondary: 'purple-2',
    tertiary: 'purple-1',
  },
};

const messages = defineMessages({
  title: {
    defaultMessage: 'Change Workspace Theme',
    id: 'PSIZYI',
  },
  subtitle: {
    defaultMessage:
      'Theme colors will apply to the app background, navigation highlights, and other areas of the application.',
    id: '58eTV4',
  },
  cancelButton: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  applyButton: {
    defaultMessage: 'Apply theme to workspace',
    id: 'A7X+/+',
  },
});
export type ThemeColor =
  | 'neutral'
  | 'blue'
  | 'cyan'
  | 'lightGreen'
  | 'orange'
  | 'red'
  | 'pink'
  | 'purple';

export type ThemeSelectorModalProps = {
  onClose: () => void;
  currentTheme: ThemePreset;
  onThemeSelected: (theme: ThemeColor) => void;
};

export const ThemeSelectorModal = ({
  onClose,
  currentTheme,
  onThemeSelected,
}: ThemeSelectorModalProps) => {
  const [selectedTheme, setSelectedTheme] = useState<ThemePreset>(currentTheme);

  const { formatMessage } = useIntl();

  useEffect(() => {
    const $body = document.querySelector('body');

    function setTheme(color: string) {
      const classesToRemove =
        $body?.classList
          .toString()
          .split(' ')
          .filter((className) => className.startsWith('theme-')) ?? [];

      classesToRemove.forEach((className) =>
        $body?.classList.remove(className)
      );

      $body?.classList.add(`theme-${color}`);
    }

    setTheme(selectedTheme);
    return () => {
      setTheme(currentTheme);
    };
  }, [currentTheme, selectedTheme]);

  return (
    <V3Modal
      isOpen
      onClose={onClose}
      title={formatMessage(messages.title)}
      subtitle={formatMessage(messages.subtitle)}
      ctaSection={
        <div className="flex w-full gap-x-2">
          <Button
            variation="secondaryLite"
            className="flex-1"
            onClick={onClose}
          >
            {formatMessage(messages.cancelButton)}
          </Button>
          <Button
            className="flex-1"
            onClick={() => {
              onClose();
              onThemeSelected(selectedTheme);
            }}
          >
            {formatMessage(messages.applyButton)}
          </Button>
        </div>
      }
    >
      <RadioGroup.Root
        className="flex flex-col gap-y-4"
        onValueChange={(value: ThemePreset) => {
          setSelectedTheme(value);
        }}
        value={selectedTheme}
      >
        {Object.values(Themes).map((theme) => (
          <ThemeRadio
            key={theme.id}
            theme={theme}
            selectedThemeId={selectedTheme}
          />
        ))}
      </RadioGroup.Root>
    </V3Modal>
  );
};

const ThemeRadio = ({
  theme,
  selectedThemeId,
}: {
  theme: {
    id: ThemePreset;
    label: string;
    primary: string;
    secondary: string;
    tertiary: string;
  };
  selectedThemeId: ThemePreset;
}) => {
  return (
    <RadioGroup.Item
      key={theme.id}
      value={theme.id}
      className={twMerge(
        'flex w-full items-center justify-between gap-x-4 rounded-2xl border border-gray-5 p-4',
        selectedThemeId === theme.id && 'border-primary-3'
      )}
    >
      <div className="flex items-center gap-x-4">
        <div className="flex h-6 w-6 items-center rounded-full border border-gray-5">
          <RadioGroup.Indicator className="flex h-full w-full items-center rounded-2xl border-[7px] border-primary-6" />
        </div>
        <TextStyle variant="base-bold">{theme.label}</TextStyle>
      </div>

      <div className="flex justify-end gap-x-2">
        <div
          className={twMerge('h-8 w-16 rounded-lg', `bg-${theme.tertiary}`)}
        />
        <div
          className={twMerge('h-8 w-8 rounded-lg', `bg-${theme.secondary}`)}
        />
        <div className={twMerge('h-8 w-3 rounded-lg', `bg-${theme.primary}`)} />
      </div>
    </RadioGroup.Item>
  );
};
