import type { GroupOrUserRecipientData } from '@assembly-web/services';
import { Fragment } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { DepartmentList } from './DepartmentList';
import { GroupAndUserChip } from './GroupAndUserChip';
import { ProfileViewer } from './ProfileViewer/ProfileViewer';

const messages = defineMessages({
  everyone: {
    defaultMessage: 'Everyone',
    id: '2XDuH4',
  },
});

type GroupOrUserChipListProps = {
  data: GroupOrUserRecipientData[];
};

export function GroupOrUserChipList({ data }: GroupOrUserChipListProps) {
  const { formatMessage } = useIntl();

  return data.map((recipient, i) => (
    <Fragment key={i}>
      {(() => {
        if (recipient.type === 'department') {
          const isEveryone = recipient.id === 'everyone';
          const name = isEveryone
            ? formatMessage(messages.everyone)
            : recipient.name;

          return (
            <DepartmentList id={recipient.id ?? recipient.name} name={name}>
              <GroupAndUserChip
                type={recipient.id ? 'everyone' : 'department'}
                name={name}
                count={recipient.count}
              />
            </DepartmentList>
          );
        }
        return (
          <ProfileViewer userDetails={recipient}>
            <GroupAndUserChip key={recipient.memberID} {...recipient} />
          </ProfileViewer>
        );
      })()}
    </Fragment>
  ));
}
