import {
  ArrowTopRightOnSquareIcon,
  ChartBarIcon,
  DocumentCheckIcon,
  LinkIcon,
  PencilIcon,
  StopIcon,
} from '@heroicons/react/24/outline';
import type { SyntheticEvent } from 'react';
import type { IntlShape } from 'react-intl';

import type { ToolbarItem } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { messages } from './messages';

export function getToolbarMenuItemsForAnnouncementCards({
  canEnd,
  canEdit,
  canShowMarkAsSeen,
  formatMessage,
  canShowInsights,
}: {
  canEnd: boolean;
  canEdit: boolean;
  canShowInsights: boolean;
  canShowMarkAsSeen: boolean;
  formatMessage: IntlShape['formatMessage'];
}) {
  const primaryToolbarItems: ToolbarItem[] = [];

  // Common items
  const baseToolbarItems: ToolbarItem[] = [
    {
      id: 'go-to-announcement',
      icon: ArrowTopRightOnSquareIcon,
      text: formatMessage(messages.goToAnnouncement),
    },
    {
      id: 'mark-as-seen',
      icon: DocumentCheckIcon,
      text: formatMessage(messages.markAsSeen),
    },
    {
      id: 'copy-announcement-link',
      icon: LinkIcon,
      text: formatMessage(messages.copyAnnouncementLink),
    },
  ];

  const secondaryToolbarItems = [
    {
      id: 'go-to-announcement',
      icon: ArrowTopRightOnSquareIcon,
      text: formatMessage(messages.goToAnnouncement),
    },
  ];

  if (canShowMarkAsSeen) {
    secondaryToolbarItems.push({
      id: 'mark-as-seen',
      icon: DocumentCheckIcon,
      text: formatMessage(messages.markAsSeen),
    });
  }

  secondaryToolbarItems.push({
    id: 'copy-announcement-link',
    icon: LinkIcon,
    text: formatMessage(messages.copyAnnouncementLink),
  });

  if (canEdit) {
    secondaryToolbarItems.push({
      id: 'edit-announcement-link',
      icon: PencilIcon,
      text: formatMessage(messages.editAnnouncement),
    });
  }

  if (canShowInsights) {
    secondaryToolbarItems.push({
      id: 'view-insights',
      icon: ChartBarIcon,
      text: formatMessage(messages.viewInsights),
    });
  }

  if (canEnd) {
    secondaryToolbarItems.push({
      id: 'end-announcement',
      icon: StopIcon,
      text: formatMessage(messages.endAnnouncement),
    });
  }

  return {
    primaryToolbarItems,
    secondaryToolbarItems:
      canEnd || canShowInsights || canEdit
        ? secondaryToolbarItems
        : baseToolbarItems,
  };
}

/**
 *
 * @param element current element
 * @param cursor  current cursor position
 * @returns  true if cursor is inside element
 */
export const checkCursorInsideElement = (
  element: HTMLElement,
  cursor: { x: number; y: number }
): boolean => {
  const wrapperBounds = element.getBoundingClientRect();
  return (
    cursor.x > wrapperBounds.left &&
    cursor.x < wrapperBounds.right &&
    cursor.y > wrapperBounds.top &&
    cursor.y < wrapperBounds.bottom
  );
};

export const cardParentDataAttributes = { 'data-clickable': true } as const;

const clickableElements = new Set(['BUTTON', 'A', 'INPUT', 'P']);

const defaultConditionCallback = (node: HTMLElement) => {
  return node.getAttribute('data-clickable') === 'true';
};

const canClick = (
  node: HTMLElement,
  conditionCallback: (el: HTMLElement) => boolean
): boolean => {
  if (conditionCallback(node)) {
    return true;
  }

  if (clickableElements.has(node.nodeName)) {
    return false;
  }

  return node.parentElement
    ? canClick(node.parentElement, conditionCallback)
    : true;
};

export const handleParentClick = <TEvent extends SyntheticEvent>(
  callback?: (e: TEvent) => void,
  conditionCallback = defaultConditionCallback
) => {
  return (e: TEvent) => {
    if (!canClick(e.target as HTMLElement, conditionCallback)) {
      return;
    }

    return callback?.(e);
  };
};
