// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getHandlerProps(props: any) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlerProps: Record<string, any> = {};

  for (const propName in props) {
    const isHandler = /^on[A-Z]/.test(propName);
    if (isHandler) {
      handlerProps[propName] = (...args: unknown[]) => {
        props[propName](...args);
      };
    }
  }

  return handlerProps;
}
