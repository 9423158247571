import { InformationCircleIcon } from '@heroicons/react/24/outline';
import {
  Content,
  Portal,
  Root as HoverCardRoot,
  Trigger,
} from '@radix-ui/react-hover-card';
import parse from 'html-react-parser';
import { type ReactNode, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { StarRating } from '../../../DesignSystem/Feedback/StarRating';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';

const messages = defineMessages({
  selectReward: {
    defaultMessage: 'Select reward',
    id: 'RlbE4+',
  },
  noDescription: {
    defaultMessage: 'No description found',
    id: 'EDZNX1',
  },
  cost: {
    defaultMessage: 'Cost:',
    id: 'KFjD/N',
  },
  shipping: {
    defaultMessage: 'shipping',
    id: 'Gu1gvR',
  },
  shippingCostDescription: {
    defaultMessage:
      '{icon} {cost} flat rate shipping on your order, regardless of quantity',
    id: 'FiCKkl',
  },
  insufficientPoints: {
    defaultMessage:
      "You don't have enough {icon} {points} to redeem this reward.",
    id: 'MBk/kw',
  },
  maxLimitAvailable: {
    defaultMessage: '{maxLimit} available',
    id: 'TjwpVh',
  },
  discountsCostNotice: {
    defaultMessage: 'Varies, not redeemable with {icon} {currency}',
    id: 'XylVr5',
  },
  taxCalculatedAtCheckout: {
    defaultMessage:
      '{icon} <b>{cost}</b> + tax and shipping calculated at checkout',
    id: '4p9Ev/',
  },
  viewMoreItemDetails: {
    defaultMessage: 'View more item details',
    id: '6agi81',
  },
  currencyIconAndValue: {
    defaultMessage: '{icon}{value}',
    id: 'LaBLwY',
  },
});

export type RewardCardProps = {
  type: 'swag' | 'customReward' | 'tangoReward' | 'discounts' | 'amazon';
  cost?: string | { min: string; max: string } | null;
  description: string;
  imageUrl: string;
  title: string;
  icon: ReactNode;
  isRewardTabOn?: boolean;
  handleOpenRedemptionModal: () => void;
  disabled?: boolean;
  buttonText?: string;
  shippingCost?: number;
  hasRedeemablePoints?: boolean;
  rewardCardType?: string | null;
  maxLimit?: number;
  currencyPluralName: string;
  href?: string;
  onItemDetailsClick?: () => void;
  rating?: string;
  reviews?: number;
  showItemDetails?: boolean;
};

export function RewardCard(props: RewardCardProps) {
  const {
    description,
    imageUrl,
    title,
    type,
    cost,
    icon,
    isRewardTabOn,
    handleOpenRedemptionModal,
    buttonText,
    disabled,
    shippingCost,
    hasRedeemablePoints = true,
    rewardCardType,
    maxLimit,
    currencyPluralName,
    href,
    onItemDetailsClick,
    rating,
    reviews,
    showItemDetails,
  } = props;
  const [descriptionOverflow, setDescriptionOverflow] = useState(false);
  const { formatMessage } = useIntl();
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const isMobileView = useDeviceInfo().deviceType === 'mobile';

  useEffect(() => {
    const onResize = () => {
      const el = descriptionRef.current;
      if (el) {
        setDescriptionOverflow(el.scrollHeight > el.clientHeight);
      }
    };

    window.addEventListener('resize', onResize);
    onResize();

    return () => window.removeEventListener('resize', onResize);
  }, [description.length]);

  return (
    <HoverCardRoot openDelay={200} closeDelay={0}>
      <Trigger className="inline-block w-full cursor-pointer">
        <section
          className={twMerge(
            'flex h-[285px] flex-col overflow-hidden rounded-lg border border-gray-5 bg-gray-1 hover:bg-gray-3 hover:shadow-base-down sm:h-[156px] sm:flex-row',
            (type === 'swag' || type === 'amazon') &&
              !isRewardTabOn &&
              'opacity-70',
            type === 'amazon' && 'h-[300px]'
          )}
          data-testid={`reward-${rewardCardType}-card`}
        >
          <div
            className={twMerge(
              'relative h-[160px] shrink-0 overflow-hidden bg-gray-3 sm:h-auto sm:w-[246px] sm:border-r sm:border-gray-5',
              type === 'tangoReward' && 'py-2 sm:py-0'
            )}
          >
            {type === 'customReward' && typeof maxLimit === 'number' && (
              <span
                className={twMerge(
                  'shadow absolute left-2 top-2 rounded bg-gray-1 px-2 py-[3px] text-xs opacity-80',
                  maxLimit > 0 ? 'text-gray-9' : 'text-gray-7'
                )}
              >
                {formatMessage(messages.maxLimitAvailable, { maxLimit })}
              </span>
            )}
            {Boolean(isMobileView) && (
              <HoverCardRoot openDelay={200} closeDelay={0}>
                <Trigger>
                  <IconButton
                    variation="secondaryLite"
                    className="absolute right-0 top-2 mr-2 h-6 w-6 rounded-full !p-0"
                  >
                    <InformationCircleIcon className="h-4 w-4"></InformationCircleIcon>
                  </IconButton>
                </Trigger>
                <Portal>
                  <Content
                    align="start"
                    className="relative right-2 top-10 z-10 mx-auto w-[90vw] rounded bg-gray-1 px-4 py-3 shadow-base-down after:absolute after:-top-2 after:right-4 after:h-0 after:w-0 after:rounded-sm after:border-x-[10px] after:border-b-[10px] after:border-x-transparent after:border-b-gray-1 after:drop-shadow-[0_-3px_2px_rgba(0,0,0,0.03)]"
                  >
                    <TextStyle
                      variant="sm-regular"
                      className="w-fit text-gray-8"
                    >
                      {parse(description)}
                    </TextStyle>
                  </Content>
                </Portal>
              </HoverCardRoot>
            )}
            <img
              alt=""
              src={imageUrl}
              className={twMerge(
                'h-full w-full object-contain',
                (type === 'tangoReward' || type === 'discounts') &&
                  'sm:scale-105'
              )}
            />
          </div>
          <div
            className={twMerge(
              'flex grow flex-col overflow-hidden px-3 py-4 sm:p-6',
              type === 'amazon' && 'sm:pb-4 sm:pt-4'
            )}
          >
            <div
              className={twMerge(
                'flex h-full flex-col sm:h-auto sm:flex-row sm:items-center sm:pb-4',
                type === 'amazon' && 'sm:pb-1'
              )}
            >
              <div className="grow truncate text-center sm:p-0 sm:text-left">
                <TextStyle
                  variant="base-medium"
                  className="truncate"
                  data-testid="reward-card-title"
                >
                  {title}
                </TextStyle>
                <div>
                  {type === 'amazon' && typeof cost === 'string' && (
                    <TextStyle variant="xs-regular">
                      {formatMessage(messages.cost)}{' '}
                      <TextStyle as="span" variant="xs-regular">
                        {formatMessage(messages.taxCalculatedAtCheckout, {
                          b: (text: ReactNode) => (
                            <TextStyle as="span" variant="xs-bold">
                              {text}
                            </TextStyle>
                          ),
                          icon,
                          cost,
                        })}
                      </TextStyle>
                      <TextStyle
                        className="flex w-full items-center justify-center gap-1 truncate align-middle text-gray-8 sm:justify-normal"
                        as="span"
                      >
                        {rating}
                        {Boolean(rating) && (
                          <StarRating
                            filled={Math.floor(Number(rating))}
                            size="sm"
                          />
                        )}
                        <TextStyle
                          as="span"
                          variant="sm-regular"
                          className="text-gray-8"
                        >
                          <button
                            className="text-primary-6 underline"
                            onClick={onItemDetailsClick}
                          >
                            {showItemDetails
                              ? formatMessage(messages.viewMoreItemDetails)
                              : reviews}
                          </button>
                        </TextStyle>
                      </TextStyle>
                    </TextStyle>
                  )}
                </div>
                {type === 'discounts' && (
                  <TextStyle variant="xs-regular">
                    {formatMessage(messages.cost)}{' '}
                    <TextStyle as="span" variant="xs-bold">
                      {formatMessage(messages.discountsCostNotice, {
                        icon,
                        currency: currencyPluralName,
                      })}
                    </TextStyle>
                  </TextStyle>
                )}
                {type == 'tangoReward' && typeof cost === 'object' ? (
                  <div className="text-xs">
                    {formatMessage(messages.cost)}{' '}
                    {formatMessage(messages.currencyIconAndValue, {
                      icon,
                      value: cost?.min,
                    })}
                    -{' '}
                    {formatMessage(messages.currencyIconAndValue, {
                      icon,
                      value: cost?.max,
                    })}
                  </div>
                ) : (
                  <span className="flex gap-1">
                    {type !== 'discounts' && type !== 'amazon' && (
                      <TextStyle variant="xs-regular">
                        {formatMessage(messages.cost)}{' '}
                        {typeof cost === 'string' && (
                          <TextStyle as="span" variant="xs-bold">
                            {formatMessage(messages.currencyIconAndValue, {
                              icon,
                              value: cost,
                            })}
                          </TextStyle>
                        )}
                      </TextStyle>
                    )}
                    {Boolean(shippingCost) && (
                      <>
                        <TextStyle variant="xs-bold" as="span">
                          +
                        </TextStyle>
                        <Tooltip
                          tooltipText={formatMessage(
                            messages.shippingCostDescription,
                            {
                              icon,
                              cost: shippingCost,
                            }
                          )}
                        >
                          <TextStyle
                            as={'p'}
                            variant={'xs-bold'}
                            className="cursor-pointer underline underline-offset-1"
                          >
                            {formatMessage(messages.shipping)}
                          </TextStyle>
                        </Tooltip>
                      </>
                    )}
                  </span>
                )}
              </div>
              {!hasRedeemablePoints ? (
                <Tooltip
                  tooltipText={formatMessage(messages.insufficientPoints, {
                    icon,
                    points: currencyPluralName,
                  })}
                >
                  <Button
                    size="small"
                    variation="secondaryEmphasized"
                    className={twMerge(
                      'shrink-0 sm:ml-3',
                      type === 'amazon' && 'sm:self-baseline'
                    )}
                    onClick={handleOpenRedemptionModal}
                    disabled={disabled}
                    data-testid="reward-card-select-reward-btn"
                  >
                    {buttonText
                      ? buttonText
                      : formatMessage(messages.selectReward)}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  size="small"
                  variation="secondaryEmphasized"
                  className={twMerge(
                    'shrink-0 sm:ml-3',
                    type === 'amazon' && 'sm:self-baseline'
                  )}
                  onClick={handleOpenRedemptionModal}
                  disabled={disabled}
                  data-testid="reward-card-select-reward-btn"
                  asChild={Boolean(href)}
                >
                  {href ? (
                    <a href={href} target="_blank" rel="noreferrer">
                      {buttonText}
                    </a>
                  ) : buttonText ? (
                    buttonText
                  ) : (
                    formatMessage(messages.selectReward)
                  )}
                </Button>
              )}
            </div>
            <TextStyle
              variant="sm-regular"
              className={twMerge(
                'line-clamp-2 hidden text-gray-8 sm:block',
                type === 'amazon' && 'h-[44px]'
              )}
              ref={descriptionRef}
            >
              {description
                ? parse(description)
                : formatMessage(messages.noDescription)}
            </TextStyle>
          </div>
        </section>
      </Trigger>
      {Boolean(descriptionOverflow) && (
        <Portal>
          <Content
            align="center"
            sideOffset={8}
            avoidCollisions={false}
            side="bottom"
            className="relative top-[-1.5rem] z-10 hidden w-[95vw] max-w-[940px] rounded bg-gray-1 px-4 py-3 shadow-base-up after:absolute after:-top-2 after:right-10 after:h-0 after:w-0 after:rounded-sm after:border-x-[10px] after:border-b-[10px] after:border-x-transparent after:border-b-gray-1 after:drop-shadow-[0_-3px_2px_rgba(0,0,0,0.03)] sm:block"
          >
            <TextStyle variant="sm-regular" className="text-gray-8">
              {parse(description)}
            </TextStyle>
          </Content>
        </Portal>
      )}
    </HoverCardRoot>
  );
}
