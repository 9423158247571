export const config = {
  env: import.meta.env.VITE_ENV,
  isProduction: import.meta.env.MODE === 'prod',
  isMobileDevMode: import.meta.env.VITE_IS_MOBILE_DEV_MODE,

  locale: import.meta.env.VITE_LOCALE,

  intercomAppId: import.meta.env.VITE_INTERCOM_APP_ID,

  mixpanelToken: import.meta.env.VITE_MIXPANEL_TOKEN,
  splitAuthKey: import.meta.env.VITE_SPLIT_AUTH_KEY,
  recaptchaSiteKey: import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY,
  giphyKey: import.meta.env.VITE_GIPHY_KEY,

  publicAssemblyPostRegex: import.meta.env.VITE_PUBLIC_ASSEMBLY_POST_REGEX,

  domains: {
    app: import.meta.env.VITE_APP_URL,
    legacyApp: import.meta.env.VITE_LEGACY_APP_URL,
    mobileApp: import.meta.env.VITE_MOBILE_APP_URL,
    adminApp: import.meta.env.VITE_ADMIN_APP_URL,
    newAdminApp: import.meta.env.VITE_NEW_ADMIN_APP_URL,
    api: import.meta.env.VITE_API_URL,
    doraApi: import.meta.env.VITE_DORA_API_URL,
    locationLookupApp: import.meta.env.VITE_LOCATION_LOOKUP_URL,
  },
  sso: {
    gSuite: {
      clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
      scope: [
        'https://www.googleapis.com/auth/userinfo.profile',
        'https://www.googleapis.com/auth/userinfo.email',
        'openid',
      ].join(' '),
    },
    office365: import.meta.env.VITE_OFFICE365_CLIENT_ID,
    slack: import.meta.env.VITE_SLACK_CLIENT_ID,
    adp: {
      consumerApplicationId: import.meta.env.VITE_ADP_CONSUMER_APPLICATION_ID,
      clientId: import.meta.env.VITE_ADP_CLIENT_ID,
    },
    lifion: {
      consumerApplicationId: import.meta.env
        .VITE_LIFION_CONSUMER_APPLICATION_ID,
      clientId: import.meta.env.VITE_LIFION_CLIENT_ID,
    },
  },

  pusher: {
    cluster: `mt1`,
    forceTLS: true,
    clientKey: import.meta.env.VITE_PUSHER_KEY,
    authEndpoint: `${import.meta.env.VITE_API_URL}/api/v3/users/pusher/auth`,
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
  },
  reactQueryDevTool: import.meta.env.VITE_REACT_QUERY_DEVTOOL,
};
