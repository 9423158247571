import { CheckIcon, PencilIcon } from '@heroicons/react/24/outline';
import * as ToggleButton from '@radix-ui/react-toggle';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export type Props = ToggleButton.ToggleProps;

export const EditButton = forwardRef<HTMLButtonElement, Props>(
  function EditButton({ className, ...tail }, ref) {
    return (
      <ToggleButton.Root
        {...tail}
        ref={ref}
        className={twMerge(
          'group flex items-center justify-center rounded-lg border-primary-6 ring-primary-6 data-[state=off]:border data-[state=off]:bg-gray-1 data-[state=on]:bg-primary-6 data-[state=off]:p-[7px] data-[state=on]:p-2 data-[state=off]:active:bg-gray-3 data-[state=on]:active:bg-primary-7 disabled:data-[state=off]:border-gray-5 disabled:data-[state=off]:bg-gray-2 disabled:data-[state=on]:bg-gray-2 [&>svg]:h-4 [&>svg]:w-4 [&>svg]:stroke-2',
          className
        )}
      >
        <CheckIcon className="hidden stroke-gray-1 group-disabled:stroke-gray-7 group-data-[state=on]:block" />
        <PencilIcon className="stroke-primary-6 group-disabled:stroke-gray-7 group-data-[state=on]:hidden" />
      </ToggleButton.Root>
    );
  }
);
