import type { File } from '@assembly-web/services';
import {
  FileType,
  getFileColorForFileCard,
  getFileTypeForFile,
} from '@assembly-web/services';
import { ArrowDownTrayIcon, LinkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import type { ToolbarItem } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { Toolbar } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { useToolbarState } from '../../../DesignSystem/Feedback/Toolbar/useToolbarState';
import { imageIcon, WarningIcon } from '../../assets/icons';
import { getFileIconForFileCard } from './utils';

const secondaryToolbarItems = [
  {
    id: 'copyLink',
    icon: LinkIcon,
    text: 'Copy link',
  },
  {
    id: 'downloadFile',
    icon: ArrowDownTrayIcon,
    text: 'Download file',
  },
];

const messages = defineMessages({
  clickToViewFile: {
    defaultMessage: 'Click to view file',
    id: 'j3vBX5',
  },
  uploadFailed: {
    defaultMessage: 'Upload failed',
    id: 'f00p/7',
  },
});

function ImageCard({
  file,
  onFileClick,
  numFiles,
}: {
  file: File;
  onFileClick: (fileName: string, fileType: string) => void;
  numFiles: number;
}) {
  const [error, setError] = useState(false);
  if (error) {
    return (
      <div className="w-24 rounded-lg border border-gray-5">
        <TextStyle
          variant="xs-regular"
          className="truncate border-b border-gray-5 p-2"
        >
          {file.name}
        </TextStyle>
        <img
          src={imageIcon}
          className="mx-auto my-0 box-content h-6 w-6 pb-5 pt-4"
          alt=""
        />
      </div>
    );
  }
  return (
    <button onClick={() => onFileClick(file.name, file.type)}>
      <img
        className={twMerge(
          'rounded-lg hover:scale-[1.015] hover:cursor-zoom-in',
          numFiles === 1 && 'object-cover',
          numFiles > 1 && 'w-[160px] object-cover max-md:w-[140px]'
        )}
        src={file.thumbnails[360]}
        alt={file.name}
        onError={() => setError(true)}
      />
    </button>
  );
}

function ImageCards({
  files,
  onFileClick,
  showInCarousel,
}: {
  files: File[];
  onFileClick: (fileName: string, fileType: string) => void;
  showInCarousel?: boolean;
}) {
  const getClassName = () => {
    if (files.length > 1 && showInCarousel) {
      return 'grid grid-cols-1 gap-2 @[280px]/image:grid @[280px]/image:grid-cols-2 @[311px]/image:flex @[311px]/image:flex-wrap';
    }
    if (files.length > 1) {
      return 'flex flex-wrap gap-2';
    }
  };

  return (
    <div className={getClassName()}>
      {files.map((file) => (
        <ImageCard
          key={file._id}
          file={file}
          onFileClick={onFileClick}
          numFiles={files.length}
        />
      ))}
    </div>
  );
}

const NonImageCard = ({
  file,
  isLast,
  onFileClick,
  onToolbarItemClick,
  showInCarousel,
}: {
  file: File;
  isLast: boolean;
  onFileClick: (fileName: string, fileType: string) => void;
  onToolbarItemClick?: ({
    args,
    fileName,
  }: {
    args: ToolbarItem;
    fileName: string;
  }) => void;
  showInCarousel?: boolean;
}) => {
  const uploadFailed = file.size === 0;
  const bgColor = uploadFailed ? 'error-2' : getFileColorForFileCard(file.type);
  const logo = getFileIconForFileCard(file.type);

  const { formatMessage } = useIntl();
  const { getToolbarProps } = useToolbarState({ hideToolbar: uploadFailed });

  return (
    <div className="relative flex">
      <button
        className={twMerge(
          'group flex h-[80px] w-full',
          !uploadFailed && 'hover:scale-[1.015] hover:cursor-zoom-in',
          (!isLast || !showInCarousel) && 'mb-2',
          showInCarousel && 'my-1'
        )}
        onClick={() => onFileClick(file.name, file.type)}
        disabled={uploadFailed}
      >
        <div
          className={twMerge(
            'flex h-[80px] w-[80px] items-center justify-center rounded-l border-r-[1px] border-gray-4',
            `bg-${bgColor}`
          )}
        >
          {uploadFailed ? (
            <WarningIcon className="h-8 w-8 text-error-6" />
          ) : (
            <img
              className="mx-auto my-auto h-[24px] w-[24px]"
              src={logo}
              alt=""
            />
          )}
        </div>
        <div className="relative flex h-[80px] flex-1 flex-col items-start justify-center overflow-hidden rounded-r border-y-[1px] border-r-[1px] border-gray-4 px-3 py-2">
          <p className="h-[22px] w-full overflow-hidden truncate text-left font-medium">
            {file.originalName ?? file.name}
          </p>
          {uploadFailed ? (
            <p className="text-xs text-error-6">
              {formatMessage(messages.uploadFailed)}
            </p>
          ) : (
            <p className="text-gray-8">
              {formatMessage(messages.clickToViewFile)}
            </p>
          )}
        </div>
      </button>
      {onToolbarItemClick ? (
        <div className="absolute right-2 top-2">
          <Toolbar
            {...getToolbarProps({
              onMenuItemClick: (args) => {
                onToolbarItemClick({ args, fileName: file.name });
              },
            })}
            secondaryToolbarItems={secondaryToolbarItems}
          />
        </div>
      ) : null}
    </div>
  );
};

function NonImageCards({
  files,
  onFileClick,
  onToolbarItemClick,
  showInCarousel,
}: {
  files: File[];
  onFileClick: (fileName: string, fileType: string) => void;
  onToolbarItemClick?: ({
    args,
    fileName,
  }: {
    args: ToolbarItem;
    fileName: string;
  }) => void;
  showInCarousel?: boolean;
}) {
  return (
    <div className="max-w-[500px] text-gray-9">
      {files.map((file, index) => {
        return (
          <NonImageCard
            key={file._id}
            file={file}
            isLast={index === files.length - 1}
            onFileClick={onFileClick}
            onToolbarItemClick={onToolbarItemClick}
            showInCarousel={showInCarousel}
          />
        );
      })}
    </div>
  );
}

export function FileBlock({
  files,
  onFileClick,
  onToolbarItemClick,
  showInCarousel,
}: {
  files: File[];
  onFileClick: (fileName: string, fileType: string) => void;
  onToolbarItemClick?: ({
    args,
    fileName,
  }: {
    args: ToolbarItem;
    fileName: string;
  }) => void;
  showInCarousel?: boolean;
}) {
  const imageFiles = files.filter(
    (file) => getFileTypeForFile(file.type) === FileType.Image
  );
  const otherFiles = files.filter(
    (file) => getFileTypeForFile(file.type) !== FileType.Image
  );

  return (
    <div className="overflow-hidden">
      {imageFiles.length > 0 && (
        <ImageCards
          files={imageFiles}
          onFileClick={onFileClick}
          showInCarousel={showInCarousel}
        />
      )}
      {otherFiles.length > 0 && (
        <NonImageCards
          files={otherFiles}
          onFileClick={onFileClick}
          onToolbarItemClick={onToolbarItemClick}
          showInCarousel={showInCarousel}
        />
      )}
    </div>
  );
}
