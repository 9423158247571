import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export function DynamicHeaderText({
  text,
  className,
}: {
  text: string;
  className?: string;
}) {
  const [fontSizeClass, setFontSizeClass] = useState('');

  const calculateFontSizeClass = (length: number): string => {
    if (length <= 10) return 'text-dynamic-lg';
    if (length <= 20) return 'text-dynamic-md';
    return 'text-dynamic-sm';
  };

  useEffect(() => {
    const handleResize = () => {
      setFontSizeClass(calculateFontSizeClass(text.length));
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [text]);

  return (
    <p
      className={twMerge(
        'line-clamp-2 text-left font-bold',
        fontSizeClass,
        className
      )}
    >
      {text}
    </p>
  );
}
