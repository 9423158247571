import type { FolderColor } from '@assembly-web/services';

export function getFolderStyles(colorName: FolderColor) {
  let className = 'bg-primary-2'; // Default color (this will handle cases where backend sends an invalid color name)

  switch (colorName) {
    case 'skyBlue':
      className = 'bg-blue-2';
      break;
    case 'cyan':
      className = 'bg-cyan-2';
      break;
    case 'limeGreen':
      className = 'bg-success-2';
      break;
    case 'gold':
      className = 'bg-upgrade-2';
      break;
    case 'orange':
      className = 'bg-brand-2';
      break;
    case 'red':
      className = 'bg-error-2';
      break;
    case 'pink':
      className = 'bg-magenta-2';
      break;
    case 'purple':
      className = 'bg-purple-2';
      break;
    case 'gray':
      className = 'bg-gray-4';
      break;
  }

  return { background: className };
}
