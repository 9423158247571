import {
  type AssemblyCurrency,
  AssemblyCurrencyType,
  mapHexCodeToEmoticon,
  type Nullable,
  type ReplyData,
} from '@assembly-web/services';
import type {
  DOMExportOutput,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical';
import { DecoratorNode } from 'lexical';
import type { ReactNode } from 'react';

import { MentionComponent } from '../components/MentionComponent';

export type MentionNodeUser = {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  role?: string;
  email?: string;
  image?: string;
  profileThumbnails?: Record<number, string>;
  points?: number;
  pointsHidden: boolean;
  applyBoostToAll: boolean;
  pronouns?: string;
  department?: string;
  currency?: AssemblyCurrency;
  memberID: string;
  isCurrentUserAnon: boolean;
  currentUserID?: string;
  isEditing?: boolean;
  selectedReply: Nullable<ReplyData>;
  canReceivePoints?: boolean;
  type?: 'boost-node' | 'mention-node';
};

export type SerializedMentionNode = Spread<
  {
    id: string;
    name: string;
    mentionedMember: MentionNodeUser;
  },
  SerializedLexicalNode
>;

export class MentionNode extends DecoratorNode<ReactNode> {
  public readonly id: string;
  public readonly name: string;
  public readonly mentionedMember: MentionNodeUser;
  public readonly type: 'boost-node' | 'mention-node' = 'mention-node';

  static getType(): string {
    return 'mention';
  }

  constructor(mention: MentionNodeUser, key?: NodeKey) {
    super(key);

    this.name = mention.name;
    this.id = mention.memberID;
    this.mentionedMember = mention;
    this.type = mention.type ? mention.type : 'mention-node';
  }

  exportJSON(): SerializedMentionNode {
    return {
      version: 1,
      id: this.id,
      type: 'mention',
      name: this.name,
      mentionedMember: this.mentionedMember,
    };
  }

  createDOM(): HTMLElement {
    return document.createElement('span');
  }

  updateDOM(): false {
    return false;
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.mentionedMember, node.__key);
  }

  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    return $createMentionNode(serializedNode.mentionedMember);
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');

    element.setAttribute('data-lexical-mention-id', this.id);
    element.setAttribute('data-lexical-mention', 'true');
    element.setAttribute('data-lexical-mention-name', this.name);
    element.setAttribute(
      'data-lexical-hide-points',
      this.mentionedMember.pointsHidden.toString()
    );
    element.setAttribute(
      'data-lexical-node-type',
      this.mentionedMember.points ? 'boost-node' : 'mention-node'
    );

    element.style.color = '#4968ED';
    element.style.fontSize = '1rem';
    element.style.cursor = 'pointer';
    element.style.userSelect = 'none';
    element.style.padding = '0.125rem';
    element.style.whiteSpace = 'nowrap';
    element.style.lineHeight = '1.5rem';
    element.style.paddingLeft = '0.25rem';
    element.style.paddingRight = '0.25rem';
    element.style.borderRadius = '0.25rem';
    element.style.display = 'inline-block';
    element.style.backgroundColor = '#DBE0FB';
    element.style.marginBottom = '0.25rem';

    let nameWithPoints = this.name;
    if (this.mentionedMember.points && !this.mentionedMember.pointsHidden) {
      element.setAttribute(
        'data-lexical-points',
        String(this.mentionedMember.points)
      );
      const currencyIcon =
        this.mentionedMember.currency?.type === AssemblyCurrencyType.Custom
          ? `<img alt=${this.mentionedMember.currency.name} style="display: inline-block; height: 1rem; width: 1rem" src=${this.mentionedMember.currency.value} />`
          : this.mentionedMember.currency?.value &&
            mapHexCodeToEmoticon(this.mentionedMember.currency.value);
      nameWithPoints += ` +${currencyIcon}${this.mentionedMember.points}`;
    }
    element.innerHTML = nameWithPoints;
    return { element };
  }

  isSegmented(): false {
    return false;
  }

  isTextEntity(): true {
    return true;
  }

  isToken(): true {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }

  isCurrentUser() {
    return this.mentionedMember.currentUserID === this.mentionedMember.memberID;
  }

  setMentionDetails(
    {
      points = this.mentionedMember.points,
      applyBoostToAll,
      pointsHidden,
    }: { points?: number; applyBoostToAll: boolean; pointsHidden: boolean },
    editor: LexicalEditor
  ) {
    editor.update(() => {
      const writable = this.getWritable();
      writable.mentionedMember.points = points;
      writable.mentionedMember.pointsHidden = pointsHidden;
      writable.mentionedMember.applyBoostToAll = applyBoostToAll;
    });
  }

  setPoint(points: number, editor: LexicalEditor) {
    editor.update(() => {
      const writable = this.getWritable();
      writable.mentionedMember.points = points;
    });
  }

  decorate() {
    return (
      <MentionComponent
        id={this.id}
        name={this.name}
        type={this.type}
        nodeKey={this.getKey()}
        mention={this.mentionedMember}
        isCurrentUser={this.isCurrentUser()}
      />
    );
  }

  getTextContent(): string {
    return this.name;
  }
}

export function $createMentionNode(mention: MentionNodeUser): MentionNode {
  return new MentionNode(mention);
}

export function $isMentionNode(
  node: LexicalNode | null | undefined
): node is MentionNode {
  return node instanceof MentionNode;
}
