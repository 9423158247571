import type { ListItemNode } from '@lexical/list';
import {
  $createListItemNode,
  $createListNode,
  $isListItemNode,
  $isListNode,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { ElementNode, LexicalNode } from 'lexical';
import {
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_CRITICAL,
  KEY_ENTER_COMMAND,
} from 'lexical';
import { useCallback, useEffect } from 'react';

import { useDeviceInfo } from '../../../../hooks/useDeviceInfo';
import { useTouchDevice } from '../../../../hooks/useTouchDevice';
import { getSerializedDataFromEditor } from '../../base/utils';
import { getSelectedNode } from '../../base/utils/getSelectedNode';
import { resetEditor } from '../../base/utils/resetEditor';
import type { EditorData, RepliesValidatorError } from './RepliesFooterPlugin';

type HandleEnterKeyPressPluginProps = {
  disabled?: boolean;
  onPressEnter?: (data: EditorData) => void;
  validator?: (text: string) => RepliesValidatorError[];
};

export function HandleEnterKeyPressPlugin({
  disabled,
  onPressEnter,
  validator,
}: HandleEnterKeyPressPluginProps) {
  const [editor] = useLexicalComposerContext();
  const isMobile = useDeviceInfo().deviceType === 'mobile';
  const isTablet = useDeviceInfo().deviceType === 'tablet';
  const isTouchDevice = useTouchDevice();

  const onEnterKeyPressed = useCallback(() => {
    editor.update(() => {
      const {
        html,
        json,
        mentionIds,
        plainText,
        gifUrls,
        linkUrls,
        boostedUsers,
      } = getSerializedDataFromEditor(editor);

      const errors = validator?.(plainText) ?? [];
      if (errors.length === 0) {
        resetEditor(editor);
      }

      onPressEnter?.({
        json,
        html,
        plainText,
        mentionIds,
        errors,
        gifUrls,
        linkUrls,
        boost: boostedUsers,
      });
    });
  }, [editor, onPressEnter, validator]);

  useEffect(() => {
    return editor.registerCommand(
      KEY_ENTER_COMMAND,
      (event: KeyboardEvent) => {
        if (disabled) {
          return true;
        }

        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const node = getSelectedNode(selection);
          if (
            node.getType() === 'text' &&
            node.getTextContent().includes('@')
          ) {
            return false;
          }

          if (event.shiftKey) {
            editor.update(() => {
              // If the current node is a list item, we should create a new list item
              // and for knowing that we should check if the parent node is a list item
              const selection = $getSelection();
              if (selection !== null) {
                const nodes = selection.getNodes();
                if ($isRangeSelection(selection)) {
                  const anchorAndFocus = selection.getStartEndPoints();
                  if (anchorAndFocus === null) {
                    return false;
                  }
                  const [anchor] = anchorAndFocus;
                  const anchorNode = anchor.getNode();
                  const anchorNodeParent = anchorNode.getParent();

                  if ($isSelectingEmptyListItem(anchorNode, nodes)) {
                    const list = $createListNode(
                      $isListNode(anchorNode)
                        ? anchorNode.getListType()
                        : 'number'
                    );

                    if ($isRootOrShadowRoot(anchorNodeParent)) {
                      anchorNode.replace(list);
                      const listItem = $createListItemNode();
                      if ($isElementNode(anchorNode)) {
                        listItem.setFormat(anchorNode.getFormatType());
                        listItem.setIndent(anchorNode.getIndent());
                      }
                      list.append(listItem);
                    } else if ($isListItemNode(anchorNode)) {
                      const parent = anchorNode.getParentOrThrow();
                      (node as ElementNode).splice(
                        list.getChildrenSize(),
                        0,
                        parent.getChildren()
                      );
                      parent.replace(list);
                    }

                    return;
                  }
                }
              }

              if ($isRangeSelection(selection)) {
                selection.insertParagraph();
              }

              event.preventDefault();
            });
            return true;
          }

          const isEnterKeyPressed =
            !event.metaKey && !event.ctrlKey && event.key === 'Enter';

          if (
            !isMobile &&
            onPressEnter &&
            isEnterKeyPressed &&
            !(isTablet && isTouchDevice)
          ) {
            onEnterKeyPressed();
            return true;
          }

          return false;
        }
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [
    disabled,
    editor,
    isMobile,
    isTablet,
    isTouchDevice,
    onEnterKeyPressed,
    onPressEnter,
  ]);

  return null;
}

function $isSelectingEmptyListItem(
  anchorNode: ListItemNode | LexicalNode,
  nodes: LexicalNode[]
): boolean {
  return (
    $isListItemNode(anchorNode) &&
    (nodes.length === 0 ||
      (nodes.length === 1 &&
        anchorNode.is(nodes[0]) &&
        anchorNode.getChildrenSize() === 0))
  );
}
