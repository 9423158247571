import { useSplitTreatments } from '@splitsoftware/splitio-react';

import type { SplitNames } from '../constants/SplitNames';

export function useFeatureSplit(splitName: SplitNames) {
  const {
    treatments: {
      [splitName]: { treatment, config },
    },
  } = useSplitTreatments({ names: [splitName] });

  return {
    config,
    treatment,
    isTreatmentActive: treatment === 'on',
  };
}
