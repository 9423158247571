import type { ClaimState } from '@assembly-web/services';
import type { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { OverflowText } from '../Feedback/OverflowText';
import { StatusBadge, type StatusBadgeProps } from '../Feedback/StatusBadge';
import type { TextStyleProps } from '../Feedback/TextStyle';
import { Button, type ButtonProps } from '../Inputs/Button';
import { IconButton, type IconButtonProps } from '../Inputs/IconButton';

type CellRendererTextStyleProps = Omit<TextStyleProps, 'html' | 'children'> & {
  html?: string | TrustedHTML;
  children?: ReactNode;
  tooltipText?: string;
};

type CellRendererProps =
  | {
      type: 'text';
      componentProps: CellRendererTextStyleProps;
      cellValue: ReactNode;
    }
  | {
      type: 'button';
      componentProps: ButtonProps;
      cellValue: ReactNode;
    }
  | {
      type: 'iconButton';
      componentProps: IconButtonProps;
      cellValue: ReactNode;
    }
  | {
      type: 'badge';
      componentProps: StatusBadgeProps<ClaimState>;
      cellValue: string | ReactNode;
    };

export const CellRenderer: FC<CellRendererProps> = ({
  type,
  cellValue,
  componentProps,
}) => {
  // TODO : We can move these renderers to a new folder as well later.
  switch (type) {
    case 'text': {
      const { html, children, tooltipText, ...otherProps } = componentProps;

      const propsWithContent =
        html !== undefined
          ? { ...otherProps, html }
          : { ...otherProps, children: cellValue ?? children };

      return (
        <OverflowText
          tooltipText={tooltipText}
          {...propsWithContent}
          className={twMerge(
            propsWithContent.className,
            'line-clamp-2 break-all'
          )}
          tooltipClassName="h-auto break-all overflow-hidden"
        />
      );
    }
    case 'button': {
      return <Button {...componentProps}>{cellValue}</Button>;
    }
    case 'iconButton': {
      return <IconButton {...componentProps}>{cellValue}</IconButton>;
    }
    case 'badge': {
      return <StatusBadge {...componentProps} />;
    }
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};
