// eslint-disable-next-line @eslint-community/eslint-comments/disable-enable-pair
/* eslint-disable @calm/react-intl/missing-formatted-message */
import { config } from '@assembly-web/services';
import { SwatchIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export function TailwindBreakpointIndicator() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className={twMerge(
        'font-mono fixed bottom-4 right-4 z-50 flex items-center justify-center rounded-lg px-4 py-2 uppercase shadow-xl-down',
        'bg-magenta-4 sm:bg-primary-4 md:bg-warning-4 lg:bg-success-4 xl:bg-error-4 2xl:bg-upgrade-4',
        ['all', 'rq'].includes(config.reactQueryDevTool) && 'right-20'
      )}
    >
      <SwatchIcon className="mr-2 h-6 w-6" />
      <div className="block sm:hidden">xs</div>
      <div className="hidden sm:block md:hidden">sm</div>
      <div className="hidden md:block lg:hidden">md</div>
      <div className="hidden lg:block xl:hidden">lg</div>
      <div className="hidden xl:block 2xl:hidden">xl</div>
      <div className="hidden 2xl:block">2xl</div>
      <div className="pl-1 text-xs">{`(${windowWidth}px)`}</div>
    </div>
  );
}
