import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  day: { id: 'P47mG0', defaultMessage: '{value}d' },
  hour: { id: 'eJ/c45', defaultMessage: '{value}h' },
  minute: { id: 'JFpzo+', defaultMessage: '{value}m' },
  overdue: { id: 'CNi26+', defaultMessage: 'overdue' },
});

/**
 *
 * @returns a function that returns formatted duration
 * (primarily used to indicate how much time is left until the due date in a custom format)
 */
export function useFormattedDueDateDuration() {
  const intl = useIntl();
  const getFormattedDuration = useCallback(
    (targetDateString: string) => {
      const currentTime = new Date();
      const targetDate = new Date(targetDateString);
      const timeDiff = targetDate.getTime() - currentTime.getTime();
      if (timeDiff <= 0) {
        return intl.formatMessage(messages.overdue);
      }
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      let formattedDays = '';
      let formattedHours = '';
      let formattedMinutes = '';
      if (days > 0) {
        formattedDays = intl.formatMessage(messages.day, { value: days });
      }
      if (hours > 0 || days > 0) {
        formattedHours = intl.formatMessage(messages.hour, { value: hours });
      }
      if (minutes > 0) {
        formattedMinutes = intl.formatMessage(messages.minute, {
          value: minutes,
        });
      }
      return formattedDays && formattedHours
        ? `${formattedDays} ${formattedHours}`
        : `${formattedDays} ${formattedHours} ${formattedMinutes}`
            .replaceAll('  ', ' ')
            .trim();
    },
    [intl]
  );
  return { getFormattedDuration };
}
