import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { LoadingSpinner } from '../../../DesignSystem/Feedback/Icons/LoadingSpinner';
import { OverflowText } from '../../../DesignSystem/Feedback/OverflowText';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Button } from '../../../DesignSystem/Inputs/Button';

export type TemplateCardProps = {
  title: string;
  icon: string;
  bgColor: string;
  blockCount: number;
  onUseTemplate: () => void;
  onPreview: () => void;
  isFlowCreationInProgress: boolean;
};

const messages = defineMessages({
  questions: {
    id: 'kOO7Kj',
    defaultMessage:
      '{blockCount} {blockCount, plural, =1 {question} other {questions}}',
  },
  preview: {
    id: 'TJo5E6',
    defaultMessage: 'Preview',
  },
  useTemplate: {
    id: 'z3PGP2',
    defaultMessage: 'Use this template',
  },
  saving: {
    id: 'TiR/Hq',
    defaultMessage: 'Saving...',
  },
});

export function TemplateCard({
  title,
  icon,
  bgColor,
  blockCount,
  onUseTemplate,
  onPreview,
  isFlowCreationInProgress,
}: TemplateCardProps) {
  const { formatMessage } = useIntl();
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className="flex h-[112px] w-[350px] cursor-pointer gap-4 rounded-lg border border-primary-3 bg-gray-1 p-4 shadow-md-down hover:bg-primary-1"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      role="button"
      tabIndex={0}
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      onClick={onPreview}
      onKeyDown={onPreview}
    >
      <div
        className={twJoin(
          'h-fit cursor-default rounded-lg border-none',
          bgColor
        )}
      >
        <TextStyle className="p-2">{icon}</TextStyle>
      </div>
      <div
        className={twJoin(
          'flex flex-col justify-between gap-4',
          isHovered ? 'w-[120px]' : 'w-full'
        )}
      >
        <OverflowText
          variant="base-medium"
          className={twJoin(
            '!block truncate',
            isHovered ? 'w-[120px]' : 'w-full'
          )}
          tooltipText={title}
        >
          {title}
        </OverflowText>
        <TextStyle variant="sm-regular" className="text-gray-8">
          {formatMessage(messages.questions, {
            blockCount,
          })}
        </TextStyle>
      </div>
      {Boolean(isHovered) && (
        <div className="flex flex-col gap-4">
          <Button
            variation="secondaryEmphasized"
            onClick={onPreview}
            className="!py-1"
          >
            {formatMessage(messages.preview)}
          </Button>
          <Button
            variation="secondaryEmphasized"
            onClick={(e) => {
              e.stopPropagation();
              onUseTemplate();
            }}
            isLoading={isFlowCreationInProgress}
            className="whitespace-nowrap !py-1"
          >
            {isFlowCreationInProgress ? (
              <>
                <>{formatMessage(messages.saving)}</>
                <LoadingSpinner />
              </>
            ) : (
              formatMessage(messages.useTemplate)
            )}
          </Button>
        </div>
      )}
    </div>
  );
}
