import { createElement, type ElementType, type ReactNode } from 'react';
import { isElement, isValidElementType } from 'react-is';

export type IconNode = ElementType | ReactNode;

type IconProps = {
  icon: IconNode;
  className?: string;
};

/** Useful to render heroIcons or custom icons with additional properties as needed. */
export function Icon({ icon, ...props }: IconProps) {
  if (isElement(icon)) {
    return icon;
  }

  if (isValidElementType(icon)) {
    return createElement(icon, props);
  }

  return null;
}
