import { MemberState } from '@assembly-web/services';
import { Content, Portal, Root, Trigger } from '@radix-ui/react-hover-card';
import { type ReactNode, useContext } from 'react';

import { PostDrawerContext } from '../../../context/PostDrawerContext';
import { BoostProfileCard } from '../Boost/BoostProfileCard';
import type { BoostOptions } from '../Editors/RepliesEditor/RepliesEditor';
import type { ProfileCardContentProps } from '../ProfileCard/ProfileCard';

export type ProfileViewerProps = {
  children: ReactNode;
  boostOptions?: BoostOptions;
  userDetails: ProfileCardContentProps | null;
  onViewProfileClick?: (memberId: string) => void;
};

export function ProfileViewer({
  children,
  userDetails,
  boostOptions,
  onViewProfileClick,
}: ProfileViewerProps) {
  const postDrawerContext = useContext(PostDrawerContext);

  if (!userDetails) {
    return <>{children}</>;
  }

  return (
    <Root openDelay={200}>
      <Trigger asChild>{children}</Trigger>
      <Portal>
        <Content
          side="top"
          align="start"
          sideOffset={8}
          alignOffset={-46}
          className="z-[51] inline-flex items-center rounded-lg bg-gray-1 shadow-base-down"
        >
          <BoostProfileCard
            email={userDetails.email}
            key={userDetails.memberID}
            image={userDetails.image ?? ''}
            lastName={userDetails.lastName}
            firstName={userDetails.firstName}
            memberState={userDetails.memberState}
            jobTitle={userDetails.jobTitle ?? ''}
            pronouns={userDetails.pronouns ?? ''}
            department={userDetails.department ?? ''}
            points={0}
            mode={'ADD'}
            currency={boostOptions?.currentUser?.assembly.currency}
            canReceiveBoost={
              !(boostOptions?.disableBoost ?? boostOptions?.hideBoost)
            }
            canGiveBoost={true}
            isAnonymousReply={false}
            reasonForCantGiveBoost={boostOptions?.disableBoostReason}
            onBoostClick={() => {
              if (postDrawerContext) {
                postDrawerContext.setSelectedBoostMember(userDetails);
              }
            }}
            onClose={() => {}}
            hideBoostButtonReason={
              !boostOptions ||
              userDetails.memberState === MemberState.Deactivated
                ? `HIDE`
                : boostOptions.currentUser?.member.memberId ===
                    userDetails.memberID
                  ? 'SELF'
                  : boostOptions.hideBoost
                    ? 'HIDE'
                    : undefined
            }
            memberID={userDetails.memberID}
            currentBoostedUsers={[]}
            isEditing={false}
            {...(onViewProfileClick && {
              onViewProfileClick: () => {
                onViewProfileClick(userDetails.memberID);
              },
            })}
          />
        </Content>
      </Portal>
    </Root>
  );
}
