import { FaceSmileIcon } from '@heroicons/react/24/outline';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { type BaseEmoji, Picker } from 'emoji-mart';
import {
  $createRangeSelection,
  $createTextNode,
  $insertNodes,
  $setSelection,
} from 'lexical';
import { useState } from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { Tooltip } from '../../../../../DesignSystem/Feedback/Tooltip';
import { Popover } from '../../../../Shared/Popover';

type EmojiButtonProps = {
  disabled?: boolean;
};

const label = defineMessage({
  defaultMessage: 'Emoji',
  id: '34NuzG',
});

export function EmojiButton({ disabled }: EmojiButtonProps) {
  const [editor] = useLexicalComposerContext();
  const { formatMessage } = useIntl();

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleOnEmojiSelect = (emoji: BaseEmoji) => {
    setShowEmojiPicker(false);
    editor.update(() => {
      const textNode = $createTextNode(emoji.native);
      const spaceNode = $createTextNode(' ');
      $insertNodes([textNode, spaceNode]);

      const selection = $createRangeSelection();
      selection.anchor.set(spaceNode.getKey(), 1, 'text');
      selection.focus.set(spaceNode.getKey(), 1, 'text');
      $setSelection(selection);
    });
  };

  return (
    <Popover
      disabled={disabled}
      aria-label={formatMessage(label)}
      onTriggerButtonClick={() => setShowEmojiPicker((prov) => !prov)}
      trigger={
        <Tooltip tooltipText={formatMessage(label)}>
          <div
            role="presentation"
            className="flex h-10 w-10 items-center justify-center rounded-lg hover:bg-gray-3"
          >
            <FaceSmileIcon className="h-6 w-6 text-gray-8" />
          </div>
        </Tooltip>
      }
      open={showEmojiPicker}
      onOpenChange={setShowEmojiPicker}
      onCloseAutoFocus={(e) => e.preventDefault()}
    >
      <Picker onClick={handleOnEmojiSelect} />
    </Popover>
  );
}
