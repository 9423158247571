import { isTruthy, withoutDefaultEventBehavior } from '@assembly-web/services';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Avatar, AvatarSize } from '../../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import type { ToolbarItem } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { Toolbar } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import { useToolbarState } from '../../../DesignSystem/Feedback/Toolbar/useToolbarState';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import type { Gradient } from '../Modals/AnnouncementCenterModal/gradientColors';
import { AssemblyLink } from '../Route/AssemblyLink';
import { getToolbarMenuItemsForAnnouncementCards } from './utils';

export type AnnouncementToolbarItem = ToolbarItem & {
  announcementId: string;
};

type PreviewAnnouncementCardType = {
  title: string;
  hideMenu: true;
  fullName: string;
  memberID: string;
  imageUrl?: string;
  gradient: Gradient;
  description?: string;
};

type AnnouncementCardType = {
  url: string;
  title: string;
  hideMenu: false;
  canEnd: boolean;
  fullName: string;
  memberID: string;
  imageUrl?: string;
  canEdit: boolean;
  gradient: Gradient;
  description?: string;
  announcementId: string;
  canShowInsights: boolean;
  onToolbarItemClick: (args: AnnouncementToolbarItem) => void;
  onAnnouncementDescriptionHover: (args: {
    announcementId: string;
    description: string;
  }) => void;
  onPostClicked?: () => void;
};

export type AnnouncementCardProps = (
  | AnnouncementCardType
  | PreviewAnnouncementCardType
) & {
  variant?: 'stretched' | 'default';
};

export function AnnouncementCard(props: AnnouncementCardProps) {
  const { formatMessage } = useIntl();
  const {
    title,
    fullName,
    hideMenu,
    gradient,
    imageUrl,
    memberID,
    description,
    variant = 'default',
  } = props;

  const cardRef = useRef<HTMLAnchorElement>(null);

  const { getContainerProps, getToolbarProps } = useToolbarState();

  const { primaryToolbarItems, secondaryToolbarItems } =
    getToolbarMenuItemsForAnnouncementCards({
      formatMessage,
      canEnd: !props.hideMenu && props.canEnd,
      canEdit: !props.hideMenu && props.canEdit,
      canShowInsights: !props.hideMenu && props.canShowInsights,
      canShowMarkAsSeen: !props.hideMenu,
    });

  const stretched = variant === 'stretched';
  const CardContent = (
    <>
      <Avatar
        name={fullName}
        image={imageUrl}
        memberID={memberID}
        size={AvatarSize.Small}
        tooltipText={fullName}
      />

      <div className="relative ml-2 flex w-48 gap-2">
        <TextStyle
          variant="base-medium"
          className="line-clamp-2 flex-1 break-words text-gray-9"
        >
          {title.substring(0, 45)}
        </TextStyle>
      </div>
      <div
        className={twMerge(
          'flex flex-col content-between items-center',
          stretched ? 'w-fit flex-row-reverse gap-1' : 'h-11 w-6'
        )}
      >
        {!hideMenu && (
          <Toolbar
            compact
            {...getToolbarProps({
              onMenuItemClick(arg) {
                props.onToolbarItemClick({
                  ...arg,
                  announcementId: props.announcementId,
                });
              },
            })}
            primaryToolbarItems={primaryToolbarItems}
            secondaryToolbarItems={secondaryToolbarItems}
          />
        )}
        {isTruthy(description) && (
          <div className="mt-auto h-5 w-5">
            <Tooltip
              side="bottom"
              contentClassName="z-50"
              tooltipText={description}
              textClassName="break-all"
            >
              <button
                className="cursor-default"
                onClick={withoutDefaultEventBehavior(() => void 0)}
                aria-label="Announcement description tooltip"
              >
                <InformationCircleIcon
                  strokeWidth={2}
                  className="h-5 w-5 text-gray-8"
                  onMouseEnter={() => {
                    if (!props.hideMenu) {
                      props.onAnnouncementDescriptionHover({
                        announcementId: props.announcementId,
                        description: description,
                      });
                    }
                  }}
                />
              </button>
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );

  const cardStyle = twMerge(
    'flex flex-col justify-between rounded-lg p-4 shadow-md-down',
    gradient.className,
    'flex flex-row gap-2 h-20 w-72',
    stretched && 'h-14 w-full'
  );

  return 'url' in props ? (
    <div className="duration-400 flex-shrink-0 transform transition-transform ease-in-out hover:scale-[1.015]">
      <AssemblyLink
        to={props.url}
        draggable={false}
        data-testid="announcement-card"
        className={cardStyle}
        ref={cardRef}
        onClick={props.onPostClicked}
        {...getContainerProps()}
      >
        {CardContent}
      </AssemblyLink>
    </div>
  ) : (
    <section
      data-testid="announcement-card"
      className={twMerge(cardStyle, 'flex-shrink-0')}
    >
      {CardContent}
    </section>
  );
}
