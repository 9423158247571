import {
  type AssemblyCurrency,
  AssemblyCurrencyType,
  mapHexCodeToEmoticon,
} from '@assembly-web/services';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $insertNodes } from 'lexical';
import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../../../DesignSystem/Feedback/Tooltip';
import { Button } from '../../../../../DesignSystem/Inputs/Button';
import { BoostPlusIcon } from '../../../../assets/icons';
import type { BoostOptions } from '../../RepliesEditor/RepliesEditor';
import { BOOST_CLICK_COMMAND } from '../plugins/MentionsPlugin';
import { getSelectedNodes } from '../utils/getSelectedNodes';

const messages = defineMessages({
  boostButtonLabel: {
    defaultMessage: 'Give someone a {currency} boost',
    id: '7b+q7o',
  },
  cannotGiveBoostAnonymously: {
    defaultMessage: 'You cannot give a boost anonymously',
    id: 'eWcjxh',
  },
  boostNotAllowed: {
    defaultMessage: 'You are out of {currency} to give for this month',
    id: 'lMQcko',
  },
  pointsExhausted: {
    defaultMessage: 'Max limit of {currency} reached to give in this reply',
    id: 'vy1FNc',
  },
});

type BoostButtonProps = {
  isAnonymousReply?: boolean;
  disabled?: boolean;
  boostOptions: BoostOptions;
  boostExhausted: boolean;
  currency: AssemblyCurrency | undefined;
};

export function BoostButton({
  isAnonymousReply,
  disabled,
  boostOptions,
  boostExhausted,
  currency,
}: BoostButtonProps) {
  const { formatMessage } = useIntl();

  const [editor] = useLexicalComposerContext();

  const handleOnBoostMemberButtonClick = () => {
    editor.update(() => {
      $insertNodes(getSelectedNodes());
      editor.dispatchCommand(BOOST_CLICK_COMMAND, null);
    });
  };

  const isBoostButtonDisabled = useMemo(() => {
    return (
      Boolean(isAnonymousReply) ||
      Boolean(disabled) ||
      boostOptions.disableBoost ||
      boostExhausted
    );
  }, [boostExhausted, boostOptions.disableBoost, disabled, isAnonymousReply]);

  const disabledBoostButtonTooltip = useMemo(() => {
    if (boostOptions.disableBoost && boostOptions.disableBoostReason) {
      return boostOptions.disableBoostReason;
    }

    if (isAnonymousReply) {
      return formatMessage(messages.cannotGiveBoostAnonymously);
    }

    if (boostExhausted) {
      return formatMessage(messages.pointsExhausted, {
        currency: currency?.pluralName,
      });
    }

    return formatMessage(messages.boostNotAllowed, {
      currency: currency?.pluralName,
    });
  }, [
    boostExhausted,
    boostOptions.disableBoost,
    boostOptions.disableBoostReason,
    currency,
    formatMessage,
    isAnonymousReply,
  ]);

  const currencyIcon = currency ? (
    currency.type === AssemblyCurrencyType.Custom ? (
      <img alt={currency.name} className="h-4 w-4" src={currency.value} />
    ) : (
      mapHexCodeToEmoticon(currency.value)
    )
  ) : null;

  const boostButtonLabel = formatMessage(messages.boostButtonLabel, {
    currency: currency?.name,
  });

  return (
    <Tooltip
      tooltipText={
        isBoostButtonDisabled ? disabledBoostButtonTooltip : boostButtonLabel
      }
    >
      <Button
        disabled={isBoostButtonDisabled}
        variation="tertiaryLite"
        className="h-10 w-10 !p-2"
        onClick={handleOnBoostMemberButtonClick}
        aria-label={boostButtonLabel}
      >
        <div className="relative h-full w-full">
          <TextStyle className={twJoin(isBoostButtonDisabled && 'grayscale')}>
            {currencyIcon}
          </TextStyle>
          <img
            className="absolute bottom-0.5 right-0.5 h-2.5 w-2.5"
            src={BoostPlusIcon}
            alt=""
          />
        </div>
      </Button>
    </Tooltip>
  );
}
