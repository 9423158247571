import { DecoratorNode } from 'lexical';
import type { ReactNode } from 'react';

export class BaseChipNode extends DecoratorNode<ReactNode> {
  static getType(): string {
    return 'combobox-chip';
  }

  createDOM(): HTMLElement {
    const element = document.createElement('span');
    element.classList.add('inline-block');
    return element;
  }

  updateDOM(): false {
    return false;
  }

  isTextEntity(): true {
    return true;
  }

  isSegmented(): true {
    return true;
  }

  isToken(): true {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }
}
