import { browserUtils } from '@assembly-web/services';
import type { HeadingTagType } from '@lexical/rich-text';
import type { LexicalEditor } from 'lexical';
import { type ReactNode, useMemo } from 'react';

import {
  TextStyle,
  type TextVariant,
} from '../../../../../DesignSystem/Feedback/TextStyle';
import {
  DropdownMenu,
  type DropdownMenuAlignmentOptions,
} from '../../../../../DesignSystem/Inputs/DropdownMenu';
import {
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  Heading4Icon,
  Heading5Icon,
  Heading6Icon,
  TextIcon,
} from '../../../../../Icons';
import { $formatHeading, $formatParagraph } from '../utils/formatNode';
import { Shortcut } from './Shortcut';

type FormatDropdownProps = {
  blockType: string;
  disabled?: boolean;
  editor: LexicalEditor;
  dropdownAlignmentOptions?: DropdownMenuAlignmentOptions;
};

function isHeadingType(value: string): value is HeadingTagType {
  return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value);
}

const ShortcutContainer = ({ children }: { children: ReactNode }) => (
  <div className="flex items-center gap-1 text-xs">{children}</div>
);

const dropdownOptions = [
  {
    icon: TextIcon,
    id: 'paragraph',
    value: 'paragraph',
    label: 'Normal Text',
    tooltip: (
      <ShortcutContainer>
        <Shortcut variant="light">
          {browserUtils.isMac ? '⌘ cmd + option + 0' : 'ctrl + alt + 0'}
        </Shortcut>
      </ShortcutContainer>
    ),
  },
  {
    id: 'h1',
    value: 'h1',
    label: 'Heading 1',
    icon: Heading1Icon,
    tooltip: (
      <ShortcutContainer>
        <Shortcut variant="light">
          {browserUtils.isMac ? '⌘ cmd + option + 1' : 'ctrl + alt + 1'}
        </Shortcut>
      </ShortcutContainer>
    ),
  },
  {
    id: 'h2',
    value: 'h2',
    label: 'Heading 2',
    icon: Heading2Icon,
    tooltip: (
      <ShortcutContainer>
        <Shortcut variant="light">
          {browserUtils.isMac ? '⌘ cmd + option + 2' : 'ctrl + alt + 2'}
        </Shortcut>
      </ShortcutContainer>
    ),
  },
  {
    id: 'h3',
    value: 'h3',
    label: 'Heading 3',
    icon: Heading3Icon,
    tooltip: (
      <ShortcutContainer>
        <Shortcut variant="light">
          {browserUtils.isMac ? '⌘ cmd + option + 3' : 'ctrl + alt + 3'}
        </Shortcut>
      </ShortcutContainer>
    ),
  },
  {
    id: 'h4',
    value: 'h4',
    label: 'Heading 4',
    icon: Heading4Icon,
    tooltip: (
      <ShortcutContainer>
        <Shortcut variant="light">
          {browserUtils.isMac ? '⌘ cmd + option + 4' : 'ctrl + alt + 4'}
        </Shortcut>
      </ShortcutContainer>
    ),
  },
  {
    id: 'h5',
    value: 'h5',
    label: 'Heading 5',
    icon: Heading5Icon,
    tooltip: (
      <ShortcutContainer>
        <Shortcut variant="light">
          {browserUtils.isMac ? '⌘ cmd + option + 5' : 'ctrl + alt + 5'}
        </Shortcut>
      </ShortcutContainer>
    ),
  },
  {
    id: 'h6',
    value: 'h6',
    label: 'Heading 6',
    icon: Heading6Icon,
    tooltip: (
      <ShortcutContainer>
        <Shortcut variant="light">
          {browserUtils.isMac ? '⌘ cmd + option + 6' : 'ctrl + alt + 6'}
        </Shortcut>
      </ShortcutContainer>
    ),
  },
];

const HeadingVariantMap: Record<string, TextVariant> = {
  h1: '2xl-bold',
  h2: 'xl-bold',
  h3: 'lg-bold',
  h4: 'base-bold',
  h5: 'sm-bold',
  h6: 'xs-bold',
  p: 'sm-medium',
};

export function FormatDropdown({
  blockType,
  disabled,
  dropdownAlignmentOptions,
  editor,
}: FormatDropdownProps) {
  const formatParagraph = useMemo(() => $formatParagraph(editor), [editor]);

  const formatHeading = useMemo(() => $formatHeading(editor), [editor]);

  return (
    <DropdownMenu
      variant="default"
      isPortalDisabled
      value={blockType}
      disabled={disabled}
      triggerClassName="!p-0"
      options={dropdownOptions}
      contentClassName="w-[316px] overflow-y-scroll h-24"
      className="ml-2 h-7 w-auto rounded px-1 hover:bg-gray-3"
      dropdownAlignmentOptions={dropdownAlignmentOptions}
      onSelected={(selectedOption) => {
        if (selectedOption.value === 'paragraph') {
          formatParagraph();
        } else if (isHeadingType(selectedOption.value)) {
          formatHeading(selectedOption.value);
        }
      }}
      labelRenderer={(label) => {
        let item = dropdownOptions.find((option) => option.label === label);
        if (!item) {
          item = dropdownOptions[0];
        }

        const Icon = item.icon;
        return (
          <div className="flex items-center">
            <Icon className="h-4 w-4 text-gray-8" />
          </div>
        );
      }}
      optionRenderer={(selectedOption) => {
        let item = dropdownOptions.find(
          (option) => option.value === selectedOption.value
        );

        if (!item) {
          item = dropdownOptions[0];
        }

        const Icon = item.icon;
        return (
          <div className="flex items-center gap-2 px-3 py-2 hover:bg-gray-3">
            <Icon className="h-4 w-4 gap-2 text-gray-8" />
            <TextStyle
              as="p"
              variant={HeadingVariantMap[item.value]}
              className="flex-1 text-gray-8"
            >
              {item.label}
            </TextStyle>
            {item.tooltip}
          </div>
        );
      }}
    />
  );
}
