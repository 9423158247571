import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import type { z } from 'zod';

import { TextStyle } from '../../../../../DesignSystem/Feedback/TextStyle';
import { Checkbox } from '../../../../../DesignSystem/Inputs/Checkbox';
import { TextField } from '../../../../../DesignSystem/Inputs/TextField';
import { ToggleSwitch } from '../../../../../DesignSystem/Inputs/ToggleSwitch';
import { messages } from '../message';
import { getDefaultValues, getSchema } from '../schema';

export const inputClassName = 'mt-1 text-gray-9 text-sm';
export const labelClassName = 'text-sm font-medium text-gray-9';

export function ProofSettings({
  disabled,
  tooltipText,
}: {
  disabled?: boolean;
  tooltipText?: string;
}) {
  const { formatMessage } = useIntl();

  const schema = getSchema(formatMessage);
  type FormFields = z.infer<typeof schema>;
  const {
    control,
    register,
    getValues,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext<FormFields>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'proof.content',
  });
  const isProofRequired = watch('proof.isRequired');
  const watchFieldArray = watch('proof.content');

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const onProofCheckboxChange = () => {
    // disable isRequired to false if all proofs are unchecked
    const areAllProofsDisabled = getValues('proof.content').every(
      (field) => !field.isChecked
    );
    if (areAllProofsDisabled) {
      setValue('proof.isRequired', false);
    }
  };

  const onRequiredProofChange = (isChecked: boolean) => {
    // when user disables all proof checkboxes and then enable proof required toggle, prefill default values
    if (isChecked) {
      const defaultValue = getDefaultValues(formatMessage).proof.content;
      const areAllProofsDisabled = getValues('proof.content').every(
        (field) => !field.isChecked
      );
      if (areAllProofsDisabled) {
        remove();
        append(defaultValue);
      }
    }
  };

  return (
    <div>
      <div className="flex justify-between pb-3">
        <TextStyle as="span" variant="sm-medium" className="text-gray-9">
          {formatMessage(messages.proofRequired)}
        </TextStyle>

        <Controller
          defaultValue={false}
          name="proof.isRequired"
          control={control}
          render={({ field: { onChange, value } }) => (
            <ToggleSwitch
              disabled={disabled}
              tooltipText={tooltipText}
              checked={value}
              onCheckedChange={(isChecked) => {
                onChange(isChecked);
                onRequiredProofChange(isChecked);
              }}
              aria-label={'proofRequired'}
            />
          )}
        />
      </div>
      {Boolean(isProofRequired) && (
        <>
          <TextStyle as="span" variant="sm-medium" className="text-gray-9">
            {formatMessage(messages.typesQuestion)}
          </TextStyle>
          <div className="flex flex-col gap-3 py-1">
            {controlledFields.map((item, index) => {
              const error =
                errors.proof?.content?.[index]?.promptText?.message ?? '';
              const title = getValues(
                `proof.content.${index}.type`
              ).toLowerCase();
              return (
                <div key={index}>
                  <div className="flex items-center gap-2 py-2">
                    <div>
                      <div className="flex">
                        <Controller
                          defaultValue={Boolean(item.promptText)}
                          name={`proof.content.${index}.isChecked`}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Checkbox
                              className={
                                disabled
                                  ? 'cursor-not-allowed'
                                  : 'cursor-pointer'
                              }
                              disabled={disabled}
                              tooltipText={tooltipText}
                              id={item.id}
                              checked={value}
                              onChange={(e) => {
                                onChange(e);
                                onProofCheckboxChange();
                              }}
                            />
                          )}
                        />
                        <label
                          htmlFor={item.id}
                          className={'ml-2 cursor-pointer text-sm text-gray-9'}
                        >
                          {formatMessage(
                            messages[title as keyof typeof messages]
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                  {Boolean(getValues(`proof.content.${index}.isChecked`)) && (
                    <TextField
                      {...register(`proof.content.${index}.promptText`)}
                      disabled={disabled}
                      tooltipText={tooltipText}
                      type="text"
                      invalidText={error}
                      label={formatMessage(messages.customisePrompt)}
                      isInvalid={Boolean(error)}
                      inputClassName={inputClassName}
                      labelClassName={labelClassName}
                      aria-label={item.id}
                      helpText={formatMessage(messages.proofHintLabel)}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
