import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';

export function EmptyOrErrorStateTemplate({
  image,
  heading,
  altText,
  subHeading,
}: {
  image: string;
  altText: string;
  heading: string;
  subHeading: string;
}) {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <img src={image} alt={altText} className="h-[150px] w-[230px]" />
      <h3 className="mb-1 text-center font-medium text-gray-9">{heading}</h3>
      <TextStyle className="text-center text-gray-8" variant="sm-regular">
        {subHeading}
      </TextStyle>
    </div>
  );
}
