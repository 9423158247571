import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  Close,
  Content,
  Overlay,
  Portal,
  Root,
  Title,
} from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';

export type ModalProps = {
  dataTestId?: string;
  isOpen: boolean;
  onClose: () => void;
  title: ReactNode;
  children: ReactNode;
  ctaSection?: ReactNode;
  className?: string;
  ctaSectionClassName?: string;
  bodyClassName?: string;
  headerClassName?: string;
  subtitle?: ReactNode;
  showHeaderSeparator?: boolean;
  closeButtonSize?: 'small' | 'large' | 'xSmall';
};

const messages = defineMessages({
  close: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
});

export const V3Modal = forwardRef<HTMLDivElement, ModalProps>(
  ({ closeButtonSize = 'large', ...props }, ref) => {
    const { formatMessage } = useIntl();
    const device = useDeviceInfo();

    const notMobile = device.deviceType !== 'mobile';

    return (
      <Root
        open={props.isOpen}
        onOpenChange={(open) => {
          if (!open) {
            props.onClose();
          }
        }}
      >
        <AnimatePresence>
          {Boolean(props.isOpen) && (
            <Portal forceMount>
              <Overlay
                asChild
                forceMount
                className="fixed inset-0 z-20 bg-neutral-secondary bg-opacity-75"
              >
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{
                    opacity: 0,
                    transition: { duration: 0.2, ease: 'easeIn' },
                  }}
                  transition={{ duration: 0.3, ease: 'easeOut' }}
                />
              </Overlay>
              <Content asChild forceMount>
                <motion.div
                  initial={{
                    opacity: 0,
                    x: '-50%',
                    y: '-50%',
                    ...(notMobile && { scale: 0.95 }),
                  }}
                  animate={{
                    opacity: 1,
                    x: '-50%',
                    y: '-50%',
                    ...(notMobile && { scale: 1 }),
                  }}
                  exit={{
                    opacity: 0,
                    x: '-50%',
                    y: '-50%',
                    ...(notMobile && { scale: 0.95 }),
                    transition: { duration: 0.2, ease: 'easeIn' },
                  }}
                  transition={{ duration: 0.3, ease: 'easeOut' }}
                  className={twMerge(
                    'fixed left-[50%] top-[50%] z-20 max-w-[90vw] -translate-x-1/2 -translate-y-1/2 transform overflow-hidden rounded-2xl bg-gray-1 p-6 pt-4 text-left shadow-xl-down focus:outline-none sm:w-full sm:max-w-lg',
                    props.className
                  )}
                  ref={ref}
                >
                  <header
                    className={twMerge(
                      'sticky top-0 z-50 flex h-fit w-full items-center justify-between bg-gray-1',
                      props.headerClassName
                    )}
                  >
                    <Title asChild className="text-left leading-6">
                      <TextStyle variant="2xl-bold" className="text-gray-9">
                        {props.title}
                      </TextStyle>
                    </Title>

                    <Close asChild className="flex-shrink-0">
                      <IconButton
                        variation="tertiaryLite"
                        size={closeButtonSize}
                        data-testid="modalCloseButton"
                      >
                        <span className="sr-only">
                          {formatMessage(messages.close)}
                        </span>
                        <XMarkIcon
                          className={twMerge(
                            'h-4 w-4',
                            closeButtonSize === 'large' && 'h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                      </IconButton>
                    </Close>
                  </header>
                  {Boolean(props.subtitle) && (
                    <TextStyle
                      variant="sm-regular"
                      className="py-4 text-start leading-[22px] text-gray-9"
                    >
                      {props.subtitle}
                    </TextStyle>
                  )}

                  <div
                    className={twMerge(
                      'max-h-[60vh] flex-1 overflow-y-auto pt-4 text-gray-9',
                      props.ctaSection && 'pt-2',
                      props.bodyClassName,
                      !notMobile ? 'flex-1' : ''
                    )}
                  >
                    {props.children}
                  </div>
                  {Boolean(props.ctaSection) && (
                    <div
                      className={twMerge(
                        'sticky bottom-0 z-50 flex h-fit w-full justify-between bg-gray-1 pt-6',
                        props.ctaSectionClassName
                      )}
                    >
                      {props.ctaSection}
                    </div>
                  )}
                </motion.div>
              </Content>
            </Portal>
          )}
        </AnimatePresence>
      </Root>
    );
  }
);

V3Modal.displayName = 'V3Modal';
