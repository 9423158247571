import { PlusCircleIcon } from '@heroicons/react/24/outline';
import {
  type InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import type { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import type { DecoratorNode, LexicalEditor } from 'lexical';
import type { FocusEventHandler, ReactNode } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../../DesignSystem/Feedback/TextStyle';
import { Chip, type ChipStyles } from '../../../../DesignSystem/Inputs/Chip';
import {
  ComboboxPlugin,
  type ComboboxPluginProps,
} from '../base/plugins/ComboboxPlugin';
import { ExternalOnChangePlugin } from '../base/plugins/ExternalOnChangePlugin';
import { PreventAutoFocusPlugin } from '../base/plugins/PreventAutoFocusPlugin';
import { UpdateEditorPlugin } from '../base/plugins/UpdateEditorPlugin';
import { EditorTheme } from '../base/theme';

const config = {
  theme: {
    ...EditorTheme,
    paragraph: 'flex flex-wrap gap-y-1 gap-x-1 items-center',
  },
  namespace: 'combobox-editor',
  onError: console.error,
} satisfies InitialConfigType;

export type ComboboxProps<
  TOptionClass extends MenuOption,
  TNode extends DecoratorNode<ReactNode>,
> = {
  autoFocusOnMount?: boolean;
  disabled?: boolean;
  onBlur?: FocusEventHandler;
  onChange?: (args: { html: string; json: string; plainText: string }) => void;
  onError?: (error: Error, editor: LexicalEditor) => void;
  onFocus?: FocusEventHandler;
  ComboboxProps: ComboboxPluginProps<TOptionClass, TNode>;
  config: InitialConfigType;
  label: string;
  error?: string;
  placeholderVariant?: ChipStyles['color'];
  draftValue?: string;
};

export function Combobox<
  TOptionClass extends MenuOption,
  TNode extends DecoratorNode<ReactNode>,
>({
  autoFocusOnMount = false,
  disabled,
  onBlur,
  onChange,
  onError,
  onFocus,
  ComboboxProps,
  config,
  label,
  error,
  placeholderVariant = 'blue',
  draftValue,
}: ComboboxProps<TOptionClass, TNode>) {
  const { formatMessage } = useIntl();

  return (
    <div className="relative flex flex-1 flex-col gap-2 overflow-hidden">
      <LexicalComposer
        initialConfig={{
          ...config,
          onError: (error, editor) => onError?.(error, editor),
        }}
      >
        <RichTextPlugin
          ErrorBoundary={LexicalErrorBoundary}
          contentEditable={
            <div
              className={twMerge(
                'relative flex-1 overflow-hidden rounded-md border-0 outline-[0] focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0',
                disabled && 'cursor-not-allowed bg-gray-2'
              )}
            >
              <ContentEditable
                disabled={disabled}
                className="relative z-0 flex h-full min-h-[38px] flex-col gap-1 overflow-y-auto text-base text-gray-9 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0"
                ariaLabel={label}
                onBlur={onBlur}
                onFocus={onFocus}
              />
            </div>
          }
          placeholder={
            <Chip.Root
              intent="badge"
              color={placeholderVariant ?? 'blue'}
              className="pointer-events-none absolute translate-x-0.5 cursor-pointer touch-none select-none"
            >
              <Chip.Text>
                {formatMessage(
                  defineMessage({ defaultMessage: 'Add', id: '2/2yg+' })
                )}
              </Chip.Text>
              <PlusCircleIcon className="h-6 w-6 text-gray-8" />
            </Chip.Root>
          }
        />
        <ComboboxPlugin {...ComboboxProps} />
        <ExternalOnChangePlugin onChange={onChange} />
        <UpdateEditorPlugin draftState={draftValue} />
        {Boolean(!autoFocusOnMount) && <PreventAutoFocusPlugin />}
      </LexicalComposer>
      {Boolean(error) && (
        <TextStyle variant="sm-regular" className="text-error-7">
          {error}
        </TextStyle>
      )}
    </div>
  );
}
Combobox.initialConfig = config;
