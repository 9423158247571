import {
  type GroupOrUserChipData,
  logger,
  type Recipients,
  useDebounce,
  useGetDepartmentsQuery,
  useRecipientsMembers,
} from '@assembly-web/services';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { Fragment, useMemo, useRef, useState } from 'react';
import type { FieldError } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import {
  $createUserChipNode,
  type SerializedUserChipNode,
  UserChipNode,
  UserTypeaheadOption,
} from './Editors/base/nodes/ComboboxChipNode/UserChipNode';
import { ComboboxPlugin } from './Editors/base/plugins/ComboboxPlugin';
import { Combobox } from './Editors/Combobox/Combobox';
import { LoadMore } from './LoadMore';
import { QuickParticipation } from './QuickParticipation/QuickParticipation';

const MaxOptions = 3;

const config = { ...Combobox.initialConfig, nodes: [UserChipNode] };

const messages = defineMessages({
  groupLabel: {
    defaultMessage: 'Group',
    id: 'HpoDwk',
  },
  peopleLabel: {
    defaultMessage: 'People',
    id: 'Tpy00S',
  },
  everyone: {
    defaultMessage: 'Everyone',
    id: '2XDuH4',
  },
  showMore: {
    defaultMessage: 'See more ({count, number})',
    id: '2+thJJ',
  },
  showLess: {
    defaultMessage: 'Show less',
    id: 'qyJtWy',
  },
  countWithBracket: {
    defaultMessage: '({count, number})',
    id: 'ZgwNJ8',
  },
});

function ShowMoreItem({
  groupsExpanded,
  departmentsCount,
}: {
  groupsExpanded: boolean;
  departmentsCount: number;
}) {
  const { formatMessage } = useIntl();

  return (
    <>
      <span>
        {groupsExpanded
          ? formatMessage(messages.showLess)
          : formatMessage(messages.showMore, {
              count: departmentsCount - MaxOptions,
            })}
      </span>
      <ChevronUpIcon
        className={twMerge(
          'h-4 w-4 flex-shrink-0 text-gray-9',
          !groupsExpanded && 'rotate-180'
        )}
      />
    </>
  );
}

function SelectItem({
  option,
  isSelected,
}: {
  option: UserTypeaheadOption;
  isSelected: boolean;
}) {
  const { formatMessage } = useIntl();

  if (option.__data.type === 'moreDepartmentOption') {
    throw new Error('Cannot create a chip node for more department option');
  }

  return (
    <QuickParticipation.MemberItem
      {...(option.__data.type === 'department'
        ? {
            type: 'department',
            countDescription: formatMessage(messages.countWithBracket, {
              count: option.__data.count,
            }),
            id: option.__data.id,
            isSelected,
            name: option.__data.name,
          }
        : {
            type: 'member',
            id: option.__data.memberID,
            isSelected,
            memberId: option.__data.memberID,
            name: option.__data.name,
            image: option.__data.image,
            memberState: option.__data.memberState,
          })}
    />
  );
}

type PersonSelectorProps = {
  onBlur?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (args: any) => void;
  error?: FieldError;
  defaultRecipients?: GroupOrUserChipData[];
  label: string;
};

export function PersonSelector({
  onChange,
  error,
  onBlur,
  defaultRecipients,
  label,
}: PersonSelectorProps) {
  const { formatMessage } = useIntl();

  const [groupsExpanded, setGroupsExpanded] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const differedSearchTerm = useDebounce(searchTerm, 300);
  const initialDraftStateSet = useRef(false);

  const {
    fetchNextPageInMembersList,
    hasNextPageInMembersList,
    isFetchingNextPageInMembersList,
    members: transformedMembers,
    membersQueryFetchStatus,
    membersQueryStatus,
  } = useRecipientsMembers({ searchTerm: differedSearchTerm });

  const {
    data: departments,
    status: departmentsStatus,
    fetchStatus: departmentsFetchStatus,
  } = useGetDepartmentsQuery({ search: differedSearchTerm });

  const departmentsCount = departments?.length ?? 0;
  const canShowGroupExpansion = departmentsCount > MaxOptions;
  const transformedDepartments = useMemo(
    () => [
      ...(
        (departments?.map((department) => ({
          type: 'department',
          id: department.id,
          count: department.memberCount,
          name:
            department.id === 'everyone'
              ? formatMessage(messages.everyone)
              : department.name,
        })) ?? []) satisfies Recipients['department']
      ).slice(0, groupsExpanded ? -1 : MaxOptions),
      ...(canShowGroupExpansion
        ? [
            {
              count: departmentsCount - MaxOptions,
              id: 'moreDepartmentOption',
              name: 'moreDepartmentOption',
              type: 'moreDepartmentOption',
            } satisfies Recipients['moreDepartmentOption'],
          ]
        : []),
    ],
    [
      canShowGroupExpansion,
      departments,
      departmentsCount,
      formatMessage,
      groupsExpanded,
    ]
  );

  const combinedTransformedList = useMemo(
    () => [...transformedDepartments, ...transformedMembers],
    [transformedDepartments, transformedMembers]
  );
  const options = useMemo(
    () => combinedTransformedList.map((data) => new UserTypeaheadOption(data)),
    [combinedTransformedList]
  );
  const groupedOptions = useMemo(() => {
    return [
      {
        label: formatMessage(messages.groupLabel),
        values: transformedDepartments,
      },
      {
        label: formatMessage(messages.peopleLabel),
        values: transformedMembers,
      },
    ].filter((options) => options.values.length > 0);
  }, [formatMessage, transformedDepartments, transformedMembers]);
  const hasMultipleGroupedOptions = groupedOptions.length > 1;

  const defaultValue = useMemo(() => {
    if (
      initialDraftStateSet.current ||
      !defaultRecipients ||
      (Array.isArray(defaultRecipients) && defaultRecipients.length === 0)
    ) {
      return undefined;
    }
    initialDraftStateSet.current = true;

    return ComboboxPlugin.$generateInitialState(
      defaultRecipients.map((value) => {
        if (value.type === 'department') {
          return {
            type: 'combobox-chip',
            version: 1,
            data: {
              type: 'department',
              count:
                departments?.find(
                  ({ name }) => name.toLowerCase() === value.name.toLowerCase()
                )?.memberCount ?? 0,
              id: value.id,
              name:
                value.name === 'everyone'
                  ? formatMessage(messages.everyone)
                  : value.name,
            },
            id: value.id,
            name:
              value.name === 'everyone'
                ? formatMessage(messages.everyone)
                : value.name,
          } satisfies SerializedUserChipNode;
        }
        return {
          type: 'combobox-chip',
          version: 1,
          data: {
            type: 'member',
            email: value.email,
            firstName: value.firstName,
            image: value.image,
            lastName: value.lastName,
            memberId: value.memberId,
            memberID: value.memberID,
            memberState: value.memberState,
            name: value.name,
            role: value.role,
            status: value.status,
            department: value.department,
            jobTitle: value.jobTitle,
            pronouns: value.pronouns,
            username: value.username,
          },
          id: value.memberID,
          name: value.name,
        } satisfies SerializedUserChipNode;
      })
    );
  }, [defaultRecipients, departments, formatMessage]);

  const isLoading =
    (membersQueryFetchStatus === 'fetching' &&
      membersQueryStatus === 'pending') ||
    (departmentsStatus === 'pending' && departmentsFetchStatus === 'fetching');

  const allLoaded =
    membersQueryFetchStatus === 'idle' &&
    membersQueryStatus === 'success' &&
    departmentsFetchStatus === 'idle' &&
    departmentsStatus === 'success';

  return (
    <Combobox<UserTypeaheadOption, UserChipNode>
      error={error?.message}
      label={label}
      config={config}
      onBlur={onBlur}
      onError={(error, editor) => {
        logger.error(
          'Participation Person Selector Editor Error',
          {
            editor,
          },
          error
        );
      }}
      draftValue={defaultValue}
      ComboboxProps={{
        onSelectedNodesChange(nodes) {
          onChange(
            nodes.map((node) => node.__data) satisfies GroupOrUserChipData[]
          );
        },
        $createChipNode: $createUserChipNode,
        type: 'multiple',
        getKey(option) {
          return option.__data.type === 'member'
            ? option.__data.memberID
            : option.__data.name;
        },
        getChipKey(chip) {
          return chip.__data.type === 'member'
            ? chip.__data.memberID
            : chip.__data.name;
        },
        onSearchQueryChange(query) {
          if (query == null || query === ' ') {
            setSearchTerm('');
            return;
          }
          setSearchTerm(query);
        },
        options,
        onSelectOption(option) {
          if (option.__data.type === 'moreDepartmentOption') {
            setGroupsExpanded((prev) => !prev);
            return true;
          }
          return false;
        },
        children({
          isSelected,
          options,
          selectOptionAndCleanUp,
          selectedIndex,
          setHighlightedIndex,
        }) {
          return (
            <ComboboxPlugin.Root
              className={twJoin(
                'max-h-[288px] overflow-auto',
                options.length > 4 && 'h-full'
              )}
            >
              <ComboboxPlugin.List>
                {Boolean(allLoaded) &&
                  transformedMembers.length === 0 &&
                  transformedDepartments.length === 0 && (
                    <ComboboxPlugin.NoResults />
                  )}
                {Boolean(isLoading) && <ComboboxPlugin.Loader />}
                {(() => {
                  let runningCount = -1;
                  return groupedOptions.map((group) => {
                    const GroupContainer = hasMultipleGroupedOptions
                      ? ComboboxPlugin.Group
                      : Fragment;
                    const GroupLabel = hasMultipleGroupedOptions
                      ? ComboboxPlugin.GroupLabel
                      : Fragment;

                    return (
                      <GroupContainer key={group.label}>
                        {Boolean(hasMultipleGroupedOptions) && (
                          <GroupLabel>{group.label}</GroupLabel>
                        )}
                        {group.values.map(() => {
                          runningCount += 1;
                          const currentCount = runningCount;
                          const typeaheadOption = options[currentCount];

                          const isShowMoreOption =
                            typeaheadOption.__data.type ===
                            'moreDepartmentOption';

                          const isHighlighted = selectedIndex === currentCount;

                          const onClick = () => {
                            if (isShowMoreOption) {
                              setGroupsExpanded((prev) => !prev);
                              return;
                            }
                            selectOptionAndCleanUp(typeaheadOption);
                          };

                          const onMouseEnter = () => {
                            setHighlightedIndex(currentCount);
                          };

                          const key =
                            typeaheadOption.__data.type === 'member'
                              ? typeaheadOption.__data.memberID
                              : typeaheadOption.__data.name;

                          return (
                            <Fragment key={key}>
                              <ComboboxPlugin.ListItem<UserTypeaheadOption>
                                isSelected={
                                  isShowMoreOption
                                    ? groupsExpanded
                                    : isSelected(typeaheadOption)
                                }
                                onClick={onClick}
                                onMouseEnter={onMouseEnter}
                                index={currentCount}
                                option={typeaheadOption}
                                renderOption={({ option, isSelected }) => (
                                  <QuickParticipation.ItemWrapper
                                    isSelected={isSelected}
                                    highlighted={isHighlighted}
                                    className={twJoin(
                                      isShowMoreOption &&
                                        groupsExpanded &&
                                        'text-gray-9'
                                    )}
                                  >
                                    {isShowMoreOption ? (
                                      <ShowMoreItem
                                        departmentsCount={departmentsCount}
                                        groupsExpanded={groupsExpanded}
                                      />
                                    ) : (
                                      <SelectItem
                                        isSelected={isSelected}
                                        option={option}
                                      />
                                    )}
                                  </QuickParticipation.ItemWrapper>
                                )}
                              />
                              {currentCount === options.length - 1 && (
                                <>
                                  {Boolean(hasNextPageInMembersList) && (
                                    <LoadMore
                                      onNextPageScrolled={
                                        fetchNextPageInMembersList
                                      }
                                    />
                                  )}
                                  {Boolean(isFetchingNextPageInMembersList) && (
                                    <ComboboxPlugin.Loader />
                                  )}
                                </>
                              )}
                            </Fragment>
                          );
                        })}
                      </GroupContainer>
                    );
                  });
                })()}
              </ComboboxPlugin.List>
            </ComboboxPlugin.Root>
          );
        },
      }}
    />
  );
}
