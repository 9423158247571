import type { Member } from '@assembly-web/services';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import * as Collapsible from '@radix-ui/react-collapsible';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import type { Key } from 'react';
import { twMerge } from 'tailwind-merge';
import type { Except } from 'type-fest';

import { Avatar, AvatarSize } from '../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../DesignSystem/Feedback/TextStyle';
import { Icon, type IconNode } from './Icon';

type ProfileMenuItem = {
  text: string;
  icon: IconNode;
};

type ProfileMenuProps = {
  variant: 'dropdown' | 'collapsible';
  menuItems: Map<Key, ProfileMenuItem>;
  open?: boolean;
  member: Member;
  onOpenChange?: (open: boolean) => void;
  onMenuItemClick: (key: Key) => void;
};

const triggerClassName =
  'flex cursor-pointer select-none items-center gap-2 rounded-lg bg-transparent px-2 py-1 [animation-duration:_400ms] [animation-timing-function:_cubic-bezier(0.16,_1,_0.3,_1)] group';

const arrowClassName =
  'h-5 w-5 group-hover:text-primary-8 group-focus-within:text-primary-8';

const contentClassName =
  'flex w-52 flex-col gap-2 rounded-2xl bg-gray-1 p-4 shadow-lg-down [animation-duration:_400ms] [animation-timing-function:_cubic-bezier(0.16,_1,_0.3,_1)]';

const itemClassName =
  'flex cursor-pointer items-center gap-2 px-3 py-1 focus-visible:bg-gray-3 hover:bg-gray-3 focus:bg-gray-3 hover:outline-none active:bg-gray-3 rounded-lg';

function DropdownProfileMenu({
  member,
  menuItems,
  open,
  onMenuItemClick,
  onOpenChange,
}: Except<ProfileMenuProps, 'variant'>) {
  return (
    <DropdownMenu.Root open={open} onOpenChange={onOpenChange}>
      <DropdownMenu.Trigger
        className={twMerge(triggerClassName, 'w-20 min-w-20')}
      >
        <Avatar
          size={AvatarSize.Large}
          memberID={member.memberId}
          name={member.profile.fullName}
          image={member.profile.image}
        />

        {open ? (
          <ChevronUpIcon className={arrowClassName} />
        ) : (
          <ChevronDownIcon className={arrowClassName} />
        )}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={contentClassName}
          align="end"
          sideOffset={5}
          alignOffset={-3}
        >
          {Array.from(menuItems).map(([key, { text, icon }]) => (
            <DropdownMenu.Item
              key={key}
              className={itemClassName}
              onClick={() => onMenuItemClick(key)}
            >
              <Icon icon={icon} className="h-4 w-4 text-gray-8" />
              <TextStyle subdued>{text}</TextStyle>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

function CollapsibleProfileMenu({
  member,
  menuItems,
  open,
  onMenuItemClick,
  onOpenChange,
}: Except<ProfileMenuProps, 'variant'>) {
  return (
    <Collapsible.Root open={open} onOpenChange={onOpenChange}>
      <Collapsible.Trigger
        className={twMerge(
          triggerClassName,
          'w-fit max-w-full data-[state="highlighted"]:bg-gray-1 data-[state="open"]:bg-gray-1'
        )}
      >
        <Avatar
          size={AvatarSize.Medium}
          memberID={member.memberId}
          name={member.profile.fullName}
          image={member.profile.image}
        />
        <span className="truncate">{member.profile.fullName}</span>

        {open ? (
          <ChevronDownIcon className={arrowClassName} />
        ) : (
          <ChevronRightIcon className={arrowClassName} />
        )}
      </Collapsible.Trigger>

      <Collapsible.Content
        className={twMerge(
          contentClassName,
          'w-full p-0 shadow-none data-[state="open"]:pt-4'
        )}
      >
        {Array.from(menuItems).map(([key, { text, icon }]) => (
          <button
            key={key}
            className={twMerge(itemClassName, 'px-0 pl-2')}
            onClick={() => onMenuItemClick(key)}
            data-closable-element
          >
            <Icon icon={icon} className="h-4 w-4 stroke-[1.5] text-gray-8" />
            <TextStyle variant="base-medium">{text}</TextStyle>
          </button>
        ))}
      </Collapsible.Content>
    </Collapsible.Root>
  );
}

/**
 * User profile menu that can be embedded in a dropdown or collapsible
 * mode with the best a11y practices for each variant.
 **/
export function ProfileMenu({ variant, ...props }: ProfileMenuProps) {
  if (variant === 'dropdown') {
    return <DropdownProfileMenu {...props} />;
  }
  return <CollapsibleProfileMenu {...props} />;
}
