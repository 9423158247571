import { Slot, type SlotProps } from '@radix-ui/react-slot';
import { type ElementRef, forwardRef, useEffect, useRef } from 'react';
import { useInViewport } from 'react-in-viewport';

export const LoadMore = forwardRef<
  HTMLElement,
  Omit<SlotProps, 'children'> & {
    asChild?: boolean;
    onNextPageScrolled: () => void;
  }
>(function LoadMoreItem({ onNextPageScrolled, asChild, ...rest }, ref) {
  const endOfListRef = useRef<ElementRef<'div'>>(null);
  const { inViewport: hasReachedEndOfDropdownItem } =
    useInViewport(endOfListRef);

  const Comp = asChild ? Slot : 'div';

  useEffect(() => {
    if (hasReachedEndOfDropdownItem) {
      onNextPageScrolled();
    }
  }, [hasReachedEndOfDropdownItem, onNextPageScrolled]);

  return (
    <Comp ref={ref as never} {...rest} role="option">
      <div ref={endOfListRef} className="h-0" />
    </Comp>
  );
});
