import { useMemo } from 'react';

import { GlobalFilterOption } from '../../constants/filter';
import type { Recipients } from '../../types/recognitionParticipation';
import { type SearchPayload, useSearchIndex } from './useSearchIndex';
import { useSuspenseUserDetails } from './useUserDetails';

export const useRecipientsMembers = ({
  customSort,
  filter = GlobalFilterOption.People,
  searchTerm,
  secondaryFilters,
}: {
  department?: string[];
  filter?: GlobalFilterOption;
  searchTerm: string;
  customSort?: SearchPayload['customSort'];
  secondaryFilters?: SearchPayload['secondaryFilters'];
}) => {
  const {
    data: { member: user },
  } = useSuspenseUserDetails();

  const {
    hasNextPage: hasNextPageInMembersList,
    fetchNextPage: fetchNextPageInMembersList,
    isFetchingNextPage: isFetchingNextPageInMembersList,
    status: membersQueryStatus,
    fetchStatus: membersQueryFetchStatus,
    data: membersData,
  } = useSearchIndex<{ members: Recipients['individuals']; total: number }>(
    {
      searchTerm,
      enabled: true,
      selectedFilters: [filter],
      populateCardDetails: true,
      customSort,
      secondaryFilters: {
        ...secondaryFilters,
        state: ['ACTIVE', 'INACTIVE'],
        memberState: ['ACTIVE', 'PENDING'],
      },
    },
    {
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => {
            return {
              total: page.data.total,
              members: page.data.data
                .filter((searchResult) => searchResult.type === 'member')
                .map((searchResult) => {
                  return {
                    department: searchResult._meta.department,
                    email: searchResult._meta.email,
                    image: searchResult._meta.profileImageUrl,
                    memberId: searchResult.id,
                    memberID: searchResult.id,
                    name: searchResult.name,
                    role: searchResult._meta.roles,
                    status: searchResult._meta.profileStatus,
                    firstName: searchResult._meta.firstName,
                    lastName: searchResult._meta.lastName,
                    memberState: searchResult._meta.state,
                    jobTitle: searchResult._meta.jobTitle,
                    pronouns: searchResult._meta.pronouns,
                    username: searchResult._meta.username,
                    type: 'member',
                  };
                }),
            };
          }),
        };
      },
    }
  );
  const members = useMemo(
    () => membersData?.pages.flatMap((x) => x.members) ?? [],
    [membersData]
  );
  const transformedMembers = useMemo(() => {
    const formattedArray: Recipients['individuals'] = [];

    for (const member of members) {
      if (member.memberID === user.memberId) {
        continue;
      }
      formattedArray.push(member);
    }

    return formattedArray;
  }, [members, user.memberId]);

  return {
    hasNextPageInMembersList,
    fetchNextPageInMembersList,
    isFetchingNextPageInMembersList,
    membersQueryStatus,
    membersQueryFetchStatus,
    members: transformedMembers,
    totalMembers: membersData?.pages[0].total ?? 0,
  };
};
