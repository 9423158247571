import { MemberState, type ReactionDetails } from '@assembly-web/services';
import { defineMessages, type IntlShape } from 'react-intl';

const messages = defineMessages({
  membersReactedWithEmoji: {
    defaultMessage: '{memberNames} reacted with emoji',
    id: '7r4xs8',
  },
  youReactedWithEmoji: { defaultMessage: 'you', id: 'Jjrc4P' },
});

export function getReactedMembersTooltipText(
  reaction: ReactionDetails,
  currentMemberId: string,
  formatMessage: IntlShape['formatMessage']
) {
  const { members } = reaction;
  const memberNames = members.map((member, index) => {
    const currentMemberText =
      index === 0
        ? formatMessage(messages.youReactedWithEmoji).charAt(0).toUpperCase() +
          formatMessage(messages.youReactedWithEmoji).slice(1)
        : formatMessage(messages.youReactedWithEmoji);
    const reactedMember =
      member.memberID === currentMemberId ? currentMemberText : member.name;
    const reactedMemberWithStateText =
      member.memberState === MemberState.Deactivated
        ? `${reactedMember} (deactivated)`
        : reactedMember;
    return reactedMemberWithStateText;
  });
  const totalMembersLength = memberNames.length;
  const maxMembers = 40;
  const maxReactedMembersText = `${memberNames
    .slice(0, maxMembers)
    .join(', ')} and ${totalMembersLength - maxMembers} ${
    totalMembersLength - maxMembers === 1 ? 'other' : 'others'
  }`;
  const reactedMembers =
    totalMembersLength > maxMembers
      ? maxReactedMembersText
      : memberNames.join(', ');
  return formatMessage(messages.membersReactedWithEmoji, {
    memberNames: reactedMembers,
  });
}
