import {
  type Flow,
  isTruthy,
  mapHexCodeToEmoticon,
} from '@assembly-web/services';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import dayjs from 'dayjs';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { useFormattedDueDateDuration } from '../../hooks/useFormattedDueDateDuration';

type FlowsToDoMenuProps = {
  alignOffset?: number;
  answerNowFlows: (Flow & { dueDate: string | undefined })[];
  children: ReactNode;
  answerAnytimeFlows: Flow[];
  defaultOpen?: boolean;
  fullWidth?: boolean;
  side?: DropdownMenu.DropdownMenuContentProps['side'];
  onMenuItemClick: (flowId: string, flowName: string) => void;
  handleOnClose?: () => void;
  onOpenChange?: (isOpen: boolean) => void;
};

const messages = defineMessages({
  answerNow: {
    defaultMessage: 'Answer now',
    id: 'Bpz0xZ',
  },
  answerAnytime: {
    defaultMessage: 'Answer anytime',
    id: '4euen7',
  },
});

export function FlowsToDoMenu(props: FlowsToDoMenuProps) {
  const {
    alignOffset = 0,
    answerAnytimeFlows,
    answerNowFlows,
    children,
    defaultOpen = false,
    fullWidth = false,
    side = 'bottom',
    onMenuItemClick,
    handleOnClose,
    onOpenChange,
  } = props;

  const { formatMessage } = useIntl();

  const { getFormattedDuration } = useFormattedDueDateDuration();

  return (
    <DropdownMenu.Root
      defaultOpen={defaultOpen}
      onOpenChange={(isOpen) => {
        onOpenChange?.(isOpen);
        if (!isOpen && handleOnClose) {
          handleOnClose();
        }
      }}
    >
      <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          side={side}
          className={twMerge(
            'z-[4] max-h-[32rem] overflow-auto rounded-md border border-gray-5 bg-gray-1 shadow-lg-down ring-opacity-5 focus:outline-none',
            fullWidth ? 'w-[var(--radix-dropdown-menu-trigger-width)]' : 'w-56'
          )}
          align="start"
          sideOffset={5}
          alignOffset={alignOffset}
          hideWhenDetached
        >
          {answerNowFlows.length > 0 && (
            <DropdownMenu.Label asChild>
              <TextStyle variant="sm-regular" className="px-3 py-2 text-gray-7">
                {formatMessage(messages.answerNow)}
              </TextStyle>
            </DropdownMenu.Label>
          )}
          <DropdownMenu.Group>
            {answerNowFlows
              .sort(({ dueDate: a }, { dueDate: b }) =>
                dayjs(a).isAfter(dayjs(b)) ? 1 : -1
              )
              .map((flow) => (
                <DropdownMenu.Item
                  key={flow.id}
                  className="hover:cursor-pointer focus:bg-primary-1 focus:outline-none"
                  onClick={() => onMenuItemClick(flow.id, flow.name)}
                  asChild
                >
                  <div className="flex w-full items-center rounded-md px-3 py-1 text-sm text-gray-8 hover:bg-primary-2">
                    <div className="flex w-full justify-between gap-1">
                      <div className="flex w-full min-w-0 items-center gap-1">
                        <div className="flex-shrink-0">
                          {mapHexCodeToEmoticon(flow.icon.value)}
                        </div>
                        <div
                          className={twJoin(
                            `min-w-0 flex-grow`,
                            isTruthy(flow.dueDate) && 'mr-1'
                          )}
                        >
                          <div className="truncate">{flow.name}</div>
                        </div>
                      </div>
                      {isTruthy(flow.dueDate) && (
                        <TextStyle
                          variant="sm-medium"
                          className="flex max-w-16 justify-center text-nowrap rounded-lg bg-upgrade-2 px-2 py-1 text-center text-gray-9"
                        >
                          {getFormattedDuration(flow.dueDate)}
                        </TextStyle>
                      )}
                    </div>
                  </div>
                </DropdownMenu.Item>
              ))}
          </DropdownMenu.Group>
          <DropdownMenu.Separator className="MenubarSeparator" />
          <DropdownMenu.Label asChild>
            <TextStyle variant="sm-regular" className="px-3 py-2 text-gray-7">
              {formatMessage(messages.answerAnytime)}
            </TextStyle>
          </DropdownMenu.Label>
          <DropdownMenu.Group>
            {answerAnytimeFlows.map((flow) => (
              <DropdownMenu.Item
                key={flow.id}
                className="focus:bg-primary-1 focus:outline-none"
                onClick={() => {
                  onMenuItemClick(flow.id, flow.name);
                }}
                asChild
              >
                <div className="flex w-full items-center rounded-md px-3 py-2 text-sm text-gray-8 hover:bg-primary-2">
                  <div className="flex gap-2">
                    <div>{mapHexCodeToEmoticon(flow.icon.value)}</div>
                    <div className="line-clamp-1 text-left">{flow.name}</div>
                  </div>
                </div>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Group>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}
