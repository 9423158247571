const RecognitionIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="#595959"
      strokeWidth="1.5"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.26263 6.184L8.86663 4.44C9.72218 3.51022 9.82907 2.69667 9.18729 1.99934M5.33329 3.5V3.16667M13.3333 3V2.66667M13.3333 9V8.66667M12 12.3333V12M9.18729 8.276L10.7913 6.53267C11.8611 5.37 12.9306 5.37 14 6.53267M2.44996 13.9807C2.3904 14.0014 2.32623 14.0051 2.26468 13.9913C2.20314 13.9775 2.14667 13.9468 2.10162 13.9027C2.05658 13.8585 2.02476 13.8027 2.00974 13.7415C1.99472 13.6802 1.99711 13.616 2.01663 13.556L3.74329 6.14C3.82329 5.89067 4.14329 5.82334 4.31796 6.018L9.48863 11.0473C9.5257 11.089 9.55194 11.1392 9.56504 11.1935C9.57813 11.2477 9.57767 11.3043 9.56371 11.3583C9.54974 11.4124 9.52269 11.4621 9.48495 11.5032C9.44721 11.5443 9.39993 11.5755 9.34729 11.594L2.44996 13.9807Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RecognitionIcon;
