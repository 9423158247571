import { BookOpenIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/solid';
import { type ButtonHTMLAttributes, forwardRef, type ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';

type Children = { children: ReactNode };

const messages = defineMessages({
  title: {
    defaultMessage: 'How do you want to create a flow?',
    id: 'zuBcc5',
  },
  subTitle: {
    defaultMessage:
      'Get assistance, browse our pre-built templates, or start fresh',
    id: 'SOU+iF',
  },
  templateTitle: {
    defaultMessage: 'Template library',
    id: 'lO/Ima',
  },
  templateSubTitle: {
    defaultMessage:
      'Explore 200+ template suggestions created by Assembly’s team.',
    id: 'eNR6qF',
  },
  fromScratchTitle: { defaultMessage: 'From scratch', id: 'CYmj9p' },
  fromScratchSubTitle: {
    defaultMessage: 'Manual workflow building experience.',
    id: 'gA92CC',
  },
});

function HeaderContainer({ children }: Children) {
  return (
    <header className="flex flex-col gap-1 px-10 pb-4 pt-6 @[700px]/body:self-center">
      {children}
    </header>
  );
}

function Title() {
  const { formatMessage } = useIntl();

  return (
    <TextStyle variant="lg-bold">{formatMessage(messages.title)}</TextStyle>
  );
}

function SubTitle() {
  const { formatMessage } = useIntl();

  return (
    <TextStyle variant="base-regular" subdued>
      {formatMessage(messages.subTitle)}
    </TextStyle>
  );
}

function StaggeredTwoColumnRow({ children }: Children) {
  return (
    <div className="flex gap-3 px-10 [&>:first-child]:flex-[1.4] [&>:last-child]:flex-1">
      {children}
    </div>
  );
}

const Button = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(function Button({ className, ...tail }, ref) {
  return (
    <button
      {...tail}
      className={twJoin(
        'group/button flex min-h-36 flex-col gap-2 rounded-2xl border border-gray-5 px-6 py-6 hover:border-primary-6',
        className
      )}
      ref={ref}
    />
  );
});

function ChoiceIconContainer({ children }: Children) {
  return (
    <span
      aria-hidden
      className="flex h-9 w-9 items-center justify-center rounded-lg bg-gray-9 stroke-current p-2 text-gray-1 group-hover/button:bg-primary-6"
    >
      {children}
    </span>
  );
}

function ChoiceContent({
  subTitle,
  title,
}: {
  title: string;
  subTitle: string;
}) {
  return (
    <div className="flex flex-col items-start">
      <TextStyle variant="lg-bold">{title}</TextStyle>
      <TextStyle variant="sm-regular" className="text-start">
        {subTitle}
      </TextStyle>
    </div>
  );
}

function TemplateLibraryChoice(props: ButtonHTMLAttributes<HTMLButtonElement>) {
  const { formatMessage } = useIntl();

  return (
    <Button
      {...props}
      className="flex flex-col items-center justify-center md:items-start"
    >
      <ChoiceIconContainer>
        <BookOpenIcon className="h-6 w-6" />
      </ChoiceIconContainer>
      <ChoiceContent
        subTitle={formatMessage(messages.templateSubTitle)}
        title={formatMessage(messages.templateTitle)}
      />
    </Button>
  );
}

function FromScratchChoice(props: ButtonHTMLAttributes<HTMLButtonElement>) {
  const { formatMessage } = useIntl();

  return (
    <Button {...props} className="flex flex-col items-center md:items-start">
      <ChoiceIconContainer>
        <PlusIcon className="h-6 w-6" />
      </ChoiceIconContainer>
      <ChoiceContent
        subTitle={formatMessage(messages.fromScratchSubTitle)}
        title={formatMessage(messages.fromScratchTitle)}
      />
    </Button>
  );
}

export const CreationChoice = {
  Button,
  FromScratchChoice,
  HeaderContainer,
  SubTitle,
  StaggeredTwoColumnRow,
  TemplateLibraryChoice,
  Title,
};
