export enum GlobalFilterOption {
  Notifications = 'notifications',
  All = 'all',
  Collections = 'collections',
  Files = 'files',
  Flows = 'flows',
  People = 'people',
  Rewards = 'rewards',
  Tasks = 'tasks',
  Challenges = 'challenges',
  Recognition = 'recognition',
  Department = 'department',
}

export const defaultAggregates = {
  [GlobalFilterOption.All]: 0,
  [GlobalFilterOption.Collections]: 0,
  [GlobalFilterOption.Files]: 0,
  [GlobalFilterOption.Flows]: 0,
  [GlobalFilterOption.People]: 0,
  [GlobalFilterOption.Rewards]: 0,
  [GlobalFilterOption.Tasks]: 0,
  [GlobalFilterOption.Notifications]: 0,
  [GlobalFilterOption.Challenges]: 0,
  [GlobalFilterOption.Recognition]: 0,
  [GlobalFilterOption.Department]: 0,
};

export function getSecondaryFiltersQueryCacheKey(
  secondaryFilters: Record<string, string[] | undefined>
) {
  return Object.fromEntries(
    Object.entries(secondaryFilters)
      .filter(([, value]) => value && value.length > 0)
      .map(([key, value]) =>
        Array.isArray(value) ? [key, value.sort()] : [key, value]
      )
  );
}
