import { useIntl } from 'react-intl';
import ReactPlayer from 'react-player/lazy';

import { useDeviceInfo } from '../../../hooks/useDeviceInfo';
import { messages } from '../FilePreviewer';

export const VideoPreviewer = ({
  src,
  downloadEnabled = false,
}: {
  src: string;
  downloadEnabled?: boolean;
}) => {
  const { formatMessage } = useIntl();

  const isMobile = useDeviceInfo().deviceType === 'mobile';
  return (
    <ReactPlayer
      controls
      url={src}
      width={isMobile ? '100%' : '90%'}
      height={isMobile ? '100%' : '90%'}
    >
      {formatMessage(messages.videoNotSupported, {
        downloadEnabled,
      })}
    </ReactPlayer>
  );
};
