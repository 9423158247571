import type { Primitive } from 'type-fest';

/**
 * Sometimes we need to derive an enum value from a string value.
 * This function will return the enum value if it exists, otherwise it will return undefined.
 */
export function deriveEnumForValue<T extends Record<string, Primitive>>(
  value: string | null | undefined,
  enumObject: T
): T[keyof T] | undefined {
  if (!value) {
    return undefined;
  }

  const enumValues = Object.values(enumObject);

  if (enumValues.includes(value)) {
    return value as T[keyof T];
  }

  return undefined;
}

/**
 * Typescript type guard to check if a value is truthy & let typescript know that the value is truthy so that it can be accessed without further checks
 * @param value  The value to check for truthiness
 * @returns true if the value is truthy, false otherwise
 */
export function isTruthy<T>(
  value: T
): value is NonNullable<T> & (object | true | number) {
  return Boolean(value);
}
