import {
  BriefcaseIcon,
  PencilSquareIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import type { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { IconButton } from '../..';
import { TextStyle } from '../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../DesignSystem/Feedback/Tooltip';
import { downloadIcon, LeaderBoardLock } from '../assets/icons';

const messages = defineMessages({
  title: {
    defaultMessage: 'Leaderboard',
    id: '6it6mU',
  },
  subTitle: {
    defaultMessage: 'Powered by DoraAI',
    id: 'MCVyLQ',
  },
  topRecipients: {
    defaultMessage: 'Top 10 Recipients',
    id: 'Chefhq',
  },
  topGivers: {
    defaultMessage: 'Top 10 Givers',
    id: 'qE4pvs',
  },
  topManagers: {
    defaultMessage: 'Top 10 Managers',
    id: 'DgOnXy',
  },
  visibility: {
    defaultMessage: 'Visible only to admins',
    id: 'jkRAfm',
  },
  downloadData: {
    defaultMessage: 'Download data',
    id: 'u1GExT',
  },
});

function Root({ children }: PropsWithChildren) {
  return (
    <section className="flex flex-col gap-6 rounded-2xl bg-gray-1 p-4 shadow-feed-post">
      {children}
    </section>
  );
}

function Button({
  className,
  ...rest
}: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      className={twMerge(
        'flex items-center gap-4 rounded-[20px] bg-gray-3 px-5 py-3 text-base font-medium text-gray-10 [&>svg]:text-gray-9',
        className
      )}
      {...rest}
    />
  );
}

function ButtonGroup({ children }: PropsWithChildren) {
  return <div className="flex flex-col gap-4">{children}</div>;
}

function TopRecipients(
  props: Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>
) {
  const { formatMessage } = useIntl();

  return (
    <Button {...props}>
      <UsersIcon className="h-8 w-8" />
      {formatMessage(messages.topRecipients)}
    </Button>
  );
}

function TopGivers(
  props: Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>
) {
  const { formatMessage } = useIntl();

  return (
    <Button {...props}>
      <PencilSquareIcon className="h-8 w-8" />
      {formatMessage(messages.topGivers)}
    </Button>
  );
}

function TopManagers(
  props: Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>
) {
  const { formatMessage } = useIntl();

  return (
    <Button {...props}>
      <BriefcaseIcon className="h-8 w-8" />
      {formatMessage(messages.topManagers)}
    </Button>
  );
}

function Title({ downloadData }: { downloadData: () => void }) {
  const { formatMessage } = useIntl();

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="flex gap-2">
            <TextStyle variant="xl-medium">
              {formatMessage(messages.title)}
            </TextStyle>
            <Tooltip tooltipText={formatMessage(messages.visibility)}>
              <img
                className="h-4 w-4 self-center"
                src={LeaderBoardLock}
                alt=""
              />
            </Tooltip>
          </div>

          <Tooltip tooltipText={formatMessage(messages.downloadData)}>
            <IconButton
              className="h-6 w-6 self-center p-1"
              variation="secondaryLite"
              onClick={downloadData}
            >
              <img className="h-4 w-4" src={downloadIcon} alt="" />
            </IconButton>
          </Tooltip>
        </div>
        <TextStyle as="span" subdued variant="sm-regular">
          {formatMessage(messages.subTitle)}
        </TextStyle>
      </div>
    </div>
  );
}

export const LeaderBoard = {
  Button,
  ButtonGroup,
  Root,
  Title,
  TopGivers,
  TopManagers,
  TopRecipients,
};
