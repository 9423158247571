import type { Channel } from 'pusher-js';
import { useContext, useEffect, useState } from 'react';

import type { ChannelsContextValue } from './ChannelsProvider';
import { ChannelsContext } from './ChannelsProvider';

export function usePusherChannel(channelName: string) {
  const { subscribe, unsubscribe } =
    useContext<ChannelsContextValue>(ChannelsContext);

  const [channel, setChannel] = useState<Channel>();

  useEffect(() => {
    if (!channelName || !subscribe || !unsubscribe) {
      return;
    }

    const _channel = subscribe(channelName);
    setChannel(_channel);

    return () => unsubscribe(channelName);
  }, [channelName, subscribe, unsubscribe]);

  return channel;
}
