import {
  queryOptions,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query';

import { APIEndpoints, assemblyAPI, type GetDraftsResponse } from '../..';

export const fetchRecognitionDraftKey = ['getDraft', 'RECOGNITION'];

const getDraftQueryOptions = (
  options?: Partial<UseSuspenseQueryOptions<GetDraftsResponse>>
) => {
  return queryOptions({
    queryKey: fetchRecognitionDraftKey,
    queryFn: async () => {
      const response = await assemblyAPI.post<GetDraftsResponse>(
        APIEndpoints.findDraft,
        {
          entityType: 'RECOGNITION',
        }
      );
      return response.data;
    },
    refetchOnWindowFocus: 'always',
    refetchOnMount: false,
    ...options,
  });
};

export function useFetchRecognitionDraft(
  options?: Partial<UseSuspenseQueryOptions<GetDraftsResponse>>
) {
  return useSuspenseQuery(getDraftQueryOptions(options));
}
