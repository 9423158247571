import {
  GlobalFilterOption,
  MemberStatus,
  type Recipients,
  useCurrency,
  useRecipientsMembers,
} from '@assembly-web/services';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import {
  type ChangeEvent,
  type PropsWithChildren,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useRefContainer } from '../../context/RefContext';
import { Banner } from '../../DesignSystem/Feedback/Banner';
import { TextField } from '../../DesignSystem/Inputs/TextField';
import { Popover } from '../Shared/Popover';
import { ComboboxPlugin } from './Editors/base/plugins/ComboboxPlugin';
import { LoadMore } from './LoadMore';
import { QuickParticipation } from './QuickParticipation/QuickParticipation';

type DepartmentData = {
  id: string;
  name: string;
};

type DepartmentListProps = PropsWithChildren<DepartmentData>;

const messages = defineMessages({
  search: {
    defaultMessage: 'Search',
    id: 'xmcVZ0',
  },
  cannotReceivePoints: {
    defaultMessage: 'These people won’t receive {name}',
    id: 'GadCFE',
  },
});

function ListItem({ children }: PropsWithChildren) {
  return <div role="listitem">{children}</div>;
}

function Item({
  member,
  italic,
}: {
  member: Recipients['individuals'][number];
  italic?: boolean;
}) {
  return (
    <ListItem>
      <QuickParticipation.ItemWrapper className="hover:bg-gray-3">
        <QuickParticipation.MemberItem
          {...{
            type: 'member',
            id: member.memberID,
            isSelected: false,
            memberId: member.memberID,
            name: member.name,
            image: member.image,
            memberState: member.memberState,
            italic,
          }}
        />
      </QuickParticipation.ItemWrapper>
    </ListItem>
  );
}

function PeopleList({ name, id }: DepartmentData) {
  const { formatMessage } = useIntl();

  const totalMembersCountSet = useRef(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [totalMembersCount, setTotalMembersCount] = useState(0);
  const differedSearchTerm = useDeferredValue(searchTerm);

  const { pluralName } = useCurrency();

  const {
    hasNextPageInMembersList,
    fetchNextPageInMembersList,
    isFetchingNextPageInMembersList,
    membersQueryStatus,
    membersQueryFetchStatus,
    members,
    totalMembers,
  } = useRecipientsMembers({
    searchTerm: differedSearchTerm,
    ...(id === 'everyone'
      ? {
          customSort: {
            profileStatus: true,
          },
        }
      : {
          secondaryFilters: {
            department: [name],
          },
          filter: GlobalFilterOption.Department,
        }),
  });

  const isLoading =
    membersQueryStatus === 'pending' && membersQueryFetchStatus === 'fetching';

  const groupedMembers = useMemo(
    () =>
      members.reduce(
        (acc, member) => {
          if (
            member.status === MemberStatus.Receiver ||
            member.status === MemberStatus.Normal
          ) {
            acc.canReceivePointsMembers.push(member);
          } else {
            acc.cannotReceivePointsMembers.push(member);
          }

          return acc;
        },
        {
          canReceivePointsMembers: [] as Recipients['individuals'],
          cannotReceivePointsMembers: [] as Recipients['individuals'],
        }
      ),
    [members]
  );

  useEffect(() => {
    if (totalMembersCountSet.current || !totalMembers) {
      return;
    }
    setTotalMembersCount(totalMembers);
    totalMembersCountSet.current = true;
  }, [totalMembers]);

  return (
    <div className="flex max-h-[380px] w-80 flex-col gap-2 overflow-hidden pl-4 pt-4">
      {Boolean(totalMembersCount > 20) && (
        <TextField
          type="text"
          className="pr-4"
          connectedLeft={
            <MagnifyingGlassIcon className="pointer-events-none h-4 w-4 text-gray-9" />
          }
          value={searchTerm}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchTerm(e.target.value);
          }}
          placeholder={formatMessage(messages.search)}
          aria-label={formatMessage(messages.search)}
        />
      )}
      {isLoading ? (
        <ComboboxPlugin.Loader className="pb-4 pr-4" />
      ) : (
        <div
          role="list"
          className="relative flex flex-col gap-1 overflow-auto pb-4 pr-4"
        >
          {members.length === 0 && (
            <ListItem>
              <ComboboxPlugin.NoResults />
            </ListItem>
          )}
          {groupedMembers.cannotReceivePointsMembers.length > 0 && (
            <>
              <Banner className="mb-1" status="alert">
                {formatMessage(messages.cannotReceivePoints, {
                  name: pluralName,
                })}
              </Banner>
              {groupedMembers.cannotReceivePointsMembers.map((member) => (
                <Item key={member.memberId} member={member} italic />
              ))}
              {groupedMembers.canReceivePointsMembers.length > 0 && (
                <hr className="border-b border-gray-5" />
              )}
            </>
          )}
          {groupedMembers.canReceivePointsMembers.map((member) => (
            <Item key={member.memberId} member={member} />
          ))}
          {Boolean(isFetchingNextPageInMembersList) && (
            <ComboboxPlugin.Loader role="listitem" />
          )}
          {Boolean(hasNextPageInMembersList) && (
            <LoadMore
              aria-hidden
              onNextPageScrolled={fetchNextPageInMembersList}
            />
          )}
        </div>
      )}
    </div>
  );
}

export function DepartmentList({ id, name, children }: DepartmentListProps) {
  const [open, setOpen] = useState(false);

  const containerRef = useRefContainer();

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      trigger={children}
      collisionBoundary={containerRef ?? undefined}
      onTriggerButtonClick={() => setOpen((prev) => !prev)}
      contentClassName="p-0"
      className="focus:ring-0"
    >
      <PeopleList id={id} name={name} />
    </Popover>
  );
}
