import type { GifRatingsProps } from '@assembly-web/services';
import type { IGif } from '@giphy/js-types';
import { GifIcon } from '@heroicons/react/24/outline';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useState } from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { Tooltip } from '../../../../../DesignSystem/Feedback/Tooltip';
import { Popover } from '../../../../Shared/Popover';
import { GiphySelect } from '../../../GiphySelect/GiphySelect';
import { INSERT_IMAGE_COMMAND } from '../plugins/ImagePlugin';

const label = defineMessage({
  defaultMessage: 'GIF',
  id: 'DSE50c',
});

type GIFButtonProps = {
  disabled?: boolean;
  gifRating: GifRatingsProps;
  giphyAPIKey: string;
};

export function GIFButton({
  disabled,
  gifRating,
  giphyAPIKey,
}: GIFButtonProps) {
  const { formatMessage } = useIntl();

  const [editor] = useLexicalComposerContext();

  const [showGIFPicker, setShowGIFPicker] = useState(false);

  const handleOnGifClick = (gif: IGif) => {
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
      altText: gif.title || gif.id.toString(),
      src: gif.images.fixed_height.url,
      height: gif.images.fixed_height.height,
      width: gif.images.fixed_height.width,
      maxWidth: 300,
    });
    setShowGIFPicker(false);
  };

  return (
    <Popover
      disabled={disabled}
      aria-label={formatMessage(label)}
      onTriggerButtonClick={() => setShowGIFPicker((prov) => !prov)}
      trigger={
        <Tooltip tooltipText={formatMessage(label)}>
          <div
            role="presentation"
            className="flex h-10 w-10 items-center justify-center rounded-lg hover:bg-gray-3"
          >
            <GifIcon className="h-6 w-6 text-gray-8" />
          </div>
        </Tooltip>
      }
      open={showGIFPicker}
      onOpenChange={setShowGIFPicker}
    >
      <GiphySelect
        onGifClick={handleOnGifClick}
        rating={gifRating}
        apiKey={giphyAPIKey}
      />
    </Popover>
  );
}
