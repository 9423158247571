import { GifRatingsProps, useDebounce } from '@assembly-web/services';
import type { IGif } from '@giphy/js-types';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextField } from '../../../DesignSystem/Inputs/TextField';
import { GiphyList } from './GiphyList';

const messages = defineMessages({
  poweredByGiphy: {
    defaultMessage: 'Powered by Giphy',
    id: 'bHuQSt',
  },
  selectGifs: {
    defaultMessage: 'Select GIFs',
    id: 'VGbfmW',
  },
});

export type GiphySelectProps = {
  onGifClick: (gif: IGif) => void;
  rating: GifRatingsProps;
  classes?: string;
  apiKey: string;
};

export function GiphySelect({
  onGifClick,
  rating = GifRatingsProps.PG,
  classes,
  apiKey,
}: GiphySelectProps) {
  const { formatMessage } = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchValue = useDebounce(searchTerm, 500);

  const handleGifClick = (gif: IGif, event: SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
    onGifClick(gif);
  };

  return (
    <div
      data-chromatic="ignore"
      className={twMerge(
        'flex h-full w-[350px] flex-col items-center justify-center gap-4 bg-gray-1',
        classes
      )}
    >
      <TextField
        placeholder="Select GIFs"
        isInvalid={false}
        invalidText={'testing'}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <GiphyList
        data-chromatic="ignore"
        onGifClick={handleGifClick}
        searchTerm={debouncedSearchValue}
        rating={rating}
        apiKey={apiKey}
        classes="flex-1"
      />
      <p className="self-start text-sm text-gray-8">
        {formatMessage(messages.poweredByGiphy)}
      </p>
    </div>
  );
}
