export enum SplitNames {
  DoraUI = 'DORA_UI',
  AddMergeApps = 'ADD_MERGE_APPS',
  EnableUserActivitiesTracking = 'ENABLE_USER_ACTIVITIES_TRACKING',
  DisableRecentlyViewedDropdownOption = 'V3_DISABLE_RECENTLY_VIEWED_OPTION',
  FlowSummaries = 'FLOW_SUMMARIES_V0',
  AskDoraPopout = 'ASK_DORA_POPOUT',
  MultiPopoutDrawer = 'MULTI_POPOUT_DRAWER',
  GoogleTranslate = 'GOOGLE_TRANSLATE',
  GoogleRecaptcha = 'RECAPTCHA',
  DisableRewards = 'DISABLE_TANGO_REDEMPTION',
  AllowAssemblyCreation = 'ALLOW_ASSEMBLY_CREATION',
  RewardsV3 = 'REWARDS_V3',

  CollectionApiPaginationChanges = 'COLLECTION_API_PAGINATION_CONVERSION',

  CustomizeWorkspaceTheme = 'CUSTOMIZE_WORKSPACE_THEME',
  EnableAssemblyMagicLink = 'ASSEMBLY_MAGIC_LINK',
  DoraReportingInsightsV0 = 'DORA_REPORTING_INSIGHTS_V0',
  DoraReportingInsightsManagers = 'DORA_REPORTING_INSIGHTS_MANAGERS',
  DoraAiRecognitionWriter = 'DORA_AI_RECOGNITION_WRITER',
  AmazonRewards = 'AMAZON_REWARDS',
  SwitchflyIntegration = 'SWITCHFLY_INTEGRATION',
  ActionBarV3 = 'ACTION_BAR_V3',
  FlowCreation = 'FLOW_CREATION',
  FlowCreationOverride = 'FLOW_CREATION_OVERRIDE',
  FlowResponse = 'DORA_ENABLE_FLOW_RESPONSE_REPORTS',
  FlowDistribution = 'FLOW_DISTRIBUTION',
  FlowSimplifiedSelectionCriteria = 'FLOW_SIMPLIFIED_SELECTION_CRITERIA',
  ShowFlowsInV3Nav = 'SHOW_FLOWS_IN_V3_NAV',
  DisableNavPinning = 'DISABLE_NAV_PINNING',
  SentrySessionReplay = 'SENTRY_SESSION_REPLAY',
  MSTeamsAppSubmission = 'MS_TEAMS_TESTING',
  MSTeamsNoWorkspaceInfo = 'MS_TEAMS_NO_WORKSPACE_INFO',
  G2Review = 'G2_REVIEW',
  FilePreviewerV3 = 'V3_FILE_PREVIEWER',
  Chat = 'V3_CHAT',
  AccessCollectionsPage = 'ACCESS_COLLECTIONS_PAGE',
  ActivityUIRevamp = 'ACTIVITY_UI_REVAMP',
  EditorMilestones = 'EDITOR_MILESTONES',
  ReplyWithPostPreview = 'REPLY_WITH_POST_PREVIEW',
  RecognitionFeedV3 = 'RECOGNITION_FEED_V3',
  RecognitionParticipationV3 = 'RECOGNITION_PARTICIPATION_V3',
  NewAdminExperience = 'NEW_ADMIN_EXPERIENCE',
  ExcludePostsWithReplies = 'EXCLUDE_POSTS_WITH_REPLIES',
  AdminAllowance = 'ALLOWANCE_ADMIN',
  AdminDeposit = 'DEPOSITS_ADMIN',
}
