import { MemberState } from '@assembly-web/services';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import {
  TextStyle,
  type TextVariant,
} from '../../DesignSystem/Feedback/TextStyle';
import type { BoostOptions } from '../Web/Editors/RepliesEditor/RepliesEditor';
import type { ProfileCardContentProps } from '../Web/ProfileCard/ProfileCard';
import { ProfileViewer } from '../Web/ProfileViewer/ProfileViewer';

export type MemberDetailsForViewProfile = ProfileCardContentProps & {
  memberState: MemberState;
};

export type AuthorButtonProps<T> = {
  label?: string;
  className?: string;
  onClick: () => void;
  respondent: T | null;
  textVariant?: TextVariant;
  boostOptions?: BoostOptions;
};

const messages = defineMessages({
  anonymous: {
    defaultMessage: 'Anonymous',
    id: 'LXxsbk',
  },
  deactivatedUser: {
    defaultMessage: '(deactivated)',
    id: 'osOx6w',
  },
});

export function AuthorButton({
  label,
  onClick,
  className,
  respondent,
  textVariant,
  boostOptions,
}: AuthorButtonProps<MemberDetailsForViewProfile>) {
  const { formatMessage } = useIntl();

  const isUserAnonymous = respondent === null || respondent.memberID === '';
  const isUserDeactivated = respondent?.memberState === MemberState.Deactivated;
  const isUserActive =
    respondent !== null &&
    respondent.memberID !== '' &&
    respondent.memberState !== MemberState.Deactivated;

  const buttonClassName = twMerge(
    'mr-1 flex',
    isUserActive && 'text-primary-6 hover:text-primary-5',
    isUserAnonymous && 'cursor-default',
    isUserDeactivated && 'text-gray-6',
    className
  );

  const textClassName = twJoin(
    'line-clamp-1 text-left',
    isUserDeactivated && 'text-gray-8',
    className
  );

  const authorName =
    respondent === null
      ? formatMessage(messages.anonymous)
      : `${respondent.firstName} ${respondent.lastName}`;

  const authorElement = (
    <button
      onClick={onClick}
      data-testid="author"
      className={buttonClassName}
      disabled={isUserDeactivated}
    >
      <TextStyle variant={textVariant ?? 'sm-medium'} className={textClassName}>
        {label ?? authorName}
      </TextStyle>
      {Boolean(isUserDeactivated) && (
        <TextStyle variant="sm-regular" className="ml-1 text-gray-8">
          {formatMessage(messages.deactivatedUser)}
        </TextStyle>
      )}
    </button>
  );

  if (isUserAnonymous) {
    return authorElement;
  }

  return (
    <ProfileViewer
      userDetails={respondent}
      boostOptions={boostOptions}
      onViewProfileClick={onClick}
    >
      {authorElement}
    </ProfileViewer>
  );
}
