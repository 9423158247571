import type { Icon, MetadataProps } from './domain';
import type { MemberDetails, MemberState } from './flowTypes';
import type { ReactionTypes } from './reactions';
import type { BoostDetails } from './searchIndex';

type MentionedBy = {
  name: string;
  memberId?: string;
};

type Mention = {
  at: Date;
  others?: number;
  by: MentionedBy;
};

export type Tasks = {
  taskId: string;
  title: { type: string; value: string }[];
};

export type Person = {
  name: string;
  memberId: string;
  state: MemberState;
};

type Message = {
  text: string;
  persons: Person[];
  tasks?: Tasks[];
};

enum ElementType {
  Text = 'text',
}

export type TaskState = 'COMPLETED' | 'ARCHIVED' | 'ACTIVE' | 'DELETED';

export enum ActivityCardTypeEnum {
  PostMentions = 'POST_MENTIONS',
  FlowActivity = 'FLOW_ACTIVITY',
  TaskActivities = 'TASK_ACTIVITIES',
  OwnerPostReply = 'OWNER_POST_REPLY',
  ResponseMentions = 'RESPONSE_MENTIONS',
  PostThreadReplies = 'POST_THREAD_REPLIES',
  FlowAccessRequest = 'FLOW_ACCESS_REQUEST',
  OwnerResponseReply = 'OWNER_RESPONSE_REPLY',
  RecognitionReminders = 'RECOGNITION_REMINDERS',
  DirectReportActivity = 'DIRECT_REPORT_ACTIVITY',
  ResponseThreadReplies = 'RESPONSE_THREAD_REPLIES',
  FlowAccessGranted = 'FLOW_ACCESS_REQUEST_GRANTED',
  BirthdayCelebrationActivity = 'BIRTHDAY_CELEBRATION_ACTIVITY',
  AnniversaryCelebrationActivity = 'ANNIVERSARY_CELEBRATION_ACTIVITY',
  RecognitionDirectReportActivity = 'RECOGNITION_DIRECT_REPORT_ACTIVITY',
  BirthdayCelebrationDirectReport = 'BIRTHDAY_CELEBRATION_DIRECT_REPORT_ACTIVITY',
  AnniversaryCelebrationDirectReport = 'ANNIVERSARY_CELEBRATION_DIRECT_REPORT_ACTIVITY',
  ChallengeLaunchActivity = 'CHALLENGE_LAUNCH',
  OwnerChallengeReply = 'OWNER_CHALLENGE_REPLY',
  ChallengeThreadReplies = 'CHALLENGE_THREAD_REPLIES',
  ChallengeClaimAction = 'CHALLENGE_CLAIM_ACTION',
  ChallengeClaim = 'CHALLENGE_CLAIM',
  ChallengeReminder = 'CHALLENGE_REMINDER',
}

export enum FlowAccessRequestStatus {
  Viewer = 'VIEWER',
  Participant = 'PARTICIPANT',
  ParticipantAndViewer = 'PARTICIPANT_AND_VIEWER',
}

type TextElement = {
  type: ElementType;
  value: string;
};

export type FlowAccessRequestActivityType = {
  type: ActivityCardTypeEnum.FlowAccessRequest;
  entity: {
    flowId: string;
    name: string;
    icon: Icon;
    shareRequestId: string;
    for: FlowAccessRequestStatus;
    invitedBy: MentionedBy;
    invitees: {
      name: string;
      others: number;
      memberId: string;
    };
  };
};

export type RecognitionRemindersActivityType = {
  type: ActivityCardTypeEnum.RecognitionReminders;
};

export type TaskActivityType = {
  type: ActivityCardTypeEnum.TaskActivities;
  entity: {
    taskId: string;
    state: TaskState;
    title: TextElement[];
    transactionId: string;
    assignedBy?: MentionedBy;
    assignedTo?: MentionedBy;
    description: TextElement[];
    stateUpdatedBy?: MentionedBy;
  };
};

export type FlowAccessGrantedActivityType = {
  type: ActivityCardTypeEnum.FlowAccessGranted;
  entity: {
    icon: Icon;
    name: string;
    flowId: string;
    ownerId: string;
    invitedBy: MentionedBy;
    for: FlowAccessRequestStatus;
  };
};

export type AnniversaryCelebrationActivityType = {
  type: ActivityCardTypeEnum.AnniversaryCelebrationActivity;
  entity: {
    postId: string;
    person: string;
    others: number;
    message: string;
    memberId: string;
    isMuted: boolean;
  };
};

export type BirthdayCelebrationActivityType = {
  type: ActivityCardTypeEnum.BirthdayCelebrationActivity;
  entity: {
    postId: string;
    person: string;
    others: number;
    message: string;
    memberId: string;
    isMuted: boolean;
  };
};

export type NotificationReactionResponse = {
  count: number;
  type: ReactionTypes;
  value: string;
  displayName: string;
  name: string;
  members: (
    | {
        memberID: string;
        name: string;
        memberId: string;
      }
    | MemberDetails
  )[];
};

export type ResponseThreadReplyActivityType = {
  type: ActivityCardTypeEnum.ResponseThreadReplies;
  entity: {
    flowId: string;
    name: string;
    icon: Icon;
    lastReply: {
      version?: number;
      editedAt?: string;
      text: string;
      gifUrl?: string;
      commentId: string;
      persons: Person[];
      pointsEach?: number;
      from: {
        memberId: string;
        name: string;
        image: string;
        state: MemberState;
        department?: string;
        jobTitle?: string;
        pronouns?: string;
        email?: string;
      };
      reactions: NotificationReactionResponse[];
    };
    mention?: Mention;
    reply?: {
      at: Date;
      others: number;
      by: MentionedBy;
    };
    response: {
      name: string;
      memberId: string;
      responseId: string;
      image: string;
      state: MemberState;
      createdAt: string;
      department?: string;
      jobTitle?: string;
      pronouns?: string;
      email?: string;
    };
    replyCount: number;
    isMuted: boolean;
    hideReplies?: boolean;
    hideReactions?: boolean;
    showTurnOffSettings?: boolean;
  };
  updatedAt: string;
};

export type PostThreadReplyActivityType = {
  type: ActivityCardTypeEnum.PostThreadReplies;
  entity: {
    flowId: string;
    name: string;
    post: {
      postId: string;
      name: string;
      memberId: string;
      image: string;
      state: MemberState;
      createdAt: string;
      department?: string;
      jobTitle?: string;
      pronouns?: string;
      email?: string;
    };
    lastReply: {
      version?: number;
      text: string;
      editedAt?: string;
      commentId: string;
      gifUrl?: string;
      persons: Person[];
      pointsEach?: number;
      from: {
        memberId: string;
        name: string;
        image: string;
        state: MemberState;
        department?: string;
        jobTitle?: string;
        pronouns?: string;
        email?: string;
      };
      reactions: NotificationReactionResponse[];
    };
    reply?: {
      at: Date;
      others: number;
      by: MentionedBy;
    };
    replyCount: number;
    to: MentionedBy;
    mention?: Mention;
    isMuted: boolean;
    hideReplies?: boolean;
    hideReactions?: boolean;
    showTurnOffSettings?: boolean;
  };
  updatedAt: string;
};

export type OwnerResponseReplyActivityType = {
  type: ActivityCardTypeEnum.OwnerResponseReply;
  entity: {
    flowId: string;
    name: string;
    icon: Icon;
    lastReply: {
      version?: number;
      commentId: string;
      editedAt?: string;
      pointsEach?: number;
      from: {
        memberId: string;
        name: string;
        image: string;
        state: MemberState;
        department?: string;
        jobTitle?: string;
        pronouns?: string;
        email?: string;
      };
      persons: Person[];
      text: string;
      gifUrl?: string;
      reactions: NotificationReactionResponse[];
    };
    reply: {
      at: Date;
      by: MentionedBy;
      others: number;
    };
    response: {
      name: string;
      memberId: string;
      responseId: string;
      image: string;
      state: MemberState;
      createdAt: string;
      department?: string;
      jobTitle?: string;
      pronouns?: string;
      email?: string;
    };
    replyCount: number;
    isMuted: boolean;
    hideReplies?: boolean;
    hideReactions?: boolean;
    showTurnOffSettings?: boolean;
  };
  updatedAt: string;
};

export type ChallengeThreadReplyActivityType = {
  type: ActivityCardTypeEnum.ChallengeThreadReplies;
  entity: {
    name: string;
    challenge: {
      challengeId: string;
      name: string;
      memberId: string;
      image: string;
      state: MemberState;
      createdAt: string;
      department?: string;
      jobTitle?: string;
      pronouns?: string;
      email?: string;
    };
    lastReply: {
      version?: number;
      text: string;
      editedAt?: string;
      commentId: string;
      gifUrl?: string;
      persons: Person[];
      pointsEach?: number;
      boost: BoostDetails[];
      from: {
        memberId: string;
        name: string;
        image: string;
        state: MemberState;
        department?: string;
        jobTitle?: string;
        pronouns?: string;
        email?: string;
      };
      reactions: NotificationReactionResponse[];
    };
    reply?: {
      at: Date;
      others: number;
      by: MentionedBy;
    };
    replyCount: number;
    to: MentionedBy;
    mention?: Mention;
    isMuted: boolean;
    hideReplies?: boolean;
    hideReactions?: boolean;
    showTurnOffSettings?: boolean;
  };
  updatedAt: string;
};

export type OwnerChallengeReplyActivityType = {
  type: ActivityCardTypeEnum.OwnerChallengeReply;
  entity: {
    name: string;
    challenge: {
      challengeId: string;
      name: string;
      memberId: string;
      image: string;
      state: MemberState;
      createdAt: string;
      department?: string;
      jobTitle?: string;
      pronouns?: string;
      email?: string;
    };
    lastReply: {
      editedAt?: string;
      version?: number;
      text: string;
      commentId: string;
      gifUrl?: string;
      persons: Person[];
      pointsEach?: number;
      boost: BoostDetails[];
      from: {
        memberId: string;
        name: string;
        image: string;
        state: MemberState;
        department?: string;
        jobTitle?: string;
        pronouns?: string;
        email?: string;
      };
      reactions: NotificationReactionResponse[];
    };
    reply: {
      at: Date;
      by: MentionedBy;
      others: number;
    };
    replyCount: number;
    to: MentionedBy;
    isMuted: boolean;

    hideReplies?: boolean;
    hideReactions?: boolean;
    showTurnOffSettings?: boolean;
  };
  updatedAt: string;
};

export type ChallengeReminderActivityType = {
  type: ActivityCardTypeEnum.ChallengeReminder;
  state: string;
  entity: {
    challengeId: string;
    title: string;
  };
  updatedAt: string;
};

export type OwnerPostReplyActivityType = {
  type: ActivityCardTypeEnum.OwnerPostReply;
  entity: {
    post: {
      postId: string;
      name: string;
      memberId: string;
      image: string;
      state: MemberState;
      createdAt: string;
      department?: string;
      jobTitle?: string;
      pronouns?: string;
      email?: string;
    };
    lastReply: {
      editedAt?: string;
      version?: number;
      text: string;
      commentId: string;
      gifUrl?: string;
      persons: Person[];
      pointsEach?: number;
      from: {
        memberId: string;
        name: string;
        image: string;
        state: MemberState;
        department?: string;
        jobTitle?: string;
        pronouns?: string;
        email?: string;
      };
      reactions: NotificationReactionResponse[];
    };
    reply: {
      at: Date;
      by: MentionedBy;
      others: number;
    };
    replyCount: number;
    to: MentionedBy;
    isMuted: boolean;

    hideReplies?: boolean;
    hideReactions?: boolean;
    showTurnOffSettings?: boolean;
  };
  updatedAt: string;
};

export type FlowEntityActivityType = {
  type: ActivityCardTypeEnum.FlowActivity;
  entity: {
    icon: Icon;
    name: string;
    endAt?: string;
    flowId: string;
    ownerId: string;
    canPost: boolean;
    countUnreadPosts?: number;
    responded?: { by: MentionedBy; others: number };
  };
};

export type ResponseMentionsActivityType = {
  type: ActivityCardTypeEnum.ResponseMentions;
  entity: {
    flowId: string;
    name: string;
    icon: Icon;
    responseId: string;
    mention: Mention;
    message: Message;
    isMuted: boolean;
    hideReplies?: boolean;
    hideReactions?: boolean;
    showTurnOffSettings?: boolean;
  };
};

export type PostMentionsActivityType = {
  type: ActivityCardTypeEnum.PostMentions;
  entity: {
    postId: string;
    name: string;
    mention: Mention;
    message: Message;
    isMuted: boolean;
  };
};

export type DirectReportActivityType = {
  type: ActivityCardTypeEnum.DirectReportActivity;
  entity: {
    canPost: boolean;
    flowId: string;
    icon: Icon;
    name: string;
    ownerId: string;
    countUnreadPosts?: number;
    responded?: { by: MentionedBy; others: number };
  };
};

export type DirectReportBirthdayCelebrationActivityType = {
  type: ActivityCardTypeEnum.BirthdayCelebrationDirectReport;
  entity: {
    postId: string;
    person: Person;
    message: string;
  };
};

export type DirectReportAnniversaryCelebrationActivityType = {
  type: ActivityCardTypeEnum.AnniversaryCelebrationDirectReport;
  entity: {
    postId: string;
    person: Person;
    message: string;
  };
};

export type DirectReportRecognizedActivityType = {
  type: ActivityCardTypeEnum.RecognitionDirectReportActivity;
  entity: {
    postId: string;
    person: Person;
    others: number;
    message: string;
    mention: Mention;
  };
};

export type ChallengeLaunchActivityType = {
  type: ActivityCardTypeEnum.ChallengeLaunchActivity;
  entity: {
    countUnreadChallenge: number;
  };
};

type ApprovedEntity = {
  state: 'APPROVED';
  approvedBy: {
    memberId: string;
    name: string;
    state: string;
    email: string;
    jobTitle: string;
    department: string;
  };
  approvedAt: string;
};

type DeniedEntity = {
  state: 'DENIED';
  deniedBy: {
    memberId: string;
    name: string;
    state: string;
    email: string;
    jobTitle: string;
    department: string;
  };
  deniedAt: string;
};

export type ChallengeClaimActionActivityType = {
  state: string;
  updatedAt: string;
  type: ActivityCardTypeEnum.ChallengeClaimAction;
  entity: {
    claimId: string;
    claimedBy: {
      memberId: string;
      name: string;
      state: string;
      email: string;
      jobTitle: string;
      department: string;
    };
    challenge: {
      challengeId: string;
      title: string;
      createdAt: string;
    };
    points: number;
    createdAt: string;
    updatedAt: string;
  } & (ApprovedEntity | DeniedEntity);
};

export type ChallengeClaimActivityType = {
  state: string;
  updatedAt: string;
  type: ActivityCardTypeEnum.ChallengeClaim;
  entity: {
    others: number;
    challengeId: string;
    challengeTitle: string;
    claimedBy: string;
    claimedByName: string;
  };
};

export type ActivityEntity =
  | TaskActivityType
  | FlowEntityActivityType
  | PostMentionsActivityType
  | DirectReportActivityType
  | OwnerPostReplyActivityType
  | PostThreadReplyActivityType
  | ResponseMentionsActivityType
  | FlowAccessGrantedActivityType
  | FlowAccessRequestActivityType
  | OwnerResponseReplyActivityType
  | ResponseThreadReplyActivityType
  | BirthdayCelebrationActivityType
  | RecognitionRemindersActivityType
  | DirectReportRecognizedActivityType
  | AnniversaryCelebrationActivityType
  | DirectReportBirthdayCelebrationActivityType
  | DirectReportAnniversaryCelebrationActivityType
  | ChallengeLaunchActivityType
  | OwnerChallengeReplyActivityType
  | ChallengeThreadReplyActivityType
  | ChallengeClaimActionActivityType
  | ChallengeClaimActivityType
  | ChallengeReminderActivityType;

export type ActivityCardType = {
  state: string;
  cardId: string;
  updatedAt: Date;
} & ActivityEntity;

export type ImportantActivitiesApiResponse = {
  data: {
    total: string;
    metadata?: MetadataProps;
    data: ActivityCardType[];
  };
};

export type ImportantActivitiesByIdApiResponse = ActivityCardType;
