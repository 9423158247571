import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import type { TypeaheadMenuPluginProps } from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  BLUR_COMMAND,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_LOW,
  FOCUS_COMMAND,
  type LexicalEditor,
  type RangeSelection,
} from 'lexical';
import { useCallback, useEffect, useState } from 'react';

import type { MenuOption, MenuResolution } from '../components/ComboboxMenu';
import { ComboboxMenu, useMenuAnchorRef } from '../components/ComboboxMenu';

function getTextUpToAnchor(selection: RangeSelection): string | null {
  const anchor = selection.anchor;
  if (anchor.type !== 'text') {
    return null;
  }
  const anchorNode = anchor.getNode();
  if (!anchorNode.isSimpleText()) {
    return null;
  }
  const anchorOffset = anchor.offset;
  return anchorNode.getTextContent().slice(0, anchorOffset);
}

function getQueryTextForSearch(editor: LexicalEditor): string | null {
  let text = null;
  editor.getEditorState().read(() => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
      return;
    }
    text = getTextUpToAnchor(selection);
  });
  return text;
}

export function ComboboxTypeaheadMenuPlugin<TOption extends MenuOption>({
  options,
  onQueryChange,
  onSelectOption,
  onOpen,
  onClose,
  menuRenderFn,
  triggerFn,
  anchorClassName,
  commandPriority = COMMAND_PRIORITY_LOW,
  parent,
}: TypeaheadMenuPluginProps<TOption>): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  const [menuOpen, setMenuOpen] = useState<'open' | 'close'>('close');
  const [resolution, setResolution] = useState<MenuResolution | null>({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    getRect: () => editor.getRootElement()!.getBoundingClientRect(),
  });
  const anchorElementRef = useMenuAnchorRef(
    resolution,
    useCallback((r) => setMenuOpen(r ? 'open' : 'close'), []),
    anchorClassName,
    parent
  );

  const closeTypeahead = useCallback(() => {
    setMenuOpen('close');
    if (onClose != null && resolution !== null) {
      onClose();
    }
  }, [onClose, resolution]);

  const openTypeahead = useCallback(
    (res: MenuResolution) => {
      setMenuOpen('open');
      onOpen?.(res);
    },
    [onOpen]
  );

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        editor.getEditorState().read(() => {
          const selection = $getSelection();
          const text = getQueryTextForSearch(editor);

          if (
            !$isRangeSelection(selection) ||
            !selection.isCollapsed() ||
            text === null
          ) {
            onQueryChange('');
            return;
          }
          setResolution({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            getRect: () => editor.getRootElement()!.getBoundingClientRect(),
            match: {
              leadOffset: text.startsWith(' ') ? 1 : 0,
              matchingString: text,
              replaceableString: text,
            },
          });
          onQueryChange(text);
        });
      }),
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          openTypeahead({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            getRect: () => editor.getRootElement()!.getBoundingClientRect(),
          });
          editor.focus();
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        (e) => {
          if (e.relatedTarget instanceof HTMLElement) {
            if (e.relatedTarget.closest('.mentions-menu')) {
              return false;
            }
          }
          closeTypeahead();
          return false;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [
    editor,
    triggerFn,
    onQueryChange,
    resolution,
    closeTypeahead,
    openTypeahead,
  ]);

  return resolution === null ||
    menuOpen === 'close' ||
    editor.getRootElement() === null ? null : (
    <ComboboxMenu
      close={closeTypeahead}
      resolution={resolution}
      editor={editor}
      anchorElementRef={anchorElementRef}
      options={options}
      menuRenderFn={menuRenderFn}
      shouldSplitNodeWithQuery={true}
      onSelectOption={onSelectOption}
      commandPriority={commandPriority}
    />
  );
}
