import { type FolderColor, isTruthy } from '@assembly-web/services';
import type { ChangeEvent } from 'react';
import { twJoin } from 'tailwind-merge';

import { ColorRadioOption } from './ColorRadioOption';
import { EmojiPicker } from './EmojiPicker';
import { getFolderStyles } from './utils';

const colorOptions: FolderColor[] = [
  'lightBlue',
  'skyBlue',
  'cyan',
  'limeGreen',
  'gold',
  'orange',
  'red',
  'pink',
  'purple',
  'gray',
];

export function EmojiColorPicker({
  showEmojiPicker,
  colorName,
  emoji,
  onColorChange,
  onEmojiChange,
}: {
  showEmojiPicker: boolean;
  colorName: FolderColor;
  emoji: string;
  onColorChange: (ev: ChangeEvent<HTMLInputElement>) => void;
  onEmojiChange: (emoji: string) => void;
}) {
  return (
    <div className="flex w-full flex-row gap-2">
      {isTruthy(showEmojiPicker) && (
        <EmojiPicker
          className="flex-shrink-0"
          emoji={emoji}
          onChange={onEmojiChange}
          buttonColor={getFolderStyles(colorName).background}
        />
      )}
      <div
        className={twJoin('w-full overflow-hidden', showEmojiPicker && 'px-2')}
      >
        <div className="flex h-full min-w-0 flex-1 items-center overflow-scroll">
          {colorOptions.map((colorOption) => (
            <ColorRadioOption
              checked={colorName === colorOption}
              colorName={colorOption}
              key={colorOption}
              onChange={onColorChange}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
