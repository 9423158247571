import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createStore } from 'zustand/vanilla';

import type { MobilePlatform } from '../mobile';
import type { Profile } from '../types/domain';

type UserAuthFlow = 'login' | 'join' | 'create-account';

type SAMLDetails = {
  id: string;
  text: string;
};

type UserDetails = Partial<Profile>;

type UserAuthStore = {
  isLoginViaMobileApp?: boolean;
  assemblyId?: string;
  workspaceSlug?: string;
  isEnforcedAuth?: boolean;
  userAuthFlow?: UserAuthFlow;
  samlDetails?: SAMLDetails;
  userDetails?: UserDetails;
  jwtToken?: string;
  refreshToken?: string;
  msTeamsContext?: string;
  isMobileAppV3?: boolean;
  mobilePlatform?: MobilePlatform;
  closeDrawerOnNavigateBack?: boolean;
  amazonCallbackURI?: string;
  amazonState?: string;
  applicationId?: string;
  deepLinkUrl?: string;

  /** Assembly ID of the last visited workspace */
  lastVisitedWorkspace?: string;

  setIsLoginViaMobileApp: (isLoginViaMobileApp: boolean) => void;
  setIsMobileAppV3: (isMobileAppV3: boolean) => void;
  setMobilePlatform: (mobilePlatform: MobilePlatform) => void;
  setCloseDrawerOnNavigateBack: (closeDrawerOnNavigateBack: boolean) => void;

  setJwtToken: (jwtToken: string) => void;
  setRefreshToken: (refreshToken: string) => void;
  setDeepLinkUrl: (deepLinkUrl: string) => void;

  setLastVisitedWorkspace: (lastVisitedWorkspace?: string) => void;

  setMSTeamsContext: (msTeamsContext?: string) => void;

  setSAMLDetails: (id: string, text: string) => void;
  setAmazonAuthWorkflowDetails: (
    amazonCallbackURI: string,
    amazonState: string,
    applicationId: string
  ) => void;

  updateEnforcedLoginDetails: (
    assemblyId: string,
    workspaceSlug: string,
    userAuthFlow: UserAuthFlow
  ) => void;

  updateUserAuthFlow: (flow: UserAuthFlow) => void;

  updateUserDetails: (userDetails: UserDetails) => void;

  clearSession: () => void;
  clearAmazonAuthWorkflowSession: () => void;
};

export const MobileJWTTokenQueryParamKey = 'jwtToken';
export const MobileRefreshTokenQueryParam = 'refreshToken';

export const userAuthStore = createStore<UserAuthStore>()(
  persist(
    immer((set) => ({
      setIsLoginViaMobileApp: (isLoginViaMobileApp: boolean) =>
        set((state) => {
          state.isLoginViaMobileApp = isLoginViaMobileApp;
        }),

      setIsMobileAppV3: (isMobileAppV3: boolean) =>
        set((state) => {
          state.isMobileAppV3 = isMobileAppV3;
        }),

      setMobilePlatform: (mobilePlatform: MobilePlatform) =>
        set((state) => {
          state.mobilePlatform = mobilePlatform;
        }),

      setJwtToken: (jwtToken: string) =>
        set((state) => {
          state.jwtToken = jwtToken;
        }),

      setRefreshToken: (refreshToken: string) =>
        set((state) => {
          state.refreshToken = refreshToken;
        }),

      setDeepLinkUrl: (deepLinkUrl: string) =>
        set((state) => {
          state.deepLinkUrl = deepLinkUrl;
        }),

      setCloseDrawerOnNavigateBack: (closeDrawerOnNavigateBack: boolean) =>
        set((state) => {
          state.closeDrawerOnNavigateBack = closeDrawerOnNavigateBack;
        }),

      setSAMLDetails: (id: string, text: string) =>
        set((state) => {
          state.samlDetails = {
            id,
            text,
          };
        }),

      setAmazonAuthWorkflowDetails: (
        amazonCallbackURI: string,
        amazonState: string,
        applicationId: string
      ) =>
        set((state) => {
          state.amazonCallbackURI = amazonCallbackURI;
          state.amazonState = amazonState;
          state.applicationId = applicationId;
        }),

      setMSTeamsContext: (msTeamsContext?: string) =>
        set(() => ({ msTeamsContext })),

      setLastVisitedWorkspace: (lastVisitedWorkspace?: string) =>
        set(() => ({ lastVisitedWorkspace })),

      updateUserAuthFlow: (flow: UserAuthFlow) =>
        set((state) => {
          state.userAuthFlow = flow;
        }),

      updateEnforcedLoginDetails: (
        assemblyId: string,
        workspaceSlug: string,
        userAuthFlow: UserAuthFlow
      ) =>
        set((state) => {
          state.assemblyId = assemblyId;
          state.workspaceSlug = workspaceSlug;
          state.isEnforcedAuth = true;
          state.userAuthFlow = userAuthFlow;
        }),

      updateUserDetails: (userDetails: UserDetails) =>
        set((state) => {
          state.userDetails = {
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            isFirstLogin: Boolean(userDetails.isFirstLogin),
            email: userDetails.email,
          };
        }),

      clearSession: () =>
        set((state) => {
          state.assemblyId = undefined;
          state.workspaceSlug = undefined;
          state.isEnforcedAuth = undefined;
          state.userAuthFlow = undefined;
          state.samlDetails = undefined;
          state.userDetails = undefined;
          state.jwtToken = undefined;
          state.msTeamsContext = undefined;
          state.refreshToken = undefined;
          state.amazonCallbackURI = undefined;
          state.amazonState = undefined;
          state.applicationId = undefined;
        }),

      clearAmazonAuthWorkflowSession: () =>
        set((state) => {
          state.amazonCallbackURI = undefined;
          state.amazonState = undefined;
          state.applicationId = undefined;
        }),
    })),
    {
      name: 'user-auth-store',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
