import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleLabel: {
    defaultMessage: 'Title',
    id: '9a9+ww',
  },
  titleRequiredLabel: {
    defaultMessage: 'Title is required',
    id: 'Lp3DO2',
  },
  titleTooLongLabel: {
    defaultMessage: 'Title must be 45 characters or less',
    id: 'nWEAnX',
  },
  titleHintLabel: {
    defaultMessage: '45 character limit',
    id: 'THIneq',
  },
  emptyTitle: {
    defaultMessage: 'Title cannot be empty',
    id: 'skuO5o',
  },
  placeholderLabelForTitle: {
    defaultMessage: 'e.g. Company 5K Challenge',
    id: 'ItXafU',
  },
  descriptionLabel: {
    defaultMessage: 'Description (optional)',
    id: 'bZenNC',
  },
  descriptionTooLong: {
    defaultMessage: 'Description must be 1000 characters or less',
    id: '96C90q',
  },
  placeholderLabelForDescription: {
    defaultMessage:
      'e.g. Do you live, laugh, love to run? Lets stay healthy together and run 5k in the next month!',
    id: 'lGTLtA',
  },
  descriptionHintLabel: {
    defaultMessage: '1000 character limit',
    id: 'BvOp16',
  },
  titleRequiredErrorLabel: {
    defaultMessage: 'Title is required',
    id: 'Lp3DO2',
  },
  currencyTitle: {
    defaultMessage: `How many {icon} {currency} would you like to award for completing this challenge?`,
    id: '/NIvJs',
  },
  currencyMax: {
    defaultMessage: 'Reward cannot exceed 10,000 {currencyPlural}',
    id: 'ToPL5K',
  },
  emptyCurrency: {
    defaultMessage: `{currency} cannot be empty`,
    id: 'gWgZV0',
  },
  placeHolderForCurrency: {
    defaultMessage: `e.g. 500`,
    id: 'G1L1MK',
  },
  rewardDecimalError: {
    defaultMessage: `Reward cannot be in decimal`,
    id: 'oty+fY',
  },
  individualLimitDecimalError: {
    defaultMessage: `Individual limit cannot be in decimal`,
    id: '6zIkwE',
  },
  totalLimitDecimalError: {
    defaultMessage: `Total limit cannot be in decimal`,
    id: 'Jp07Ql',
  },
  totalLimitMaxError: {
    defaultMessage: `Total limit cannot exceed 1000`,
    id: 'tNwsIe',
  },
  limitMinError: {
    defaultMessage: `Individual limit cannot be empty`,
    id: 'iIQI5L',
  },
  limitMaxError: {
    defaultMessage: `Individual limit cannot exceed 500`,
    id: 'TsIRXC',
  },
  flowTitle: {
    defaultMessage: 'In what flow would you like to post this challenge?',
    id: 'Qh3ujB',
  },
  placeholderLabelForFlow: {
    defaultMessage: 'Choose a flow',
    id: 'K3OkSO',
  },
  flowHintLabel: {
    defaultMessage:
      'Everyone who can view posts in this flow will be able to claim the challenge.',
    id: '9r+yv0',
  },
  noFlows: {
    defaultMessage: 'You have no flows to post this challenge in.',
    id: 'HOlL9x',
  },
  createFlow: {
    defaultMessage: 'Create one to start',
    id: '9ocI0I',
  },
  postInteractionSettingsLabel: {
    defaultMessage: 'Post interaction settings',
    id: 'qB/sCv',
  },
  postInteractionSettingsDescriptionLabel: {
    defaultMessage:
      'When turned off, existing replies and reactions will be hidden.',
    id: 'uQBwfk',
  },
  allowRepliesLabel: {
    defaultMessage: 'Allow replies on this post',
    id: 'J456i4',
  },
  allowReactionsLabel: {
    defaultMessage: 'Allow reactions on this post',
    id: 'lplhM5',
  },
  editLabel: {
    defaultMessage: 'Edit',
    id: 'wEQDC6',
  },
  closeLabel: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
  emailLabel: {
    defaultMessage: 'Email',
    id: 'sy+pv5',
  },
  onLaunchLabel: {
    defaultMessage: 'On launch',
    id: 'bYfB9p',
  },
  notifyOnLaunch: {
    defaultMessage: 'Notify on launch',
    id: '9e1C77',
  },
  mobileAppLabel: {
    defaultMessage: 'Mobile app',
    id: 'i/O7gT',
  },
  noneLabel: {
    defaultMessage: 'None',
    id: '450Fty',
  },
  savingLabel: {
    defaultMessage: 'Saving...',
    id: 'TiR/Hq',
  },
  cancelLabel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  settingsAndControlsLabel: {
    defaultMessage: 'Setting limits',
    id: 'XfK/zV',
  },
  distributionLabel: {
    defaultMessage: 'Share & notify',
    id: 'r1sZIM',
  },
  customizeStylingLabel: {
    defaultMessage: 'Customize styling',
    id: 'siRV7O',
  },
  detailsLabel: {
    defaultMessage: 'Details',
    id: 'Lv0zJu',
  },
  header: {
    defaultMessage: 'Challenges Center',
    id: 'n9bEvF',
  },
  cardPreviewTitle: {
    defaultMessage: 'Challenge card preview',
    id: '3DCti/',
  },
  totalClaimLimitTitle: {
    defaultMessage: 'How many total times can this challenge be claimed?',
    id: 'AfYaW3',
  },
  totalClaimLimitHintLabel: {
    defaultMessage:
      'This challenge can be claimed {limit, select, limited {a} unlimited {an} other {}} {limit} number of times until the challenge is ended',
    id: '39SNdA',
  },
  totalLimitToolTip: {
    defaultMessage:
      'This limits how many times the challenge can be claimed . For example, a limit of 10 means the challenge can only be claimed 10 times total, regardless of how many people participate.',
    id: 'AGQoT8',
  },
  totalClaims: {
    defaultMessage: 'total claims',
    id: 'vVsEK+',
  },
  unlimited: {
    defaultMessage: 'Unlimited',
    id: 'ekXood',
  },
  limited: {
    defaultMessage: 'Limited',
    id: 'hVIj7Y',
  },
  individualClaimLimitTitle: {
    defaultMessage: 'How many times can each participant claim the challenge?',
    id: 'cQSYvh',
  },
  individualClaimLimitTooltip: {
    defaultMessage:
      'This limits how many times each person can claim this challenge. You can set a specific number of times per challenge.',
    id: 'BYdKUB',
  },
  individualClaimLimitHintLabel: {
    defaultMessage: 'Limit must be under 500',
    id: 'V/o/c3',
  },
  numberOfTimes: {
    defaultMessage: 'time(s) each challenge',
    id: 'b0UCJe',
  },
  proofRequired: {
    defaultMessage: 'Require proof of completion',
    id: '0lZ9Ua',
  },
  typesQuestion: {
    defaultMessage: 'What types of proof are required?',
    id: '80c155',
  },
  text: {
    defaultMessage: 'Text description',
    id: 'aAb2Or',
  },
  customisePrompt: {
    defaultMessage: 'Customize your prompt',
    id: 'jICpQU',
  },
  promptValidation: {
    defaultMessage: 'Prompt must be 100 characters or less',
    id: 'lR3JP+',
  },
  emptyPromptValidation: {
    defaultMessage: 'Prompt cannot be empty',
    id: 'dF/JYk',
  },
  textPlaceHolder: {
    defaultMessage: 'Write an explanation of how you completed this challenge:',
    id: 'kCgGFf',
  },
  challengeFileUploadDefaultPromptMessage: {
    defaultMessage: 'Upload file(s) to show you completed the challenge',
    id: 'SUZCvj',
  },
  proofHintLabel: {
    defaultMessage: '100 characters maximum',
    id: '1nElxG',
  },
  file: {
    defaultMessage: 'File upload',
    id: 'hEl6BL',
  },
  filePlaceHolder: {
    defaultMessage: 'Upload images, files, or videos:',
    id: 'tSmhA3',
  },
  imageUpload: {
    defaultMessage:
      'Upload an image for the challenge (recommended: 236x180px)',
    id: 'go5jev',
  },
  customizeClaimButton: {
    defaultMessage: 'Customize the claim button',
    id: 'BpkjKE',
  },
  emptyClaimButton: {
    defaultMessage: 'Claim button text cannot be empty',
    id: 'Gm8jjn',
  },
  claimButtonError: {
    defaultMessage: 'Claim button text cannot exceed 20 characters',
    id: 'PAM3Nm',
  },
  buttonPlaceholder: {
    defaultMessage: 'Claim reward',
    id: '1j9SfB',
  },
  clickToUploadTitle: {
    defaultMessage: 'Click here to upload or drag and drop',
    id: 'RBu4Ux',
  },
  challengeAcceptedFileTypes: {
    defaultMessage: 'Accepted file types: png, jpeg, bmp. Max file size: 30MB',
    id: 'Fr4swT',
  },
  buttonLimitHintLabel: {
    defaultMessage: '20 character limit',
    id: 'C2TU05',
  },
  interactionSettings: {
    defaultMessage: 'Challenge interaction settings',
    id: 'W6fNZX',
  },
  notificationTitle: {
    defaultMessage: 'Where do you want notify your participants and approvers?',
    id: 'bQ6wm4',
  },
  notificationDesc: {
    defaultMessage:
      'Send notifications to the places people are working. These notification settings will apply to participants and approvers. Who will see and be able to claim this challenge?',
    id: 'nzTZWL',
  },
  previewList: {
    defaultMessage: 'Preview list',
    id: 'y9y6sj',
  },
  saveAndLaunchLater: {
    defaultMessage: 'Save and launch later',
    id: 'hy97qW',
  },
  createAndLaunch: {
    defaultMessage: 'Create and launch',
    id: 'D3zOP5',
  },
  saveAndLaunch: {
    defaultMessage: 'Save and launch',
    id: 'uVziNn',
  },
  saveChanges: {
    defaultMessage: 'Save Changes',
    id: '3VI9mt',
  },
  closeModal: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
  emailNotifyOnLaunchDescription: {
    defaultMessage:
      'Email your audience when this challenge is launched and when new claims are submitted.',
    id: 'WqCDpQ',
  },
  mobileAppNotifyOnLaunchDescription: {
    defaultMessage:
      'Send a push notification to your audience when this challenge is launched and when new claims are submitted.',
    id: 'H2z2j1',
  },
  confirmationModalLabel: {
    defaultMessage: 'Leave without finishing your challenge?',
    id: '2WaKx/',
  },
  confirmationModalDesc: {
    defaultMessage: 'Your challenge will not be saved.',
    id: 's1zBde',
  },
  confirmationModalButton: {
    defaultMessage: 'Leave challenge',
    id: 'wyCTuK',
  },
  activeChallengeLimitDisabledTooltip: {
    defaultMessage: 'Limits cannot be edited after the challenge has launched',
    id: '445kMj',
  },
});
