import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import type {
  AriaAttributes,
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  ForwardedRef,
} from 'react';
import { forwardRef } from 'react';

import { Tooltip } from '../..';

const buttonStyles = cva(
  'inline-flex items-center justify-center gap-2 rounded-[8px] px-3 text-sm font-medium leading-[22px] focus-visible:bg-gray-3 enabled:hover:bg-gray-3 disabled:cursor-not-allowed dark:bg-gray-9 dark:hover:bg-gray-8 dark:focus:ring-primary-4 dark:focus:ring-offset-gray-9 dark:focus-visible:outline-none',
  {
    variants: {
      variation: {
        primary: '',
        secondaryEmphasized:
          'border border-primary-6 bg-gray-1 text-primary-6 dark:border-primary-3 dark:text-primary-3 dark:focus:bg-gray-8 dark:focus-visible:outline-none',
        secondaryLite:
          'border border-gray-5 bg-gray-1 text-gray-8 dark:border-gray-4 dark:text-gray-2 dark:focus:bg-gray-8',
        tertiaryEmphasized:
          'bg-gray-1 font-normal text-primary-6 dark:text-primary-3 dark:focus:bg-gray-8',
        tertiaryLite:
          'bg-gray-1 font-normal text-gray-8 dark:text-gray-2 dark:focus:bg-gray-8',
        danger:
          'bg-error-7 font-medium text-gray-1 focus:!bg-error-8 focus:ring-error-8 enabled:hover:!bg-error-8 dark:bg-error-3 dark:text-brand-9 dark:hover:!bg-error-2  dark:focus:!bg-error-4 dark:focus:!ring-error-4',
        dangerLite:
          'border border-error-6 text-error-7 font-medium  hover:bg-error-3 focus-visible:ring-error-6 focus:ring-error-6',
        success:
          'border border-success-8 bg-success-2 font-medium text-success-8 focus:bg-success-3 focus:ring-success-8 enabled:hover:bg-success-3 dark:border-success-3 dark:bg-success-9 dark:text-success-3 dark:hover:bg-success-8 dark:focus:bg-success-10 dark:focus:ring-success-3',
        upgrade:
          'border border-upgrade-6 bg-gray-1 text-upgrade-9 focus:bg-gray-3 focus:ring-upgrade-6 enabled:hover:bg-gray-3 dark:border-none dark:bg-upgrade-3 dark:text-upgrade-9 dark:hover:bg-upgrade-2 dark:focus:bg-upgrade-4 dark:focus:ring-upgrade-3',
      },
      size: {
        small: 'h-8 py-1',
        large: 'h-10 py-2',
      },
      isLoading: {
        true: 'cursor-not-allowed transition duration-150 ease-in-out',
      },
      disabled: {
        true: 'border-none !bg-gray-2 !text-gray-7 !shadow-none dark:!bg-gray-8 dark:!text-gray-6 dark:hover:!bg-gray-8',
      },
      isFullWidth: {
        true: 'w-full',
      },
    },
    defaultVariants: {
      variation: 'primary',
      isFullWidth: false,
    },
    compoundVariants: [
      {
        variation: 'primary',
        disabled: false,
        className:
          'block border border-primary-6 !bg-primary-6 text-gray-1 hover:!bg-primary-7 focus:!bg-primary-7 dark:border-none dark:!bg-primary-4 dark:text-primary-9 dark:hover:!bg-primary-3 dark:focus:!bg-primary-5 dark:focus:!ring-primary-4',
      },
      {
        variation: 'primary',
        isLoading: true,
        className:
          '!bg-primary-7 outline-none ring-2 ring-primary-6 ring-offset-2 dark:!bg-primary-5 dark:outline-none dark:!ring-primary-4 dark:ring-offset-gray-9 dark:hover:!bg-primary-5',
      },
      {
        variation: [
          'secondaryEmphasized',
          'secondaryLite',
          'tertiaryEmphasized',
          'tertiaryLite',
        ],
        isLoading: true,
        className:
          'bg-gray-3 outline-none ring-2 ring-primary-6 ring-offset-2 dark:bg-gray-8 dark:outline-none dark:ring-primary-4 dark:ring-offset-gray-9',
      },
      {
        variation: 'danger',
        isLoading: true,
        className:
          '!bg-error-8 outline-none ring-2 !ring-error-8 ring-offset-2 dark:!bg-error-4 dark:!ring-error-4 dark:ring-offset-gray-9 dark:hover:!bg-error-4',
      },
      {
        variation: 'success',
        isLoading: true,
        className:
          'border bg-success-3 outline-none ring-2 ring-success-7 ring-offset-2 dark:bg-success-10 dark:ring-success-3 dark:ring-offset-success-10 dark:hover:bg-success-10',
      },
      {
        variation: 'upgrade',
        isLoading: true,
        className:
          'border bg-gray-3 outline-none ring-2 ring-upgrade-6 ring-offset-2 dark:bg-upgrade-4 dark:ring-upgrade-3 dark:ring-offset-gray-9 dark:hover:bg-upgrade-4',
      },
      {
        variation: 'success',
        disabled: true,
        className:
          '!border-solid !border-gray-5 dark:border dark:border-solid dark:border-gray-6',
      },
      {
        variation: ['primary', 'tertiaryEmphasized', 'tertiaryLite', 'danger'],
        disabled: true,
        className:
          'border-none !bg-gray-2 !text-gray-7 !shadow-none dark:!bg-gray-8 dark:!text-gray-6 dark:hover:!bg-gray-8',
      },
      {
        variation: ['secondaryEmphasized', 'secondaryLite'],
        disabled: true,
        className:
          '!border !border-solid !border-gray-5 dark:border dark:border-solid dark:border-gray-6',
      },
    ],
  }
);

export const ButtonVariations = [
  'primary',
  'secondaryEmphasized',
  'secondaryLite',
  'tertiaryEmphasized',
  'tertiaryLite',
  'danger',
  'dangerLite',
  'success',
  'upgrade',
] as const;

export const ButtonSize = ['small', 'large'] as const;

export type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  AriaAttributes & {
    isLoading?: boolean;
    isFullWidth?: boolean;
    variation?: (typeof ButtonVariations)[number];
    size?: (typeof ButtonSize)[number];
    ref?: ForwardedRef<HTMLButtonElement>;
    asChild?: boolean;
    tooltipText?: string;
  };

export const Button = forwardRef(
  (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      className,
      children,
      isLoading = false,
      disabled = false,
      variation = 'primary',
      size = 'large',
      isFullWidth,
      asChild,
      tooltipText,
      ...buttonProps
    } = props;

    const Component = asChild ? Slot : 'button';

    return (
      <Tooltip tooltipText={tooltipText}>
        <Component
          className={buttonStyles({
            variation,
            size,
            isLoading,
            disabled,
            isFullWidth,
            className,
          })}
          disabled={isLoading || disabled}
          {...buttonProps}
          ref={ref}
        >
          {children}
        </Component>
      </Tooltip>
    );
  }
);

Button.displayName = 'Button';
