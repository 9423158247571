import {
  ChevronLeftIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import type { DropdownOption } from '../../../DesignSystem/Inputs/DropdownMenu';
import { DropdownMenu } from '../../../DesignSystem/Inputs/DropdownMenu';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import { TextField } from '../../../DesignSystem/Inputs/TextField';
import { FixedVirtualizedList } from '../../Shared/VirtualizedList';
import {
  CriteriaItem,
  CriteriaItemLoader,
  type CriteriaItemProps,
} from './CriteriaItem';

const messages = defineMessages({
  previewModalSubtitle: {
    defaultMessage:
      'See who has permission to collaborate and view this collection.',
    id: 'KvBw23',
  },
  previewModalTitle: {
    defaultMessage: 'Preview list',
    id: 'y9y6sj',
  },
  noResultsFound: {
    defaultMessage: 'No results. Please try another search.',
    id: 'AX8eCS',
  },
  noResultsFoundWithFilter: {
    defaultMessage:
      'No results. Please try another search or search in “All roles”.',
    id: 'hrZaG4',
  },
} as const);

export type PreviewMembersProps = {
  view: 'previewMembers';
  options: DropdownOption[];
  onPreviewModalBackButtonClick: () => void;
  rules: CriteriaItemProps[];
  hasNextPage: boolean;
  onLoadMore: () => void;
  onSearchOrFilterList: (args: { searchTerm: string; filter: string }) => void;
  isLoadingPreview?: boolean;
  isFetchingMoreMembers?: boolean;
  className?: string;
  subTitle?: string;
};

export function PreviewMembers({
  options,
  onPreviewModalBackButtonClick,
  rules,
  hasNextPage,
  onLoadMore,
  onSearchOrFilterList,
  isLoadingPreview,
  subTitle,
  className,
}: Omit<PreviewMembersProps, 'view'>) {
  const { formatMessage } = useIntl();

  const [selectedOption, setSelectedOption] = useState(() =>
    options.length > 0 ? options[0].value : ''
  );

  const [searchTerm, setSearchTerm] = useState('');

  const itemCount =
    hasNextPage && rules.length ? rules.length + 1 : rules.length;

  const showDropDown = options.length > 0;

  const onOptionSelect = ({ value }: DropdownOption) => {
    setSelectedOption(value);
    onSearchOrFilterList({ filter: value, searchTerm });
  };

  const noResultsFoundLabel = useMemo(() => {
    if (selectedOption === 'all' || !showDropDown) {
      return formatMessage(messages.noResultsFound);
    }
    return formatMessage(messages.noResultsFoundWithFilter);
  }, [formatMessage, selectedOption, showDropDown]);

  return (
    <div className="w-full">
      <div className="flex flex-row items-start gap-1">
        <IconButton
          size="xSmall"
          variation="tertiaryLite"
          onClick={onPreviewModalBackButtonClick}
        >
          <ChevronLeftIcon className="h-4 w-4 text-gray-8" />
        </IconButton>
        <div className="flex flex-col gap-1">
          <TextStyle variant="lg-medium">
            {formatMessage(messages.previewModalTitle)}
          </TextStyle>
          <TextStyle variant="sm-regular">
            {subTitle ? subTitle : formatMessage(messages.previewModalSubtitle)}
          </TextStyle>
        </div>
      </div>
      <div className="flex w-full gap-2 px-6 py-4">
        <div className={showDropDown ? 'w-1/2' : 'w-full'}>
          <TextField
            invalidText=""
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm((e.target as HTMLInputElement).value);
              onSearchOrFilterList({
                searchTerm: (e.target as HTMLInputElement).value,
                filter: selectedOption,
              });
            }}
            isInvalid={false}
            placeholder="Search members"
            connectedLeft={
              <MagnifyingGlassIcon className="h-4 w-4 text-gray-8" />
            }
            inputClassName="focus:ring-0 focus:border-gray-5 text-sm"
            inputSize="md"
            aria-label="Search members on preview criteria list"
          />
        </div>
        {Boolean(showDropDown) && (
          <div className="w-1/2">
            <DropdownMenu
              value={selectedOption}
              options={options}
              onSelected={onOptionSelect}
              variant="bordered"
              className="m-0 w-full p-0"
              triggerClassName="w-full rounded-md focus:ring-0 focus:border-gray-5"
              contentClassName="w-[var(--radix-menubar-trigger-width)]"
              disabled={isLoadingPreview}
              isPortalDisabled
            />
          </div>
        )}
      </div>

      <section className={twMerge('mt-2 min-h-[180px] px-6', className)}>
        {Boolean(isLoadingPreview) && (
          <>
            <CriteriaItemLoader showDropdownLoader={false} />
            <CriteriaItemLoader showDropdownLoader={false} />
          </>
        )}
        {!isLoadingPreview &&
          (rules.length ? (
            <FixedVirtualizedList
              itemCount={itemCount}
              items={rules}
              fixedItemSize={60}
              onLoadMore={onLoadMore}
              renderItem={(item) => {
                return <CriteriaItem {...item} />;
              }}
            />
          ) : (
            <TextStyle className="w-full pt-4 text-center text-gray-9">
              {noResultsFoundLabel}
            </TextStyle>
          ))}
      </section>
    </div>
  );
}
