import {
  ArrowsPointingInIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
} from '@heroicons/react/20/solid';
import * as Slider from '@radix-ui/react-slider';
import { motion, useMotionValue } from 'framer-motion';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type WheelEventHandler,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Tooltip } from '../../../../DesignSystem/Feedback/Tooltip';

const messages = defineMessages({
  adjustZoomLevel: {
    defaultMessage: 'Adjust zoom level',
    id: 'hEhE3v',
  },
  resetZoom: {
    defaultMessage: 'Reset zoom',
    id: 'nQhPfW',
  },
  zoomIn: {
    defaultMessage: 'Zoom in',
    id: 'xbi38c',
  },
  zoomLevel: {
    defaultMessage: 'Zoom level',
    id: '6GminY',
  },
  zoomOut: {
    defaultMessage: 'Zoom out',
    id: '/UnJ3S',
  },
});

export const ImagePreviewer = ({
  imageURL,
  isOpen,
}: {
  imageURL: string;
  isOpen: boolean;
}) => {
  const { formatMessage } = useIntl();

  const defaultButtonRef = useRef<HTMLButtonElement>(null);

  const scale = useMotionValue(1);
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const [zoomLevel, setZoomLevel] = useState(100);

  const zoomStep = 5;
  const minZoomLevel = 50;
  const maxZoomLevel = 200;

  useEffect(() => {
    scale.set(zoomLevel / 100);
  }, [zoomLevel, scale]);

  const handleZoomReset = useCallback(() => {
    scale.set(1);
    x.set(0);
    y.set(0);
    setZoomLevel(100);
  }, [scale, x, y]);

  const handleZoomIn = () => {
    const newZoomLevel = zoomLevel + zoomStep;
    if (newZoomLevel <= maxZoomLevel) {
      setZoomLevel(newZoomLevel);
    }
  };

  const handleZoomOut = () => {
    const newZoomLevel = zoomLevel - zoomStep;
    if (newZoomLevel >= minZoomLevel) {
      setZoomLevel(newZoomLevel);
    }
  };

  const handleWheel: WheelEventHandler<HTMLImageElement> = (event) => {
    event.preventDefault();
    const newScale = scale.get() - event.deltaY * 0.001;
    if (newScale >= 1) {
      setZoomLevel(newScale * 100);
    }
  };

  useEffect(() => defaultButtonRef.current?.focus(), [isOpen]);

  useEffect(() => {
    handleZoomReset();
  }, [handleZoomReset]);

  return (
    <div className="relative flex h-full w-full justify-center overflow-auto bg-gray-3">
      <motion.img
        src={imageURL}
        alt=""
        className="absolute bottom-0 left-0 right-0 top-0 m-auto h-full object-contain"
        style={{ x, y, scale }}
        drag
        dragConstraints={{
          left: -500,
          right: 500,
          top: -500,
          bottom: 500,
        }}
        onWheel={handleWheel}
      />
      <div className="absolute bottom-2 flex w-44 items-center justify-center space-x-2 overflow-hidden rounded-2xl bg-gray-9 shadow-base-down">
        <Tooltip tooltipText={formatMessage(messages.zoomOut)}>
          <button onClick={handleZoomOut} className="cursor-pointer">
            <MagnifyingGlassMinusIcon className="h-4 w-4 text-gray-1" />
          </button>
        </Tooltip>
        <Slider.Root
          className="relative flex h-2 w-20 touch-none select-none items-center"
          min={minZoomLevel}
          max={maxZoomLevel}
          step={zoomStep}
          value={[zoomLevel]}
          onValueChange={(values) => setZoomLevel(values[0])}
        >
          <Slider.Track className="relative h-0.5 grow rounded-full bg-gray-5">
            <Slider.Range className="absolute h-full rounded-full bg-gray-1" />
          </Slider.Track>
          <Tooltip tooltipText={formatMessage(messages.adjustZoomLevel)}>
            <Slider.Thumb
              className="block h-2 w-2 rounded-full bg-brand-5 shadow-[0_1px_8px] shadow-gray-8 focus:shadow-[0_0_0_3px] focus:shadow-primary-5 focus:outline-none"
              aria-label={formatMessage(messages.zoomLevel)}
            />
          </Tooltip>
        </Slider.Root>
        <Tooltip tooltipText={formatMessage(messages.zoomIn)}>
          <button
            onClick={handleZoomIn}
            className="cursor-pointer"
            ref={defaultButtonRef}
          >
            <MagnifyingGlassPlusIcon className="h-4 w-4 text-gray-1" />
          </button>
        </Tooltip>
        <div className="h-8 w-[1px] bg-gray-5 px-[0.5px]" />
        <Tooltip tooltipText={formatMessage(messages.resetZoom)}>
          <button onClick={handleZoomReset} className="cursor-pointer">
            <ArrowsPointingInIcon className="h-4 w-4 text-gray-1" />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};
