import type { Member } from '@assembly-web/services';
import { createContext } from 'react';

export type ChatUser = Pick<
  Member,
  'memberId' | 'firstName' | 'lastName' | 'image'
>;

type ChatContextProps = {
  isChatEnabled: boolean;
  onSendMessageButtonClick: (user: ChatUser) => void;
};

export const ChatContext = createContext<ChatContextProps | null>(null);
