import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import type { PanInfo } from 'framer-motion';
import { motion } from 'framer-motion';
import type { CSSProperties, ReactNode, RefObject } from 'react';
import {
  Children,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';

const messages = defineMessages({
  previousLabel: {
    defaultMessage: `Previous`,
    id: 'JJNc3c',
  },
  nextLabel: {
    defaultMessage: `Next`,
    id: '9+Ddtu',
  },
});

export type ControlClickArgs = {
  index: number;
  direction: 'left' | 'right';
};

export type CarouselProps = {
  buttonSize?: 'sm' | 'md';
  children: ReactNode;
  slidesToScroll?: number;
  onControlClick?: (args: ControlClickArgs) => void;
  lastCard?: ReactNode;
  isActive?: boolean;
  childrenClasses?: string;
  firstItemStyle?: CSSProperties;
  lastItemStyle?: CSSProperties;
  align?: 'center' | 'start';
  fullWidth?: boolean;
  onNavigate?: (options: {
    showPreviousButton: boolean;
    showNextButton: boolean;
  }) => void;
  hideNavControls?: boolean;
};

export type CarouselRef = {
  handleNext: () => void;
  handlePrevious: () => void;
};

export const Carousel = forwardRef<CarouselRef, CarouselProps>(
  function Carousel(props, ref) {
    const {
      buttonSize = 'md',
      children,
      slidesToScroll = 3,
      onControlClick,
      lastCard,
      isActive,
      firstItemStyle,
      lastItemStyle,
      childrenClasses,
      align = 'center',
      fullWidth = false,
      onNavigate,
      hideNavControls = false,
    } = props;
    const { formatMessage } = useIntl();

    const childLength = Children.count(children);
    const childrenLength = lastCard ? childLength + 1 : childLength;

    const [showNextButton, setShowNextButton] = useState(false);
    const [showPreviousButton, setShowPreviousButton] = useState(false);

    let [index, setIndex] = useState(0);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

    const totalSlidesWidth = itemRefs.current.reduce(
      (acc, item) => acc + (item?.clientWidth ?? 0),
      0
    );

    const gapWidth = 12;

    const containerWidth = containerRef.current?.clientWidth ?? 0;

    const getXPosition = useCallback(() => {
      const leftmostPosition = Math.min(
        0,
        containerWidth - (childrenLength * gapWidth + totalSlidesWidth)
      );
      const currSlidesWidth = itemRefs.current
        .slice(0, index)
        .reduce((acc, item) => acc + (item?.clientWidth ?? 0), 0);
      return Math.max(leftmostPosition, -(index * gapWidth + currSlidesWidth));
    }, [childrenLength, containerWidth, index, totalSlidesWidth]);

    const [xPosition, setXPosition] = useState<number>(getXPosition);

    useEffect(() => {
      setXPosition(getXPosition);
    }, [getXPosition, index]);

    const lastItemWidth =
      itemRefs.current[itemRefs.current.length - 1]?.clientWidth ?? 0;

    const lastItemExtendsWidth = lastItemWidth > containerWidth;

    const handlePrevious = () => {
      setIndex((prevIndex) => {
        const nextIndex = Math.max(0, prevIndex - slidesToScroll);
        onControlClick?.({ index: nextIndex, direction: 'left' });
        return nextIndex;
      });
    };

    const handleNext = () => {
      const maxIndex = lastItemExtendsWidth
        ? childrenLength
        : childrenLength - 1;
      setIndex((prevIndex) => {
        const nextIndex = Math.min(maxIndex, prevIndex + slidesToScroll);
        onControlClick?.({
          direction: 'right',
          index: nextIndex + slidesToScroll,
        });
        return nextIndex;
      });
    };

    const handleEndDrag = (
      _event: MouseEvent | TouchEvent | PointerEvent,
      dragProps: PanInfo
    ) => {
      const dragThreshold = 10;
      const clientWidth = containerRef.current?.clientWidth ?? 0;

      const { offset } = dragProps;

      if (offset.x > clientWidth / dragThreshold && index > 0) {
        handlePrevious();
      } else if (
        offset.x < -clientWidth / dragThreshold &&
        index < childrenLength - 1
      ) {
        handleNext();
      }
    };

    const buttonSizeSmall = buttonSize === 'sm';

    useEffect(() => {
      const slidesClientWidth = itemRefs.current.reduce(
        (acc, item) => acc + (item?.clientWidth ?? 0),
        0
      );

      const containerClientWidth = containerRef.current?.clientWidth ?? 0;

      const doesEntireContentFitWithinCarousel =
        slidesClientWidth > containerClientWidth;

      setShowPreviousButton(index > 0 && doesEntireContentFitWithinCarousel);

      setShowNextButton(
        index <
          (lastItemExtendsWidth
            ? childrenLength - slidesToScroll + 1
            : childrenLength - slidesToScroll) &&
          doesEntireContentFitWithinCarousel
      );
    }, [index, childrenLength, slidesToScroll, lastItemExtendsWidth]);

    useEffect(() => {
      onNavigate?.({
        showNextButton,
        showPreviousButton,
      });
    }, [onNavigate, showNextButton, showPreviousButton]);

    useImperativeHandle(
      ref,
      () =>
        ({
          handleNext,
          handlePrevious,
        }) satisfies CarouselRef
    );

    return (
      <div className="-ml-1 h-full w-full">
        <div
          className={twMerge(
            'border-white mx-auto flex h-full w-full flex-col justify-center',
            !fullWidth && 'max-w-7xl'
          )}
        >
          <div
            className={twMerge(
              'relative max-w-[100%]',
              !fullWidth && 'w-[960px]',
              align === 'center' && 'm-auto'
            )}
          >
            <div className="overflow-hidden">
              <motion.div
                initial={false}
                ref={containerRef}
                whileTap={{
                  cursor:
                    childrenLength > slidesToScroll ? 'grabbing' : 'default',
                }}
                animate={{ x: xPosition }}
                transition={{ duration: 0.9, ease: [0.32, 0.72, 0, 1] }}
              >
                <motion.div
                  className={twMerge('flex gap-[12px]', childrenClasses)}
                  drag={childrenLength > slidesToScroll ? 'x' : undefined}
                  dragElastic={0.3}
                  onDragEnd={handleEndDrag}
                  dragConstraints={{ right: 0, left: 0 }}
                >
                  {Children.map(children, (item: ReactNode, index: number) =>
                    item ? (
                      <div
                        key={index}
                        ref={(el) => (itemRefs.current[index] = el)}
                        className={twJoin(
                          'flex-shrink-0',
                          index === 0 && 'ml-1'
                        )}
                      >
                        {item}
                      </div>
                    ) : null
                  )}
                  {lastCard ? (
                    <div
                      ref={(el) =>
                        (itemRefs.current[itemRefs.current.length - 1] = el)
                      }
                    >
                      {lastCard}
                    </div>
                  ) : null}
                </motion.div>
              </motion.div>
            </div>

            {Boolean(showPreviousButton) && !hideNavControls && (
              <div
                className={twMerge(
                  'absolute left-0 top-0 flex h-full w-10 items-center justify-center bg-white-gradient-2',
                  buttonSizeSmall && 'w-8'
                )}
                style={firstItemStyle}
              >
                <Button
                  onClick={handlePrevious}
                  variation="secondaryLite"
                  className={twMerge(
                    'absolute -left-2 h-8 w-8 !p-0 transition',
                    buttonSizeSmall && 'h-6 w-6'
                  )}
                  aria-label={formatMessage(messages.previousLabel)}
                >
                  <ArrowLeftIcon
                    className={twMerge(
                      'h-4 w-4 text-gray-8',
                      buttonSizeSmall && 'h-3 w-3'
                    )}
                  />
                </Button>
              </div>
            )}

            {Boolean(showNextButton) && !hideNavControls && (
              <div
                className={twMerge(
                  'absolute right-0 top-0 flex h-full w-10 items-center justify-center',
                  buttonSizeSmall && 'w-8',
                  !isActive && 'bg-white-gradient-1'
                )}
                style={lastItemStyle}
              >
                <Button
                  onClick={handleNext}
                  variation="secondaryLite"
                  className={twMerge(
                    'absolute -right-3 h-8 w-8 !p-0 transition',
                    buttonSizeSmall && 'h-6 w-6'
                  )}
                  aria-label={formatMessage(messages.nextLabel)}
                >
                  <ArrowRightIcon
                    className={twMerge(
                      'h-4 w-4 text-gray-8',
                      buttonSizeSmall && 'h-3 w-3'
                    )}
                  />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export function CarouselCompactNav({
  disablePreviousButton,
  disableNextButton,
  carouselRef,
}: {
  disablePreviousButton: boolean;
  disableNextButton: boolean;
  carouselRef: RefObject<CarouselRef>;
}) {
  const { formatMessage } = useIntl();
  if (!carouselRef.current) {
    return null;
  }

  if (disableNextButton && disablePreviousButton) {
    return null;
  }

  return (
    <div className="flex gap-4">
      <Tooltip tooltipText={formatMessage(messages.previousLabel)}>
        <IconButton
          size="xSmall"
          variation="tertiaryEmphasized"
          disabled={disablePreviousButton}
          onClick={carouselRef.current.handlePrevious}
          className="focus:bg-gray-1 focus:ring-0 focus:ring-offset-0"
        >
          <ChevronLeftIcon className="h-4 w-4" />
        </IconButton>
      </Tooltip>
      <Tooltip tooltipText={formatMessage(messages.nextLabel)}>
        <IconButton
          size="xSmall"
          disabled={disableNextButton}
          variation="tertiaryEmphasized"
          onClick={carouselRef.current.handleNext}
          className="focus:bg-gray-1 focus:ring-0 focus:ring-offset-0"
        >
          <ChevronRightIcon className="h-4 w-4" />
        </IconButton>
      </Tooltip>
    </div>
  );
}
