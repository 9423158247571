import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useIntl } from 'react-intl';

import {
  FilePreviewer,
  messages,
  type PreviewerFileType,
} from './FilePreviewer';

export const Carousel = (props: {
  startFileIndex?: number;
  onClose: () => void;
  files: Omit<PreviewerFileType, 'goToPostTooltip'>[];
  isChallengeClaim?: boolean;
  isUploading?: boolean;
}) => {
  const { formatMessage } = useIntl();

  const {
    startFileIndex = 0,
    onClose,
    files,
    isChallengeClaim,
    isUploading,
  } = props;
  const [currentFileIndex, setCurrentFileIndex] = useState(startFileIndex);

  useHotkeys('left', () => {
    previousFile();
  });

  useHotkeys('right', () => {
    nextFile();
  });

  const nextFile = () => {
    if (currentFileIndex < files.length - 1) {
      setCurrentFileIndex(currentFileIndex + 1);
    } else if (currentFileIndex === files.length - 1) {
      setCurrentFileIndex(0);
    }
  };

  const previousFile = () => {
    if (currentFileIndex > 0) {
      setCurrentFileIndex(currentFileIndex - 1);
    } else if (currentFileIndex === 0) {
      setCurrentFileIndex(files.length - 1);
    }
  };

  return (
    <FilePreviewer
      isOpen
      onClose={onClose}
      file={{
        ...files[currentFileIndex],
        goToPostTooltip: formatMessage(
          isChallengeClaim ? messages.goToChallengeClaim : messages.goToPost
        ),
      }}
      enableControls={files.length > 1}
      handleLeftClick={previousFile}
      handleRightClick={nextFile}
      isUploading={isUploading}
    />
  );
};
