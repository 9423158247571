import {
  type BlockResponseDetails,
  BlockTypes,
  MemberState,
} from '@assembly-web/services';
import type { ReactElement } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Avatar, AvatarSize } from '../../../DesignSystem/Feedback/Avatar';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import type { BoostOptions } from '../../Web/Editors/RepliesEditor/RepliesEditor';
import { GroupOrUserChipList } from '../../Web/GroupOrUserChipList';
import { ProfileViewer } from '../../Web/ProfileViewer/ProfileViewer';
import { PostCardActions, type PostCardProps } from './PostCardUI';

const messages = defineMessages({
  deactivatedUser: {
    defaultMessage: 'deactivated',
    id: 'uuPhrD',
  },
  everyone: {
    defaultMessage: 'Everyone',
    id: '2XDuH4',
  },
});

export function PersonSelectorBlock({
  onMemberClick,
  onPostCardBodyClick,
  boostOptions,
  editTag,
  responseBlock,
  responseId,
}: {
  boostOptions?: BoostOptions;
  editTag: ReactElement | null;
  onMemberClick: (memberId: string) => void;
  onPostCardBodyClick: PostCardProps['onPostCardBodyClick'];
  responseBlock: BlockResponseDetails;
  responseId: string;
}) {
  const { formatMessage } = useIntl();

  if (
    responseBlock.type !== BlockTypes.PersonSelector ||
    responseBlock.response == null
  ) {
    return null;
  }

  return (
    <>
      <div className="flex flex-wrap gap-2 text-gray-9">
        {'recipients' in responseBlock.response ? (
          <GroupOrUserChipList data={responseBlock.response.recipients} />
        ) : (
          responseBlock.response.persons.map((person) => {
            const firstName = person.firstName ?? person.name.firstName;
            const lastName = person.lastName ?? person.name.lastName ?? '';
            if (person.memberState === MemberState.Deactivated) {
              return (
                <div
                  key={`${responseId}-${responseBlock.blockId}-${person.memberId}`}
                  className="mt-1 flex w-fit items-center gap-2 rounded-2xl bg-gray-2 p-1 pr-4"
                >
                  <Avatar
                    className="grayscale"
                    image={person.image}
                    memberID={person.memberId}
                    name={firstName.toString()}
                    size={AvatarSize.Small}
                  />
                  <TextStyle variant="base-regular" className="text-gray-8">
                    {formatMessage(messages.deactivatedUser, {
                      firstName: firstName,
                      lastName: lastName,
                    })}
                  </TextStyle>
                </div>
              );
            } else {
              return (
                <ProfileViewer
                  boostOptions={boostOptions}
                  onViewProfileClick={() => {
                    onMemberClick(person.memberId);
                  }}
                  userDetails={{
                    ...person,
                    memberID: person.memberId,
                    firstName: firstName,
                    lastName: lastName,
                  }}
                  key={`${responseId}-${responseBlock.blockId}-${person.memberId}`}
                >
                  <button
                    key={`${responseId}-${responseBlock.blockId}-${person.memberId}`}
                    onClick={() => {
                      onPostCardBodyClick(
                        PostCardActions.PersonClicked,
                        person.memberId
                      );
                    }}
                    className="mt-1 flex w-fit items-center gap-2 rounded-2xl bg-gray-3 py-1 pl-1 pr-2 text-gray-9 hover:bg-gray-4"
                  >
                    <Avatar
                      image={person.image}
                      memberState={person.memberState}
                      memberID={person.memberId}
                      name={firstName.toString()}
                      size={AvatarSize.Small}
                    />
                    {`${firstName.toString()} ${lastName.toString()}`}
                  </button>
                </ProfileViewer>
              );
            }
          })
        )}
      </div>
      {Boolean(responseBlock.edited) && editTag}
    </>
  );
}
