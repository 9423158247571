import type { MemberState } from '@assembly-web/services';
import { UserPlusIcon } from '@heroicons/react/20/solid';
import { Fallback, Image, Root } from '@radix-ui/react-avatar';
import { cva } from 'class-variance-authority';
import { forwardRef, memo, type ReactNode } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { AnonymousReplyIcon } from '../../UI/assets/icons';
import { Tooltip } from './Tooltip';

export enum AvatarSize {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl',
  DoubleExtraLarge = '2xl',
  QuadExtraLarge = '4xl',
  HexExtraLarge = '6xl',
}

export const avatarSizes = cva([], {
  variants: {
    size: {
      xs: 'h-4 w-4',
      sm: 'h-6 w-6',
      md: 'h-8 w-8',
      lg: 'h-10 w-10',
      xl: 'h-11 w-11',
      '2xl': 'h-12 w-12',
      '4xl': 'h-14 w-14',
      '6xl': 'h-16 w-16',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export type AvatarProps = {
  image?: string;
  memberID: string;
  name: string;
  size: AvatarSize;
  className?: string;
  tooltipText?: string;
  isRounded?: boolean;
  memberState?: MemberState;
};

/**
 *
 * @param userId
 * @returns a color which is based on the userID
 * We use User ID because that is unique to each user since the assignment and is consistent
 * Even if the user name is changed, the user ID will remain the same
 * which implies the color would remain consistent since initial login
 */
const getAvatarBackgroundColor = (userId: string) => {
  let lastChar = 'p';
  if (userId) {
    lastChar = userId.substr(userId.length - 1);
  }
  const charCode = lastChar.charCodeAt(0);
  switch (charCode) {
    case 97:
    case 113:
    case 103:
    case 119:
    case 49:
    case 111:
      return 'magenta-7';
    case 98:
    case 114:
    case 104:
    case 120:
    case 50:
    case 57:
      return 'cyan-8';
    case 99:
    case 115:
    case 106:
    case 122:
    case 52:
    case 112:
      return 'success-8';
    case 100:
    case 116:
    case 105:
    case 121:
    case 51:
    case 108:
      return 'warning-9';
    case 101:
    case 117:
    case 107:
    case 53:
    case 110:
    case 56:
    case 54:
      return 'warning-8';
    case 102:
    case 118:
    case 48:
    case 109:
    case 55:
      return 'brand-7';
    default:
      return 'blue-7';
  }
};

function AvatarWrapper({
  children,
  tooltipText,
}: {
  children: ReactNode;
} & Pick<AvatarProps, 'tooltipText'>) {
  if (!tooltipText) {
    return <>{children}</>;
  }
  return <Tooltip tooltipText={tooltipText}>{children}</Tooltip>;
}

const altMessage = defineMessage({
  id: 'Tygbom',
  defaultMessage: "{name}'s profile picture",
});

export const Avatar = memo(
  forwardRef<HTMLDivElement, AvatarProps>(function Avatar(
    {
      image,
      memberState,
      memberID,
      name = '',
      size,
      className,
      tooltipText,
      isRounded = true,
    },
    ref
  ) {
    const { formatMessage } = useIntl();

    const avatarImage =
      name === 'Anonymous' && !memberID ? AnonymousReplyIcon : image;

    return (
      <AvatarWrapper tooltipText={tooltipText}>
        <Root
          ref={ref}
          className={twMerge(
            'group flex flex-shrink-0',
            isRounded ? 'rounded-full' : 'rounded-md',
            className
          )}
        >
          <Image
            src={avatarImage}
            alt={formatMessage(altMessage, { name })}
            className={twMerge(
              'inline min-h-4 min-w-4 object-cover group-hover:border-transparent max-md:max-w-none',
              isRounded && 'rounded-full',
              !isRounded && 'rounded-md',
              avatarSizes({ size })
            )}
          />
          <Fallback
            className={twMerge(
              'flex min-h-4 min-w-4 items-center justify-center',
              isRounded && 'rounded-full',
              !isRounded && 'rounded-md',
              memberState === 'PENDING'
                ? 'bg-gray-6'
                : `bg-${getAvatarBackgroundColor(memberID)}`,
              avatarSizes({ size })
            )}
          >
            {memberState === 'PENDING' ? (
              <UserPlusIcon className="h-4 w-4" />
            ) : (
              <span
                className={twMerge(
                  'text-sm font-medium text-gray-1',
                  size === AvatarSize.ExtraSmall && 'text-xs'
                )}
              >
                {name.slice(0, 1).toUpperCase()}
              </span>
            )}
          </Fallback>
        </Root>
      </AvatarWrapper>
    );
  })
);
