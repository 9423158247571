import { Content, List, Root, Trigger } from '@radix-ui/react-tabs';
import type { ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';

export type Tab = {
  title: string;
  content: ReactNode;
  id: string;
};

export function Tabs({
  tabs,
  selectedTab,
  onTabChange,
}: {
  tabs: Tab[];
  selectedTab: string;
  onTabChange: (value: string) => void;
}) {
  return (
    <Root value={selectedTab} onValueChange={onTabChange}>
      <List className="flex w-full rounded-t-lg bg-gray-1">
        {tabs.map(({ title, id }) => (
          <Trigger
            key={`tab-trigger-${id}`}
            value={id}
            className={twJoin(
              'inline-flex w-max flex-col items-center justify-start gap-0.5',
              'radix-state-active:border-b-[2px] radix-state-active:border-primary-6',
              'px-3 pb-1.5 pt-1'
            )}
            asChild
          >
            <button className="text-sm font-medium leading-snug text-primary-6">
              {title}
            </button>
          </Trigger>
        ))}
      </List>
      {tabs.map(({ id, content }) => (
        <Content key={`tab-content-${id}`} value={id}>
          {content}
        </Content>
      ))}
    </Root>
  );
}
