import { fromUrl, NO_HOSTNAME } from 'parse-domain';

export function checkValidUrl(newValue: string) {
  const trimmedValue = newValue.trim();

  return (
    trimmedValue.startsWith('http://') || trimmedValue.startsWith('https://')
  );
}

export function buildSafeUrl(
  baseUrl: string,
  paramsToBeEncoded?: Record<string, string> | URLSearchParams
) {
  const url = new URL(baseUrl);

  if (!paramsToBeEncoded) {
    return url.toString();
  }

  if (paramsToBeEncoded instanceof URLSearchParams) {
    Array.from(paramsToBeEncoded.entries()).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });
  } else {
    Object.entries(paramsToBeEncoded).forEach(([key, value]) => {
      url.searchParams.append(key, value);
    });
  }

  return url.toString();
}

/**
 * Retrieves the image source for the specified website's favicon.
 *
 *
 * @param url Web URL
 * @returns {string}
 */
export function getFaviconSource(url: string) {
  let domain = fromUrl(url);

  if (domain === NO_HOSTNAME) {
    domain = encodeURIComponent(url);
  }

  return `https://www.google.com/s2/favicons?domain=${domain}&sz=32`;
}
