import {
  type Icon,
  SplitNames,
  useDownload,
  useFeatureSplit,
} from '@assembly-web/services';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { ArrowDownTrayIcon, NewspaperIcon } from '@heroicons/react/24/outline';
import * as Dialog from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { IconButton } from '../../../DesignSystem/Inputs/IconButton';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';
import { Header } from './components/Header';
import { ImagePreviewer } from './components/ImagePreviewer';
import { PDFPreviewer } from './components/PDFPreviewer';
import { PreviewNotAvailable } from './components/PreviewNotAvailable';
import { VideoPreviewer } from './components/VideoPreviewer';
import { useGetFileDetails } from './hooks/useGetFileDetails';

export const messages = defineMessages({
  download: {
    defaultMessage: 'Download',
    id: '5q3qC0',
  },
  close: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
  workingOnSupport: {
    defaultMessage:
      'We’re working on supporting all file types. {downloadEnabled, select, true {In the meantime, download this file.} other {}}',
    id: 'tiqbQh',
  },
  unableToPreview: {
    defaultMessage: 'Unable to preview file',
    id: '3dhdmd',
  },
  previewNotAvailable: {
    defaultMessage: 'Preview not available for .{fileType} files',
    id: 'bTEMQS',
  },
  videoNotSupported: {
    defaultMessage:
      'Unable to preview video. {downloadEnabled, select, true {Please download the file to view it.} other {}}',
    id: 'KghYCB',
  },
  uploaded: {
    defaultMessage: 'uploaded',
    id: 'Ks4Ba7',
  },
  you: {
    defaultMessage: 'You',
    id: 'kJ5W29',
  },
  goToChallengeClaim: {
    defaultMessage: 'View challenge claim',
    id: 'erUW9r',
  },
  goToPost: {
    defaultMessage: 'See post and replies',
    id: 'X/hTz1',
  },
  uploading: {
    defaultMessage: 'are uploading',
    id: 'K7xt0G',
  },
  dateShared: {
    defaultMessage: 'Date shared:',
    id: 'uXQWZ2',
  },
  sharedIn: {
    defaultMessage: 'Shared in:',
    id: 'Joqb+w',
  },
});

export type PreviewerFileType = {
  locationUrl: string;
  fileType: string;
  fileName: string;
  fileSize?: number;
  memberID: string;
  memberName: string;
  handleGoToPost: () => void;
  goToPostTooltip: string;
  memberImage?: string;
  dateShared: string | Date | number;
  sharedIn: string;
  handleSharedInClick: () => void;
  sharedInIcon?: Icon;
};

type FilePreviewerProps = {
  file: PreviewerFileType;
  isOpen: boolean;
  onClose: () => void;
  primaryActions?: React.ReactNode | React.ReactNode[];
  secondaryActions?: React.ReactNode | React.ReactNode[];
  enableDownload?: boolean;
  enableControls?: boolean;
  handleLeftClick?: () => void;
  handleRightClick?: () => void;
  isUploading?: boolean;
};

export const FilePreviewer = (props: FilePreviewerProps) => {
  const {
    isOpen,
    onClose,
    enableDownload = true,
    enableControls,
    handleLeftClick,
    handleRightClick,
    isUploading = false,
    file,
  } = props;

  const { formatMessage } = useIntl();
  const isMobile = useDeviceInfo().deviceType === 'mobile';

  const { isTreatmentActive: isV3PreviewerActive } = useFeatureSplit(
    SplitNames.FilePreviewerV3
  );

  const { isImageUrl, isVideo, isPDF } = useGetFileDetails(file);

  const {
    memberID,
    memberName,
    handleGoToPost,
    goToPostTooltip,
    locationUrl,
    fileName,
    memberImage,
    sharedIn,
    handleSharedInClick,
    dateShared,
    sharedInIcon,
  } = file;

  const { downloadFile } = useDownload();

  return (
    <Dialog.Root open={isOpen}>
      <AnimatePresence>
        {Boolean(isOpen) && (
          <Dialog.Portal forceMount>
            <Dialog.Overlay
              asChild
              forceMount
              className="fixed inset-0 z-50 bg-neutral-secondary bg-opacity-75"
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.2, ease: 'easeIn' },
                }}
                transition={{ duration: 0.3, ease: 'easeOut' }}
              />
            </Dialog.Overlay>
            <Dialog.Content asChild forceMount>
              <motion.div
                initial={{
                  opacity: 0,
                  x: '-50%',
                  y: '-50%',
                  ...(!isMobile && { scale: 0.95 }),
                }}
                animate={{
                  opacity: 1,
                  x: '-50%',
                  y: '-50%',
                  ...(!isMobile && { scale: 1 }),
                }}
                exit={{
                  opacity: 0,
                  x: '-50%',
                  y: '-50%',
                  ...(!isMobile && { scale: 0.95 }),
                  transition: { duration: 0.2, ease: 'easeIn' },
                }}
                transition={{ duration: 0.3, ease: 'easeOut' }}
                className={twJoin(
                  'fixed left-[50%] top-[50%] z-50 flex w-full -translate-x-1/2 -translate-y-1/2 transform flex-col items-center bg-gray-1 text-left md:h-[95vh] md:max-h-[60rem] md:w-[95vw] md:max-w-[100rem]',
                  isMobile
                    ? 'min-w-screen inset-0 min-h-screen overflow-hidden'
                    : 'max-w-lg overflow-visible rounded-2xl shadow-xl-down focus:outline-none'
                )}
              >
                <Header
                  fileName={fileName}
                  onClose={onClose}
                  memberID={memberID}
                  memberName={memberName}
                  isUploading={isUploading}
                  memberImage={memberImage}
                  dateShared={dateShared}
                  sharedIn={sharedIn}
                  handleSharedInClick={handleSharedInClick}
                  sharedInIcon={sharedInIcon}
                />
                <div
                  className={twMerge(
                    !isMobile && 'rounded-b-2xl',
                    'flex h-full w-full flex-1 items-center justify-center overflow-hidden'
                  )}
                >
                  {isImageUrl ? (
                    <ImagePreviewer imageURL={locationUrl} isOpen />
                  ) : isVideo ? (
                    <VideoPreviewer
                      src={locationUrl}
                      downloadEnabled={enableDownload}
                    />
                  ) : isV3PreviewerActive && isPDF ? (
                    <PDFPreviewer src={locationUrl} />
                  ) : (
                    <PreviewNotAvailable
                      fileType={props.file.fileType}
                      downloadEnabled={enableDownload}
                    />
                  )}
                </div>
                {enableControls ? (
                  <>
                    <IconButton
                      variation="tertiaryEmphasized"
                      onClick={handleLeftClick}
                      className="absolute left-2 top-1/2 z-50 rounded-xl border border-primary-3 bg-primary-2 shadow-md-down hover:bg-blue-4 md:-left-4"
                    >
                      <ChevronLeftIcon className="h-6 w-6 text-gray-8" />
                    </IconButton>
                    <IconButton
                      variation="tertiaryEmphasized"
                      onClick={handleRightClick}
                      className="absolute right-2 top-1/2 z-50 rounded-xl border border-primary-3 bg-primary-2 shadow-md-down hover:bg-blue-4 md:-right-4"
                    >
                      <ChevronRightIcon className="bg-white h-6 w-6 text-gray-8" />
                    </IconButton>
                  </>
                ) : null}
                <div
                  className={twMerge(
                    isMobile
                      ? 'flex w-full flex-shrink-0 justify-between bg-white-gradient-1 p-4 py-6'
                      : 'rounded-b-2xl'
                  )}
                >
                  <Tooltip tooltipText={goToPostTooltip}>
                    <IconButton
                      className={twMerge(
                        'cursor-pointer',
                        !isMobile && 'absolute right-4 top-28'
                      )}
                      variation="secondaryEmphasized"
                      onClick={handleGoToPost}
                      size="small"
                    >
                      <NewspaperIcon className="h-4 w-4" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip tooltipText={formatMessage(messages.download)}>
                    <IconButton
                      className={twMerge(
                        'cursor-pointer',
                        !isMobile && 'absolute right-4 top-40'
                      )}
                      variation="secondaryEmphasized"
                      onClick={() =>
                        downloadFile(props.file.fileName, locationUrl)
                      }
                      size="small"
                    >
                      <ArrowDownTrayIcon className="h-4 w-4" />
                    </IconButton>
                  </Tooltip>
                </div>
              </motion.div>
            </Dialog.Content>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  );
};
