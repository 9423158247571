import {
  type AssemblyCurrency,
  AssemblyCurrencyType,
  isTruthy,
} from '@assembly-web/services';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import {
  Content as DropdownContent,
  Root as DropdownRoot,
  Trigger as DropdownTrigger,
} from '@radix-ui/react-dropdown-menu';
import dayjs from 'dayjs';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { ProgressBar } from '../../../DesignSystem/Feedback/ProgressBar';
import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { Tooltip } from '../../../DesignSystem/Feedback/Tooltip';
import { Button } from '../../../DesignSystem/Inputs/Button';
import { HorizontalRule } from '../../../DesignSystem/Layout/HorizontalRule';
import { mapHexCodeToEmoticon } from '../PostCard/PostCardHeader';

const messages = defineMessages({
  earned: {
    defaultMessage: 'To use:',
    id: 'Hnf5mN',
  },
  allowance: {
    defaultMessage: 'To give:',
    id: 'j3yNam',
  },
  allowanceTooltip: {
    defaultMessage:
      'This represents your monthly allowance and how much you’ve given this month. You have {totalAllowance} {currency} each month to give. Spread some joy!',
    id: 'Yb3tE6',
  },
  earnedBalanceTooltip: {
    defaultMessage: 'This represents your earned balance',
    id: '9d9pgm',
  },
  browseRewards: {
    defaultMessage: 'Browse Rewards',
    id: 'ug7T4L',
  },
  givenIn: {
    defaultMessage: 'Given in {month}',
    id: '3tHaZ/',
  },
  leftToGive: {
    defaultMessage:
      '{currencyIcon} {remainingAllowance} left to give in {month}',
    id: 'P9rwzn',
  },
  refreshesTo: {
    defaultMessage: 'Refreshes to {currencyIcon} {totalAllowance} on {month} 1',
    id: 'gPk2QL',
  },
  left: {
    defaultMessage: '{currencyIcon} {remainingAllowance}',
    id: 'TDF4ke',
  },
  yourEarnedBalance: {
    defaultMessage: 'Your earned balance',
    id: 'nh6oB9',
  },
  givenRatio: {
    defaultMessage:
      '<numerator>{currencyIcon} {spentAllowance}</numerator> <denominator>/ {totalAllowance}</denominator>',
    id: '1cywcR',
  },
});

function RewardsDetailsTrigger({
  earnedBalance,
  remainingAllowance,
  currency,
  hasAllowance,
  hasEarnedPoints,
}: {
  earnedBalance: number;
  remainingAllowance: number;
  currency: AssemblyCurrency;
  hasAllowance: boolean;
  hasEarnedPoints: boolean;
}) {
  const { formatMessage } = useIntl();

  const currencyIcon =
    currency.type === AssemblyCurrencyType.Custom ? (
      <img
        alt={currency.name}
        className="mr-0.5 h-3 w-3"
        src={currency.value}
      />
    ) : (
      mapHexCodeToEmoticon(currency.value)
    );

  return (
    <div className="flex gap-2 space-x-2 divide-x divide-gray-3 rounded px-3 py-[3px] hover:divide-gray-10 hover:bg-upgrade-3 hover:text-upgrade-10 group-aria-expanded:divide-gray-10 group-aria-expanded:bg-upgrade-3">
      {isTruthy(hasEarnedPoints) && (
        <div className="flex w-max items-center gap-1">
          <TextStyle
            variant="xs-regular"
            className="text-gray-8 group-hover:text-upgrade-10 dark:text-gray-1"
          >
            {formatMessage(messages.earned)}
          </TextStyle>
          <TextStyle
            className="flex flex-row items-center justify-center"
            variant="sm-regular"
          >
            {currencyIcon} {earnedBalance}
          </TextStyle>
        </div>
      )}
      {isTruthy(hasAllowance) && (
        <div className="flex items-center gap-1 pl-4">
          <TextStyle
            variant="xs-regular"
            className="text-gray-8 group-hover:text-upgrade-10 dark:text-gray-1"
          >
            {formatMessage(messages.allowance)}
          </TextStyle>
          <TextStyle
            variant="sm-regular"
            className="flex flex-row items-center justify-center"
          >
            {formatMessage(messages.left, { currencyIcon, remainingAllowance })}
          </TextStyle>
        </div>
      )}
    </div>
  );
}

export function RewardsDetailsContent({
  currency,
  earnedBalance,
  spentAllowance,
  totalAllowance,
  hasAllowance,
  canRedeem,
  remainingAllowance,
  className,
  hasEarnedPoints,
  onBrowseRewardsClick,
}: {
  earnedBalance: number;
  spentAllowance: number;
  totalAllowance: number;
  currency: AssemblyCurrency;
  hasAllowance: boolean;
  canRedeem: boolean;
  remainingAllowance: number;
  className?: string;
  hasEarnedPoints: boolean;
  onBrowseRewardsClick?: () => void;
}) {
  const percentage = parseInt(`${(spentAllowance / totalAllowance) * 100}`);
  const { formatMessage } = useIntl();

  const currencyIcon =
    currency.type === AssemblyCurrencyType.Custom ? (
      <img
        alt={currency.name}
        className="mr-0.5 h-3 w-3"
        src={currency.value}
      />
    ) : (
      mapHexCodeToEmoticon(currency.value)
    );

  const currentMonth = dayjs().format('MMMM');
  let progress;

  if (percentage >= 100) {
    progress = formatMessage(messages.refreshesTo, {
      currencyIcon,
      totalAllowance,
      month: dayjs().add(1, 'month').format('MMMM'),
    });
  } else {
    progress = formatMessage(messages.leftToGive, {
      currencyIcon,
      remainingAllowance,
      month: currentMonth,
    });
  }

  return (
    <div
      className={twMerge(
        'flex flex-col gap-2 rounded bg-gray-1 p-4 shadow-2xl-down md:min-w-[300px]',
        className
      )}
    >
      {isTruthy(hasAllowance) && (
        <>
          <div className="flex items-center justify-between">
            <TextStyle variant="base-regular">
              {formatMessage(messages.givenIn, { month: currentMonth })}
            </TextStyle>
            <div className="flex items-center justify-between">
              {formatMessage(messages.givenRatio, {
                currencyIcon,
                spentAllowance,
                totalAllowance,
                numerator(node) {
                  return (
                    <TextStyle
                      className="flex flex-row items-center"
                      variant="xs-regular"
                    >
                      {node}
                    </TextStyle>
                  );
                },
                denominator(node) {
                  return (
                    <TextStyle variant="xs-regular" className="text-gray-7">
                      {node}
                    </TextStyle>
                  );
                },
              })}
              <Tooltip
                side="left"
                align="start"
                contentClassName="bg-gray-10 text-gray-1 leading-4"
                tooltipText={formatMessage(messages.allowanceTooltip, {
                  totalAllowance,
                  currency: currency.pluralName,
                })}
              >
                <InformationCircleIcon className="ml-1 h-3 w-3 text-gray-7" />
              </Tooltip>
            </div>
          </div>

          <ProgressBar
            progress={percentage}
            aria-describedby="rewards-details-progress"
            indicatorBgColor={percentage >= 100 ? 'bg-error-6' : undefined}
          />
          <TextStyle
            id="rewards-details-progress"
            variant="xs-regular"
            className="flex flex-row items-center text-gray-7"
          >
            {progress}
          </TextStyle>
        </>
      )}
      {isTruthy(hasAllowance && hasEarnedPoints) && <HorizontalRule />}
      {isTruthy(hasEarnedPoints) && (
        <div className="flex justify-between">
          <TextStyle variant="base-regular">
            {formatMessage(messages.yourEarnedBalance)}
          </TextStyle>
          <div className="flex items-center">
            <TextStyle
              className="flex flex-row items-center"
              variant="xs-regular"
            >
              {currencyIcon} {earnedBalance}
            </TextStyle>
            <Tooltip
              side="left"
              contentClassName="bg-gray-10 text-gray-1"
              tooltipText={formatMessage(messages.earnedBalanceTooltip)}
            >
              <InformationCircleIcon className="ml-1 h-3 w-3 text-gray-7" />
            </Tooltip>
          </div>
        </div>
      )}
      {isTruthy(onBrowseRewardsClick) &&
        isTruthy(canRedeem && hasEarnedPoints) && (
          <Button
            onClick={() => onBrowseRewardsClick()}
            className="mt-2 h-8 w-full"
          >
            {formatMessage(messages.browseRewards)}
          </Button>
        )}
    </div>
  );
}

export type RewardsDetailsProps = {
  className?: string;
  earnedBalance: number;
  spentAllowance: number;
  totalAllowance: number;
  currency: AssemblyCurrency;
  isRedeemable: boolean;
  hasAllowance: boolean;
  remainingAllowance: number;
  hasEarnedPoints: boolean;
  onBrowseRewardsClick?: () => void;
  onShowDetailsClick?: () => void;
};

export function RewardsDetails({
  className,
  earnedBalance,
  spentAllowance,
  totalAllowance,
  currency,
  isRedeemable,
  hasAllowance,
  remainingAllowance,
  hasEarnedPoints,
  onBrowseRewardsClick,
  onShowDetailsClick,
}: RewardsDetailsProps) {
  const [showDetails, setShowDetails] = useState(false);
  const handleShowDetails = () => {
    if (!showDetails) {
      onShowDetailsClick?.();
    }
    setShowDetails(!showDetails);
  };

  return (
    <DropdownRoot onOpenChange={handleShowDetails} open={showDetails}>
      <DropdownTrigger asChild>
        <button
          className={twMerge(
            'group relative inline-flex text-left focus-visible:outline-none',
            className
          )}
          onClick={() => setShowDetails(true)}
        >
          <RewardsDetailsTrigger
            earnedBalance={earnedBalance}
            remainingAllowance={remainingAllowance}
            currency={currency}
            hasAllowance={hasAllowance}
            hasEarnedPoints={hasEarnedPoints}
          />
        </button>
      </DropdownTrigger>
      <DropdownContent
        align="end"
        className="radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down z-99"
        sideOffset={5}
      >
        <RewardsDetailsContent
          earnedBalance={earnedBalance}
          spentAllowance={spentAllowance}
          totalAllowance={totalAllowance}
          currency={currency}
          hasAllowance={hasAllowance}
          canRedeem={isRedeemable}
          remainingAllowance={remainingAllowance}
          hasEarnedPoints={hasEarnedPoints}
          onBrowseRewardsClick={() => {
            setShowDetails(false);
            onBrowseRewardsClick?.();
          }}
        />
      </DropdownContent>
    </DropdownRoot>
  );
}
