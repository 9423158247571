import { motion } from 'framer-motion';
import type { PageViewport, PDFDocumentProxy } from 'pdfjs-dist';
import * as pdfjs from 'pdfjs-dist';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { twMerge } from 'tailwind-merge';

import { LoadingSpinner } from '../../../../DesignSystem/Feedback/Icons/LoadingSpinner';
import { useDeviceInfo } from '../../../hooks/useDeviceInfo';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

type RenderContextType = {
  canvasContext: CanvasRenderingContext2D;
  viewport: PageViewport;
};

export const PDFPreviewer = ({ src }: { src: string }) => {
  const [pdf, setPdf] = useState<PDFDocumentProxy | null>(null);
  const [numPages, setNumPages] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);

  const [pageRendering, setPageRendering] = useState(false);
  const [pageNumPending, setPageNumPending] = useState<number | null>(null);

  const [thumbnailRendering, setThumbnailRendering] = useState(false);
  const [thumbnailNumPending, setThumbnailNumPending] = useState<number | null>(
    null
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const thumbnailContainerRef = useRef<HTMLDivElement>(null);
  const canvasRefs = useRef<HTMLCanvasElement[]>([]);
  const thumbnailCanvasRefs = useRef<HTMLCanvasElement[]>([]);

  const isMobile = useDeviceInfo().deviceType === 'mobile';

  const fetchPdf = useCallback(() => {
    const loadingTask = pdfjs.getDocument(src);
    // TODO : Handle error
    loadingTask.promise.then((pdfDoc) => {
      setPdf(pdfDoc);
      setNumPages(pdfDoc.numPages);
    });
  }, [src]);

  const renderPage = useCallback(
    (pageNum: number) => {
      if (!pdf) return;
      if (pageRendering) {
        setPageNumPending(pageNum);
      } else {
        setPageRendering(true);
        pdf.getPage(pageNum).then((page) => {
          const viewport = page.getViewport({ scale: 1 });
          const canvas = canvasRefs.current[pageNum - 1];
          const context = canvas.getContext('2d');

          canvas.width = containerRef.current?.clientWidth || 0;
          canvas.height = (viewport.height / viewport.width) * canvas.width;

          context?.clearRect(0, 0, canvas.width, canvas.height);

          const scale = Math.min(
            canvas.width / viewport.width,
            canvas.height / viewport.height
          );

          const renderContext = {
            canvasContext: context,
            viewport: page.getViewport({ scale }),
          };

          page.render(renderContext as RenderContextType).promise.then(() => {
            setPageRendering(false);
            if (pageNumPending !== null) {
              renderPage(pageNumPending);
              setPageNumPending(null);
            }
          });
        });
      }
    },
    // this is intentional
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pdf]
  );

  const renderThumbnail = useCallback(
    (pageNum: number) => {
      if (!pdf) return;
      if (thumbnailRendering) {
        setThumbnailNumPending(pageNum);
      } else {
        setThumbnailRendering(true);
        pdf.getPage(pageNum).then((page) => {
          const viewport = page.getViewport({ scale: 1 });
          const canvas = thumbnailCanvasRefs.current[pageNum - 1];
          const context = canvas.getContext('2d');
          canvas.width = thumbnailContainerRef.current?.clientWidth || 0;
          canvas.height = (viewport.height / viewport.width) * canvas.width;

          const scale = Math.min(
            canvas.width / viewport.width,
            canvas.height / viewport.height
          );

          context?.clearRect(0, 0, canvas.width, canvas.height);

          const renderContext = {
            canvasContext: context,
            viewport: page.getViewport({ scale }),
          };
          page.render(renderContext as RenderContextType).promise.then(() => {
            setThumbnailRendering(false);
            if (thumbnailNumPending !== null) {
              renderThumbnail(thumbnailNumPending);
              setThumbnailNumPending(null);
            }
          });
        });
      }
    },
    // this is intentional
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pdf]
  );

  const handleThumbnailClick = (pageNum: number) => {
    if (canvasRefs.current.length) {
      const canvas = canvasRefs.current[pageNum - 1];
      canvas.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  const nextPage = () => {
    if (selectedPage < numPages) {
      setSelectedPage(selectedPage + 1);
      handleThumbnailClick(selectedPage + 1);
    }
  };

  const prevPage = () => {
    if (selectedPage > 1) {
      setSelectedPage(selectedPage - 1);
      handleThumbnailClick(selectedPage - 1);
    }
  };

  useHotkeys('down', nextPage);
  useHotkeys('up', prevPage);

  useEffect(() => {
    fetchPdf();
  }, [fetchPdf]);

  useEffect(() => {
    for (let i = 1; i <= numPages; i++) {
      renderPage(i);
      renderThumbnail(i);
    }
  }, [renderPage, renderThumbnail, numPages]);

  if (!pdf) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="grid h-full w-full grid-cols-7 gap-2 bg-gray-4 text-center">
      {!isMobile ? (
        <div className="col-span-1 max-h-full overflow-y-scroll border border-l border-gray-5 p-3 px-6">
          <div ref={thumbnailContainerRef}>
            {Array.from({ length: numPages }, (_, i) => (
              <motion.div
                key={i}
                className="flex cursor-pointer flex-col items-center py-1"
              >
                <canvas
                  className="shadow-md-down"
                  ref={(el) => {
                    if (el) {
                      thumbnailCanvasRefs.current[i] = el;
                    }
                  }}
                  onClick={() => {
                    setSelectedPage(i + 1);
                    handleThumbnailClick(i + 1);
                  }}
                />
                <div className="p-1">{i + 1}</div>
              </motion.div>
            ))}
          </div>
        </div>
      ) : null}
      <div
        className={twMerge(
          'flex max-h-full w-full flex-1 flex-col items-center overflow-scroll p-2',
          isMobile ? 'col-span-7' : 'col-span-6 pl-3 pr-16'
        )}
      >
        <div ref={containerRef} className="w-full">
          {Array.from({ length: numPages }, (_, i) => (
            <motion.div key={i} className="py-2">
              <canvas
                className="shadow-lg-down"
                ref={(el) => {
                  if (el) {
                    canvasRefs.current[i] = el;
                  }
                }}
              />
              <div className="p-2">{i + 1}</div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};
