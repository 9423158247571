import type { UseQueryOptions } from '@tanstack/react-query';

import { APIEndpoints } from '../APIEndpoints';
import { assemblyAPI } from '../assemblyAPI';
import type { MemberAPIResponse } from '../types/domain';
import {
  getCanUserCreateFlows,
  getCanUserInviteNewUsers,
  getCanUserPostAnnouncements,
  getHasProfanityTurnedOn,
  getWorkspaceSlugPath,
} from '../workspace';

const updatedUserDetails = (data: MemberAPIResponse) => {
  return {
    member: {
      ...data.member,
      profile: {
        ...data.member.profile,
        fullName:
          `${data.member.profile.firstName} ${data.member.profile.lastName}`.trim(),
      },
      permissions: {
        canPostAnnouncements: getCanUserPostAnnouncements(data),
        canCreateFlow: getCanUserCreateFlows(data),
        canInviteNewUsers: getCanUserInviteNewUsers(data),
        profanitySetting: getHasProfanityTurnedOn(data),
      },
    },
    assembly: {
      ...data.assembly,
      workspaceSlugPath: getWorkspaceSlugPath(data),
    },
  };
};

export function getUserDetailsQuery(
  options?: UseQueryOptions<MemberAPIResponse>
) {
  return {
    ...options,
    queryKey: ['userDetails'],
    queryFn: fetchUserDetails,
  };
}

export async function fetchUserDetails() {
  const response = await assemblyAPI.get<MemberAPIResponse>(
    APIEndpoints.userDetails
  );
  return updatedUserDetails(response.data);
}
