import { config, userAuthStore } from '@assembly-web/services';
import type { Options } from 'pusher-js';
import Pusher from 'pusher-js';
import type Ajax from 'pusher-js/types/src/core/http/ajax';
import { createContext, type ReactNode, useEffect, useState } from 'react';

import { ChannelsProvider } from './ChannelsProvider';

type PusherProviderProps = {
  clientKey: string;
  children: ReactNode;
} & Options;

export type PusherContextValue = Pusher | null;

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    PusherClientContext?: PusherContextValue;
  }
}

const isDebugModeOn =
  import.meta.env.MODE === 'development' &&
  Boolean(import.meta.env.VITE_PUSHER_DEBUG);

export const PusherContext = createContext<PusherContextValue>(null);

export function PusherProvider(props: PusherProviderProps) {
  const { clientKey, children, ...options } = props;

  const [pusher, setPusher] = useState<PusherContextValue>(null);

  useEffect(() => {
    Pusher.Runtime.createXHR = () => {
      const xhr = new XMLHttpRequest();

      const mobileAuthToken = userAuthStore.getState().jwtToken;

      if (config.isMobileDevMode && mobileAuthToken) {
        const originalOpen = xhr.open;

        xhr.open = function (
          method: string,
          url: string,
          async?: boolean,
          user?: string | null,
          password?: string | null
        ): ReturnType<typeof XMLHttpRequest.prototype.open> {
          originalOpen.call(this, method, url, Boolean(async), user, password);
          this.setRequestHeader('Authorization', `JWT ${mobileAuthToken}`);
        };
      } else {
        xhr.withCredentials = true;
      }

      return xhr as Ajax;
    };

    if (isDebugModeOn) {
      Pusher.log = (message) => {
        console.log('✨ ', message);
      };
    }
  }, []);

  useEffect(() => {
    if (!window.PusherClientContext && !pusher) {
      window.PusherClientContext = new Pusher(clientKey, {
        ...options,
      });

      setPusher(window.PusherClientContext);
    }

    return () => {
      pusher?.disconnect();
      delete window.PusherClientContext;
    };
  }, [clientKey, options, pusher]);

  return (
    <PusherContext.Provider value={pusher}>
      <ChannelsProvider>{children}</ChannelsProvider>
    </PusherContext.Provider>
  );
}
