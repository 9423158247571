import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  Content,
  Item,
  Menu,
  Portal,
  Root as MenuRoot,
  Trigger,
} from '@radix-ui/react-menubar';
import type { PopoverContentProps } from '@radix-ui/react-popover';
import { Root, ToggleGroup, ToggleItem } from '@radix-ui/react-toolbar';
import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../Feedback/TextStyle';

export type DropdownOption = {
  value: string;
  label: string;
};

export type DropdownMenuAlignmentOptions = {
  sideOffset?: number;
  alignOffset?: number;
  align?: PopoverContentProps['align'];
  side?: PopoverContentProps['side'];
};

type DropdownMenuProps = {
  value: string;
  options: DropdownOption[];
  onSelected: (value: DropdownOption) => void;
  connectedLeft?: ReactNode;
  variant: 'default' | 'bordered';
  disabled?: boolean;
  className?: string;
  triggerClassName?: string;
  contentClassName?: string;
  isPortalDisabled?: boolean;
  dropdownAlignmentOptions?: DropdownMenuAlignmentOptions;
  placeholder?: string;
  labelRenderer?: (label: string) => ReactNode;
  optionRenderer?: (option: DropdownOption) => ReactNode;
};

export function DropdownMenu(props: DropdownMenuProps) {
  const {
    options,
    value: selectedOption,
    onSelected,
    connectedLeft,
    variant = 'default',
    disabled,
    className,
    triggerClassName,
    contentClassName,
    isPortalDisabled = false,
    dropdownAlignmentOptions,
    placeholder = '',
    labelRenderer,
    optionRenderer,
  } = props;
  const [, setIsOpen] = useState(false);
  const toggleItemRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    // TODO: @arunselvakumar - Do we still need it? 🤔
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (
          toggleItemRef.current &&
          mutation.type === 'attributes' &&
          mutation.attributeName === 'data-state'
        ) {
          setIsOpen(
            toggleItemRef.current.getAttribute('data-state') === 'open'
          );
        }
      }
    });

    if (toggleItemRef.current) {
      observer.observe(toggleItemRef.current, { attributes: true });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Root
      className={twMerge(
        'flex w-full bg-gray-1',
        disabled && 'bg-gray-2',
        className
      )}
      orientation="horizontal"
    >
      <ToggleGroup className={twMerge('flex', triggerClassName)} type="single">
        <MenuRoot className={twMerge('flex', triggerClassName)}>
          <Menu>
            <Trigger
              className={twMerge(
                'group relative inline-block text-left',
                triggerClassName,
                disabled && 'cursor-not-allowed'
              )}
              disabled={disabled}
              ref={toggleItemRef}
            >
              <ToggleItem
                asChild={true}
                value={selectedOption}
                title={
                  options.find((x) => x.value === selectedOption)?.label ??
                  options[0]?.label
                }
                className={twMerge(
                  'flex w-full items-center justify-between rounded hover:bg-gray-3',
                  triggerClassName
                )}
              >
                <div
                  className={twMerge(
                    'flex items-center gap-2 px-2 align-middle',
                    variant === 'bordered' && 'border border-gray-5 px-3 py-1'
                  )}
                >
                  <div className="flex items-center">
                    {connectedLeft}

                    {labelRenderer ? (
                      labelRenderer(
                        options.find((x) => x.value === selectedOption)
                          ?.label ?? placeholder
                      )
                    ) : (
                      <TextStyle
                        as="p"
                        variant="sm-regular"
                        className="px-2 py-1 text-gray-8"
                      >
                        {options.find((x) => x.value === selectedOption)
                          ?.label ?? options[0]?.label}
                      </TextStyle>
                    )}
                  </div>
                  <ChevronDownIcon className="h-4 w-4 text-gray-9 group-aria-expanded:rotate-180" />
                </div>
              </ToggleItem>
            </Trigger>
            {isPortalDisabled ? (
              <DropdownMenuContent
                options={options}
                onSelected={onSelected}
                optionRenderer={optionRenderer}
                selectedOption={selectedOption}
                contentClassName={contentClassName}
                dropdownAlignmentOptions={dropdownAlignmentOptions}
              />
            ) : (
              <Portal>
                <DropdownMenuContent
                  options={options}
                  onSelected={onSelected}
                  selectedOption={selectedOption}
                  optionRenderer={optionRenderer}
                  contentClassName={contentClassName}
                  dropdownAlignmentOptions={dropdownAlignmentOptions}
                />
              </Portal>
            )}
          </Menu>
        </MenuRoot>
      </ToggleGroup>
    </Root>
  );
}

export function DropdownMenuContent({
  options,
  selectedOption,
  onSelected,
  optionRenderer,
  contentClassName,
  dropdownAlignmentOptions,
}: {
  selectedOption: string;
  options: DropdownMenuProps['options'];
  onSelected: DropdownMenuProps['onSelected'];
  optionRenderer?: (option: DropdownOption) => ReactNode;
  contentClassName?: DropdownMenuProps['contentClassName'];
  dropdownAlignmentOptions?: DropdownMenuAlignmentOptions;
}) {
  return (
    <Content
      className={twMerge(
        'space-b absolute left-0 z-10 max-h-[32rem] origin-top transform overflow-auto rounded-md border border-gray-5 bg-gray-1 py-1 shadow-lg-down ring-opacity-5 focus:outline-none',
        contentClassName
      )}
      align="start"
      sideOffset={5}
      alignOffset={-3}
      {...(dropdownAlignmentOptions ? dropdownAlignmentOptions : {})}
    >
      {options.map((option) => (
        <Item
          data-testid="dropdown-menu-item"
          onClick={() => {
            onSelected(option);
          }}
          key={option.value}
          className="cursor-pointer focus:bg-primary-2 focus:outline-none"
        >
          {optionRenderer ? (
            optionRenderer(option)
          ) : (
            <div className="bg-gray-0 flex w-full cursor-pointer items-center justify-between p-[9px] pr-2 text-sm text-gray-8 hover:bg-gray-3 focus:outline-none">
              <div
                className={twMerge(
                  'ml-2 line-clamp-1 text-left',
                  selectedOption === option.value
                    ? 'font-medium'
                    : 'font-normal'
                )}
              >
                {option.label}
              </div>
              {selectedOption === option.value && (
                <CheckIcon className="mr-2 h-4 w-4 text-primary-5" />
              )}
            </div>
          )}
        </Item>
      ))}
    </Content>
  );
}
