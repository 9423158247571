import type {
  AriaAttributes,
  DetailedHTMLProps,
  HTMLAttributes,
  ReactNode,
} from 'react';

import { classNames } from '../Utils/classNames';

export type RadioProps = DetailedHTMLProps<
  HTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  AriaAttributes & {
    fullWidth?: boolean;
    label: string;
    helpText?: string;
  };

export type RadioOptionProps = {
  name: string;
  isDefaultOption?: boolean;
  value: string;
  className?: string;
  children: ReactNode;
  disabled?: boolean;
  inputClassName?: string;
};

export function RadioOption(props: RadioOptionProps) {
  return (
    <div key={props.value} className="flex items-center">
      <input
        id={props.value}
        name={props.name}
        type="radio"
        defaultChecked={props.isDefaultOption}
        defaultValue={props.value}
        disabled={props.disabled}
        className={classNames(
          'h-4 w-4 border-gray-6 before:mx-[25%] before:my-[25%] before:block before:h-[45%] before:w-[45%] before:rounded-[50%] before:content-[""] checked:bg-none before:checked:bg-primary-1 dark:bg-gray-9 dark:checked:bg-primary-3 dark:checked:ring-primary-3 dark:checked:ring-offset-gray-9 dark:before:checked:bg-gray-9',
          {
            'cursor-pointer text-primary-6 focus:ring-primary-6':
              !props.disabled,
            'cursor-not-allowed text-gray-6 focus:ring-gray-6 dark:checked:bg-gray-7':
              props.disabled,
          },
          props.inputClassName
        )}
      />
      <label
        htmlFor={props.value}
        className={classNames(
          'ml-3 block text-sm',
          props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        )}
      >
        {props.children}
      </label>
    </div>
  );
}

export function Radio(props: RadioProps) {
  return (
    <div>
      <label className="text-base font-medium">{props.label}</label>
      {Boolean(props.helpText) && (
        <p className="text-sm leading-5 text-neutral-secondary">
          {props.helpText}
        </p>
      )}
      <fieldset className="mt-4">
        <legend className="sr-only">{props.label}</legend>
        <div className="space-y-4">{props.children}</div>
      </fieldset>
    </div>
  );
}
