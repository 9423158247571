import type { FolderColor } from '@assembly-web/services';
import { cva } from 'class-variance-authority';
import type { ChangeEvent } from 'react';

const colorRadioOptionStyles = cva(
  'mr-4 h-4 w-4 rounded-lg checked:border checked:before:m-[calc(50%-6px/2)] checked:before:block checked:before:h-1.5 checked:before:w-1.5 checked:before:rounded-lg checked:hover:border-[1px] focus:ring-0 focus:ring-offset-0',
  {
    variants: {
      colorName: {
        gray: 'bg-gray-4 border-gray-7 checked:border-gray-9 checked:text-gray-4 checked:before:bg-gray-9 checked:hover:border-gray-9 checked:focus:border-gray-9',
        skyBlue:
          'bg-blue-2 border-blue-5 checked:border-blue-9 checked:text-blue-2 checked:before:bg-blue-9 checked:hover:border-blue-9 checked:focus:border-blue-9',

        cyan: 'bg-cyan-2 border-cyan-5 checked:border-cyan-9 checked:text-cyan-2 checked:before:bg-cyan-9 checked:hover:border-cyan-9 checked:focus:border-cyan-9',

        limeGreen:
          'bg-success-2 border-success-5 checked:border-success-9 checked:text-success-2 checked:before:bg-success-9 checked:hover:border-success-9 checked:focus:border-success-9',

        gold: 'bg-upgrade-2 border-upgrade-5 checked:border-upgrade-9 checked:text-upgrade-2 checked:before:bg-upgrade-9 checked:hover:border-upgrade-9 checked:focus:border-upgrade-9',

        orange:
          'bg-brand-2 border-brand-5 checked:border-brand-9 checked:text-brand-2 checked:before:bg-brand-9 checked:hover:border-brand-9 checked:focus:border-brand-9',

        red: 'bg-error-2 border-error-5 checked:border-error-9 checked:text-error-2 checked:before:bg-error-9 checked:hover:border-error-9 checked:focus:border-error-9',

        pink: 'bg-magenta-2 border-magenta-5 checked:border-magenta-9 checked:text-magenta-2 checked:before:bg-magenta-9 checked:hover:border-magenta-9 checked:focus:border-magenta-9',

        purple:
          'bg-purple-2 border-purple-5 checked:border-purple-9 checked:text-purple-2 checked:before:bg-purple-9 checked:hover:border-purple-9 checked:focus:border-purple-9',

        lightBlue:
          'bg-primary-2 border-primary-5 checked:border-primary-8 checked:text-primary-1 checked:before:bg-primary-8 checked:hover:border-primary-8 checked:focus:border-primary-8',
      } satisfies Record<FolderColor, string>,
    },
  }
);

type ColorRadioOptionProps = {
  checked: boolean;
  colorName: FolderColor;
  onChange: (ev: ChangeEvent<HTMLInputElement>) => void;
};

export function ColorRadioOption({
  checked,
  colorName,
  onChange,
}: ColorRadioOptionProps) {
  return (
    <input
      aria-label={colorName}
      checked={checked}
      className={colorRadioOptionStyles({ colorName })}
      name="colorRadioOption"
      onChange={onChange}
      type="radio"
      value={colorName}
    />
  );
}
