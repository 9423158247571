import { useIntl } from 'react-intl';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';
import { messages } from './messages';
import { RepliesLoader } from './RepliesLoader';

export function FetchMore({
  hasMore,
  fetchMore,
  isFetching,
  fetchedReplyCount,
  totalReplyCount,
  label,
  hideCount = false,
}: {
  hasMore: boolean;
  isFetching: boolean;
  fetchMore: () => void;
  fetchedReplyCount: number;
  totalReplyCount: number;
  label: string;
  hideCount?: boolean;
}) {
  const { formatMessage } = useIntl();

  if (isFetching) {
    return <RepliesLoader />;
  }

  if (hasMore) {
    return (
      <div className="flex w-full justify-between py-4 align-middle">
        <button
          onClick={fetchMore}
          className="ml-1 text-sm font-medium text-gray-8 underline"
        >
          {label}
        </button>
        {!hideCount && (
          <TextStyle className="text-gray-8" variant="sm-regular">
            {formatMessage(messages.replyCountStatus, {
              fetchedReplyCount,
              totalReplyCount,
            })}
          </TextStyle>
        )}
      </div>
    );
  }
}
