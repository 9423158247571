import { XCircleIcon } from '@heroicons/react/24/outline';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { TextNode } from 'lexical';
import { defineMessages, useIntl } from 'react-intl';

import type { BaseChipNode } from './BaseNode';

const messages = defineMessages({
  remove: {
    defaultMessage: 'Remove {name}',
    id: 'T0e6Lh',
  },
});

export function RemoveButton({
  name,
  node,
}: {
  name: string;
  node: BaseChipNode;
}) {
  const { formatMessage } = useIntl();
  const [editor] = useLexicalComposerContext();

  return (
    <button
      className="h-6 w-6 flex-shrink-0 text-gray-8"
      aria-label={formatMessage(messages.remove, { name })}
      onClick={() =>
        editor.update(() => {
          const nextSibling = node.getNextSibling();
          if (
            nextSibling instanceof TextNode &&
            nextSibling.getTextContent() === ' '
          ) {
            nextSibling.remove();
          }
          node.remove();
          editor.focus();
        })
      }
    >
      <XCircleIcon />
    </button>
  );
}
