import { LinkIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { defineMessages, type IntlShape } from 'react-intl';

import type { ToolbarItem } from '../../../DesignSystem/Feedback/Toolbar/Toolbar';
import type { ConversationCardProps } from './ConversationCard';

const messages = defineMessages({
  copyReply: {
    defaultMessage: 'Copy reply link',
    id: 'DiL7pw',
  },
  editReply: {
    defaultMessage: 'Edit reply',
    id: 'tNcHxJ',
  },
  deleteReply: {
    defaultMessage: 'Delete reply',
    id: 'T59CCg',
  },
});

export enum RepliesToolbarItemId {
  OpenEmojiPicker = 'open-emoji-picker',
  CopyReplyLink = 'copy-reply-link',
  EditReply = 'edit-reply',
  DeleteReply = 'delete-reply',
}

export type RepliesSecondaryToolbarItemId = Exclude<
  RepliesToolbarItemId,
  RepliesToolbarItemId.OpenEmojiPicker
>;

export function getReplyCardToolbarItems({
  formatMessage,
  canEditReply,
  canDeleteReply,
  hasPoints,
}: {
  formatMessage: IntlShape['formatMessage'];
  canEditReply: boolean;
  canDeleteReply: boolean;
  hasPoints?: boolean;
}): ToolbarItem[] {
  return [
    {
      id: 'copy-reply-link',
      icon: LinkIcon,
      text: formatMessage(messages.copyReply),
    },
    ...(canEditReply
      ? [
          {
            id: 'edit-reply',
            icon: PencilIcon,
            text: formatMessage(messages.editReply),
          },
        ]
      : []),
    ...(canDeleteReply
      ? [
          {
            id: 'delete-reply',
            icon: TrashIcon,
            text: formatMessage(messages.deleteReply),
            isAlertStyle: true,
            hasPoints,
          },
        ]
      : []),
  ];
}

export function getConversionCardVariant({
  previousReplyMemberId,
  currentReplyMemberId,
}: {
  previousReplyMemberId: string;
  currentReplyMemberId: string;
}): ConversationCardProps['variant'] {
  if (
    previousReplyMemberId === 'Anonymous' ||
    currentReplyMemberId === 'Anonymous'
  ) {
    return 'single';
  }

  return previousReplyMemberId === currentReplyMemberId ? 'grouped' : 'single';
}
