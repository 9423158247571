import { AtSymbolIcon } from '@heroicons/react/20/solid';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $insertNodes } from 'lexical';
import { defineMessage, useIntl } from 'react-intl';

import { Tooltip } from '../../../../../DesignSystem/Feedback/Tooltip';
import { Button } from '../../../../../DesignSystem/Inputs/Button';
import { getSelectedNodes } from '../utils/getSelectedNodes';

const label = defineMessage({
  defaultMessage: 'Mention someone',
  id: 'VUJxrl',
});

type MentionButtonProps = {
  disabled?: boolean;
};

export function MentionButton({ disabled }: MentionButtonProps) {
  const { formatMessage } = useIntl();

  const [editor] = useLexicalComposerContext();

  const handleOnMentionsButtonClick = () => {
    editor.update(() => {
      $insertNodes(getSelectedNodes());
    });
  };

  return (
    <Tooltip tooltipText={formatMessage(label)}>
      <Button
        disabled={disabled}
        variation="tertiaryLite"
        className="h-10 w-10 !p-2"
        onClick={handleOnMentionsButtonClick}
        aria-label={formatMessage(label)}
      >
        <AtSymbolIcon className="h-6 w-6 text-gray-8" />
      </Button>
    </Tooltip>
  );
}
