import type { CalendarDate } from '@internationalized/date';
import type { BaseEmoji } from 'emoji-mart';

import type { supportedUploadFileTypes } from '../fileUtils';
import type { KnownErrorKeys } from '../flowBuilderUtils';
import type { PermissionType } from './criteriaTypes';
import type { MilestoneSettings } from './flowDetailsApiResponse';
import type {
  DeadlineType,
  FlowCollaborator,
  FlowFrequencyOptions,
  FlowResponseType,
  MemberState,
  MilestoneFrequencyType,
  MilestoneType,
  RemindersType,
  ResponseFrequencyType,
  TimeUnit,
} from './flowTypes';
import type { CriteriaRuleType, Operator, ShareCriteria } from './shareSheet';

type KnownErrorType = keyof typeof KnownErrorKeys;

export type ErrorEntry = {
  id: string;
  message: string;
};

type ErrorType = { message: string; showBanner?: boolean; index?: number };

export type ScheduleRule = {
  rule: string;
};

export type BasicFlowRuleAttributes = {
  required?: boolean;
  editable?: boolean;
};

export type RichText = {
  plainText: string;
  html: string;
  json: string;
};

export type ContentBlockBaseState = {
  id: string;
  title: string;
  description: string;
  blockId?: string;
  isRequired: boolean;
  isLinkedBlock: boolean;
  type: BlockType;
};

type FlowDescription = {
  text: string;
  icon?: {
    type: string;
    value: string;
  };
};

export type FlowBlockOption = {
  id: string;
  value: string;
};

export type BasicFlowBlockAttributes = {
  blockId?: string;
  title: string;
  description?: FlowDescription;
  type: unknown;
};

export type OpenEndedBlockSettingOptions = {
  mentions?: boolean;
  emojis?: boolean;
  gifs?: boolean;
  attachments?: boolean;
  tasks?: boolean;
};

export const BlockTypeMap = {
  Dropdown: 'DROPDOWN',
  Scale: 'SCALE',
  OpenEnded: 'OPEN_ENDED',
  Trophies: 'TROPHIES',
  PersonSelector: 'PERSON_SELECTOR',
  Gif: 'GIF',
  FileUpload: 'FILE_UPLOAD',
  MultiChoice: 'MULTI_CHOICE',
  GivePointsStack: 'GIVE_POINTS_STACK',
  Nps: 'NPS',
} as const;
export type BlockType = (typeof BlockTypeMap)[keyof typeof BlockTypeMap];

export const SelectableOptionMap = {
  Single: 'SINGLE',
  Multi: 'MULTI',
} as const;
export type SelectableOptionType =
  (typeof SelectableOptionMap)[keyof typeof SelectableOptionMap];

export const SelectablePeopleMap = {
  Everyone: 'EVERYONE',
  Participants: 'PARTICIPANTS',
  Viewers: 'VIEWERS',
  Custom: 'CUSTOM',
} as const;
export type SelectablePeopleTypes =
  (typeof SelectablePeopleMap)[keyof typeof SelectablePeopleMap];

export const SelectablePeopleRangeMap = {
  Exact: 'EXACT',
  Range: 'RANGE',
  Unlimited: 'UNLIMITED',
} as const;
export type SelectablePeopleRangeType =
  (typeof SelectablePeopleRangeMap)[keyof typeof SelectablePeopleRangeMap];

type CriteriaCondition = 'and' | 'or';
type CriteriaField =
  | 'slack'
  | 'everyone'
  | 'department'
  | 'email'
  | 'member'
  | 'manager'
  | 'directReport'
  | 'memberIsManager'
  | 'memberIsNotManager'
  | 'workLocation'
  | 'homeLocation'
  | 'jobTitle';
type CriteriaOperator = 'is' | 'isNot' | 'of';

export const TriggerMap = {
  Milestone: 'MILESTONE',
  OnDemand: 'ONDEMAND',
  Scheduled: 'SCHEDULED',
  NoTrigger: 'NO_TRIGGER',
} as const;
export type TriggerType = (typeof TriggerMap)[keyof typeof TriggerMap];

export type AllowedOpenEndedMediaTypes =
  | 'EMOJI'
  | 'GIF'
  | 'FILES'
  | 'FORMATTING'
  | 'MENTION'
  | 'TASKS';

export type AllowedFlowFileTypes = typeof supportedUploadFileTypes;

type CriteriaValueItem = {
  id: string;
  value: string;
  memberState?: MemberState;
};
type CriteriaValue = CriteriaValueItem[];

type CriteriaRule =
  | {
      ruleId: string;
      field: 'everyone';
      operator: 'is';
      value: [];
    }
  | {
      ruleId: string;
      field: CriteriaField;
      operator: CriteriaOperator;
      value: CriteriaValue;
    };

export type CriteriaGroup = {
  groupId: string;
  groupCondition?: CriteriaCondition;
  groupRules: CriteriaRule[];
};

export type CriteriaGroups = {
  groupsCondition: CriteriaCondition;
  groups: CriteriaGroup[];
};

export type OptionsSelectTypes = 'EXACT_NUMBER' | 'RANGE' | 'UNLIMITED_OPTIONS';

export type BaseOptionsSelectObject = {
  type: OptionsSelectTypes;
};

export type UnlimitedOptionsSelectObject = BaseOptionsSelectObject & {
  type: 'UNLIMITED_OPTIONS';
};

export type RangeOptionsSelectObject = BaseOptionsSelectObject & {
  type: 'RANGE';
  minOptions: number;
  maxOptions: number;
};

export type ExactOptionsSelectObject = BaseOptionsSelectObject & {
  type: 'EXACT_NUMBER';
  exactOptions: number;
};

export type GivePointsStackCurrencyAmount = {
  type: 'PERCENT' | 'EXACT';
  value: number;
};

export type GiveExactPointsRule = {
  limitType: 'EXACT_VALUE' | 'PERCENTAGE';
  limit: number;
};

export type NoPointsLimitRule = {
  noLimit?: boolean;
};

export type OptionsSelectObject =
  | ExactOptionsSelectObject
  | RangeOptionsSelectObject
  | UnlimitedOptionsSelectObject;

export type OptionItemProps = {
  defaultLabel?: string;
  label: string;
  value: string;
  helperText?: string;
  checked?: boolean;
  autoFocus?: boolean;
};

export type FlowRuleLimit = {
  exact?: number;
  noLimit?: boolean;
  range?: {
    min: number;
    max: number;
  };
};

export type ScaleLabels = {
  low?: string;
  middle?: string;
  high?: string;
};

export type PersonSelectorBlockRules = BasicFlowRuleAttributes & {
  select?: SelectablePeopleTypes;
  criteria?: { custom?: ShareCriteria };
  limit?: FlowRuleLimit;
};

export type PersonSelectorBlockRulesPayload = Omit<
  PersonSelectorBlockRules,
  'criteria'
> & {
  criteria?: {
    custom: {
      exclude: [];
      include: (
        | {
            field: CriteriaRuleType.Everyone;
            value: boolean;
            perm: PermissionType.Custom;
            operator: Operator.Is;
          }
        | {
            field:
              | CriteriaRuleType.Member
              | CriteriaRuleType.Email
              | CriteriaRuleType.Department
              | CriteriaRuleType.JobTitle
              | CriteriaRuleType.WorkLocation
              | CriteriaRuleType.HomeLocation
              | CriteriaRuleType.ManagerStatus;
            values: {
              operator: Operator;
              perm: PermissionType.Custom;
              value: string;
            }[];
          }
      )[];
    };
  };
};

export type DropdownBlockRules = BasicFlowRuleAttributes & {
  limit?: FlowRuleLimit;
};

export type MultiChoiceBlockRules = BasicFlowRuleAttributes & {
  limit?: FlowRuleLimit;
  allowOther?: boolean;
};

export type OpenEndedBlockRules = BasicFlowRuleAttributes & {
  min?: number;
  max?: number;
  allowedMedia?: AllowedOpenEndedMediaTypes[];
  notifyMentionedIndividual?: boolean;
  fileType?: AllowedFlowFileTypes;
};

export type GivePointsStackRules = BasicFlowRuleAttributes & {
  points?: GiveExactPointsRule | NoPointsLimitRule;
  hidePoints?: boolean;
};

export type ContentPersonSelectorBlockState = ContentBlockBaseState & {
  type: 'PERSON_SELECTOR';
  optionType: SelectableOptionType;
  optionSelectObject: OptionsSelectObject;
  selectedBlockParticipants: SelectablePeopleTypes;
  criteriaGroups?: ShareCriteria | undefined;
  customPersonSelectorCount?: number;
  initialized?: boolean;
};

export type ContentOpenEndedBlockState = ContentBlockBaseState & {
  type: 'OPEN_ENDED';
  openEndedOptions: OpenEndedBlockSettingOptions;
  maximumCharacters?: number;
  minimumCharacters?: number;
};

export type ContentMultiChoiceBlockState = ContentBlockBaseState & {
  type: 'MULTI_CHOICE';
  choices: string[];
  maximumSelectableOptions: number;
  options: OptionItemProps[];
  optionType: SelectableOptionType;
  optionSelectObject: OptionsSelectObject;
};

export type ContentDropdownBlockState = ContentBlockBaseState & {
  type: 'DROPDOWN';
  choices: string[];
  maximumSelectableOptions: number;
  options: OptionItemProps[];
  optionType: SelectableOptionType;
  optionSelectObject: OptionsSelectObject;
};

export type ContentScaleBlockState = ContentBlockBaseState & {
  type: 'SCALE';
  lowLabel: string;
  middleLabel: string;
  highLabel: string;
  minimumRange: number;
  maximumRange: number;
};

export type ContentNPSBlockState = ContentBlockBaseState & {
  type: 'NPS';
};

export type ContentGifBlockState = ContentBlockBaseState & {
  type: 'GIF';
};

export type ContentFileUploadBlockState = ContentBlockBaseState & {
  type: 'FILE_UPLOAD';
};

export type ContentGivePointsBlockState = ContentBlockBaseState & {
  type: 'GIVE_POINTS_STACK';
  hideCurrencyValues: boolean;
  limitAmountDetails?: GivePointsStackCurrencyAmount;
};

export type PersonSelectorFlowBlock = BasicFlowBlockAttributes & {
  type: 'PERSON_SELECTOR';
  select_type: 'SINGLE_PERSON' | 'MULTI_PERSON';
  key?: string;
  rules?: PersonSelectorBlockRules;
};

export type PersonSelectorFlowBlockPayload = Omit<
  PersonSelectorFlowBlock,
  'rules'
> & { rules: PersonSelectorBlockRulesPayload };

export type OpenEndedFlowBlock = BasicFlowBlockAttributes & {
  type: 'OPEN_ENDED';
  rules?: OpenEndedBlockRules;
};

export type DropdownFlowBlock = BasicFlowBlockAttributes & {
  type: 'DROPDOWN';
  options: FlowBlockOption[];
  rules?: DropdownBlockRules;
};

export type MultiChoiceFlowBlock = BasicFlowBlockAttributes & {
  type: 'MULTI_CHOICE';
  optionType: 'MULTI' | 'SINGLE';
  options: FlowBlockOption[];
  rules?: MultiChoiceBlockRules;
};

export type ScaleFlowBlock = BasicFlowBlockAttributes & {
  type: 'SCALE';
  min: number;
  max: number;
  labels?: ScaleLabels;
  rules?: BasicFlowRuleAttributes;
  isNPSEnabled?: boolean;
};

export type NPSFlowBlock = BasicFlowBlockAttributes & {
  type: 'NPS';
  min: number;
  max: number;
  labels?: ScaleLabels;
  rules?: BasicFlowRuleAttributes;
  isNPSEnabled?: boolean;
};

export type GifUploadFlowBlock = BasicFlowBlockAttributes & {
  type: 'GIF';
  rules?: BasicFlowRuleAttributes;
};

export type FileUploadFlowBlock = BasicFlowBlockAttributes & {
  type: 'FILE_UPLOAD';
  rules?: BasicFlowRuleAttributes;
};

export type GiveTrophiesStackFlowBlock = BasicFlowBlockAttributes & {
  type: 'GIVE_POINTS_STACK';
  dependentKeys: string[];
  rules: GivePointsStackRules;
};

export type TriggerBuilderBlockData = {
  triggerType: TriggerType | undefined;
  errors?: ErrorEntry[] | null;
  endTimeInMinutes: number;
  shortcut: boolean;
  selectedCustomRecurrenceTypes: unknown | undefined;
  schedule: ScheduleRule | undefined;
  isSchedulerTouched?: boolean;
};

export type ContentBlockState =
  | ContentScaleBlockState
  | ContentPersonSelectorBlockState
  | ContentOpenEndedBlockState
  | ContentMultiChoiceBlockState
  | ContentDropdownBlockState
  | ContentNPSBlockState
  | ContentGifBlockState
  | ContentFileUploadBlockState
  | ContentGivePointsBlockState;

export type ContentBuilderBlockData = {
  contentBlocks: ContentBlockState[];
  errors: ErrorEntry[] | null;
};

export type VisibilityBuilderBlockData = {
  everyone: boolean | undefined;
  onlyParticipants: boolean | undefined;
  onlyOwnersAndCollaborators: boolean | undefined;
  custom: boolean | undefined;
  skippedMembers?: string[] | undefined;
  criteriaGroups?: CriteriaGroups | undefined;
  type: string;
  errors?: ErrorEntry[] | null;
};

export type ActionBuilderBlockData = {
  actionType: 'SEND_FORM';
  errors?: ErrorEntry[] | null;
};

export type ParticipantsBuilderBlockData = {
  participantsCriteria: CriteriaGroups | undefined;
  errors?: ErrorEntry[] | null;
};

export type BlockDataType = {
  ACTION: ActionBuilderBlockData | null;
  TRIGGER: TriggerBuilderBlockData;
  PARTICIPANTS: ParticipantsBuilderBlockData | null;
  VISIBILITY: VisibilityBuilderBlockData;
  CONTENT: ContentBuilderBlockData;
};

export type FlowBuilderState = {
  flowId: string;
  blockData: BlockDataType;
  isFlowDataChangeConfirmed: boolean;
  showDataChangeConfirm: boolean;
  description: string | undefined;
  emoji: BaseEmoji;
  owner: FlowCollaborator[];
  collaborators: FlowCollaborator[];
  flowName: string | undefined;
  templateId: string | undefined;
  templateName: string | undefined;
  inEditMode: boolean;
  currentSchedule: ScheduleRule | undefined;
  deadlineType: DeadlineType;
  remindersType: RemindersType;
  flowResponseType: FlowResponseType;
  responseFrequencyType: ResponseFrequencyType;
  responseTimeValue: number;
  responseTimeUnit: TimeUnit;
  numberOfResponses: number;
  errors:
    | ({
        [T in KnownErrorType]?: ErrorType;
      } & Record<string, ErrorType>)
    | null;
  canValidate: boolean;
  dueDate: CalendarDate;
  dueTime: string | null;
  endDate: CalendarDate | null;
  remindersStartDate: CalendarDate | null;
  repeatFrequency: FlowFrequencyOptions | null;
  responseFrequencyTimeZone: string | null;
  remindersCount: number | null;
  remindersFrequency?: FlowFrequencyOptions;
  remindersDueTime?: string;
  remindersTimeZone?: string | null;
  reminderSchedule: string | null;
  newUsersToInvite: string[];
  allowMemberLevelOccurrence: boolean;
  selectedMilestone: MilestoneType;
  selectedMilestoneFrequency: MilestoneFrequencyType;
  numberOfResponseForMilestone: number | null;
  skipWeekend?: boolean | null;
};

export type FlowBlockContent =
  | ScaleFlowBlock
  | PersonSelectorFlowBlock
  | OpenEndedFlowBlock
  | DropdownFlowBlock
  | MultiChoiceFlowBlock
  | NPSFlowBlock
  | GifUploadFlowBlock
  | FileUploadFlowBlock
  | GiveTrophiesStackFlowBlock;

export type FlowBlockContentPayload =
  | Exclude<FlowBlockContent, PersonSelectorFlowBlock>
  | PersonSelectorFlowBlockPayload;

export type SaveFlowPayload = {
  name: string;
  kind?: TriggerType;
  description?: string;
  endTimeInMinutes: number;
  schedule?: { rule: string };
  shortcut: boolean;
  ownerId?: string;
  icon?: {
    kind: string;
    value: string;
  };
  action: {
    kind: 'FORM' | 'MESSAGE';
    templateId?: string;
    blocks: FlowBlockContentPayload[];
  };
  folderId?: string;
  collaborators?: string[];
  numberOfResponses?: number;
  reminder:
    | {
        type: 'MANUAL';
      }
    | {
        type: 'AUTOMATED';
        rrule: string;
      }
    | {
        type: 'AUTOMATED';
        numberOfReminders: number | null;
      };
  metaData: {
    isEndTimeInMinutesUpdated?: boolean;
    responseFrequencyType?: ResponseFrequencyType;
  };
  allowMemberLevelOccurrence: boolean;
  milestoneSettings?: MilestoneSettings;
};
