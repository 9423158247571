import type { SerializedEditorState, SerializedElementNode } from 'lexical';

export const cleanEditorState = ({
  html,
  json: editorState,
  plainText,
}: {
  html: string;
  plainText: string;
  json: string;
}) => {
  // regex explanation: https://regexr.com/7si4d
  html = html.replace(
    /((^<p><\/p>)|(^<p><br><\/p>))(<p><br><\/p>)+|(?:(<p><br><\/p>)+)?(?:(<p><\/p>$)|(<p(?: dir="ltr")?><br><\/p>$))/gm,
    ''
  );
  plainText = plainText.trim();

  const json = (() => {
    try {
      let json = JSON.parse(editorState) as SerializedEditorState;
      json = {
        ...json,
        root: {
          ...json.root,
          children: (() => {
            let start = 0;
            let tail = json.root.children.length - 1;
            while (
              start < json.root.children.length &&
              json.root.children[start].type === 'paragraph' &&
              (json.root.children[start] as SerializedElementNode).children
                .length === 0
            ) {
              start++;
            }

            while (
              tail >= 0 &&
              json.root.children[tail].type === 'paragraph' &&
              (json.root.children[tail] as SerializedElementNode).children
                .length === 0
            ) {
              tail--;
            }

            return json.root.children.slice(start, tail + 1);
          })(),
        },
      };

      return JSON.stringify(json);
    } catch {
      return editorState;
    }
  })();

  return { html, json, plainText };
};

export function removeNewLinesAndTabs(plainText: string) {
  return plainText.replace(/(\r\n|\n|\r|\t)/gm, '');
}
