import type { PopoverContentProps } from '@radix-ui/react-popover';
import { Content, Portal, Root, Trigger } from '@radix-ui/react-popover';
import type { ButtonHTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Tooltip } from '../../DesignSystem/Feedback/Tooltip';

export function Popover({
  children,
  open,
  onCloseAutoFocus,
  onOpenAutoFocus,
  onOpenChange,
  trigger,
  onTriggerButtonClick,
  modal = true,
  side = 'bottom',
  align = 'center',
  contentClassName,
  tooltipText,
  tooltipTextClassName,
  triggerRef,
  container = document.body,
  collisionBoundary = document.body,
  ...rest
}: {
  trigger: ReactNode;
  children: ReactNode;
  open: boolean;
  onOpenAutoFocus?: (event: Event) => void;
  onCloseAutoFocus?: (event: Event) => void;
  onOpenChange: (open: boolean) => void;
  onTriggerButtonClick?: () => void;
  modal?: boolean;
  side?: PopoverContentProps['side'];
  align?: PopoverContentProps['align'];
  contentClassName?: string;
  tooltipText?: ReactNode;
  tooltipTextClassName?: string;
  triggerRef?: React.RefObject<HTMLButtonElement>;
  container?: HTMLElement | null;
  collisionBoundary?: Element | Element[];
} & ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <Root open={open} onOpenChange={onOpenChange} modal={modal}>
      <Tooltip tooltipText={tooltipText} textClassName={tooltipTextClassName}>
        <Trigger
          ref={triggerRef}
          onClick={onTriggerButtonClick}
          className="relative"
          {...rest}
        >
          {trigger}
        </Trigger>
      </Tooltip>
      <Portal container={container}>
        <Content
          align={align}
          sideOffset={4}
          side={side}
          collisionBoundary={collisionBoundary}
          className={twMerge(
            'radix-side-top:animate-slide-up radix-side-bottom:animate-slide-down z-50 w-full rounded-lg bg-gray-1 p-4 shadow-md-down',
            contentClassName
          )}
          onOpenAutoFocus={onOpenAutoFocus}
          onCloseAutoFocus={onCloseAutoFocus}
        >
          {children}
        </Content>
      </Portal>
    </Root>
  );
}
