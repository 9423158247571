import {
  queryOptions,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query';

import type { GetPostResponse } from '../..';
import { APIEndpoints } from '../../APIEndpoints';
import { assemblyAPI } from '../../assemblyAPI';

const getRecognitionPostQueryOptions = ({
  options,
  postId,
}: {
  postId: string;
  options?: Partial<UseSuspenseQueryOptions<GetPostResponse>>;
}) => {
  return queryOptions({
    ...options,
    queryKey: ['recognition', postId],
    queryFn: async () => {
      const response = await assemblyAPI.get<GetPostResponse>(
        APIEndpoints.getRecognitionPost(postId)
      );
      return response.data;
    },
    refetchOnWindowFocus: 'always',
    refetchOnMount: false,
  });
};

export function useGetRecognitionPost({
  options,
  postId,
}: {
  postId: string;
  options?: Partial<UseSuspenseQueryOptions<GetPostResponse>>;
}) {
  return useSuspenseQuery(getRecognitionPostQueryOptions({ postId, options }));
}
