import { useCallback, useEffect, useRef, useState } from 'react';

import { TextStyle, type TextStyleProps } from './TextStyle';
import { Tooltip } from './Tooltip';

type OverflowTextProps = TextStyleProps & {
  tooltipText?: string;
  tooltipClassName?: string;
};

function OverflowTextImpl(props: OverflowTextProps) {
  const { tooltipText, tooltipClassName, ...propsWithContent } = props;

  const [showTooltip, setShowTooltip] = useState(false);
  const textRef = useRef<HTMLParagraphElement | null>(null);

  const checkIfTextOverflowing = useCallback(
    (parentElement?: HTMLParagraphElement) => {
      const parent = parentElement ?? textRef.current?.parentElement;

      const fullWidth = textRef.current?.getBoundingClientRect().width ?? 0;
      const displayWidth = parent?.getBoundingClientRect().width ?? 0;

      const fullHeight = textRef.current?.getBoundingClientRect().height ?? 0;
      const displayHeight = parent?.getBoundingClientRect().height ?? 0;

      const isOverflowing =
        fullWidth > displayWidth || fullHeight > displayHeight;
      return isOverflowing;
    },
    []
  );

  useEffect(() => {
    function handler() {
      setShowTooltip(checkIfTextOverflowing());
    }

    window.addEventListener('resize', handler);

    return () => {
      window.removeEventListener('resize', handler);
      textRef.current?.remove();
    };
  }, [checkIfTextOverflowing]);

  const textMountCallback = useCallback(
    (e: HTMLParagraphElement | null) => {
      if (!e) {
        if (textRef.current) {
          textRef.current.remove();
          textRef.current = null;
        }
        return;
      }
      textRef.current = e.cloneNode(true) as HTMLParagraphElement;

      textRef.current.className = '';
      textRef.current.style.position = 'fixed';
      textRef.current.style.overflow = 'visible';
      textRef.current.style.whiteSpace = 'nowrap';
      textRef.current.style.visibility = 'hidden';

      e.parentNode?.appendChild(textRef.current);

      setShowTooltip(checkIfTextOverflowing(e));
    },
    [checkIfTextOverflowing]
  );

  return (
    <Tooltip
      tooltipText={Boolean(showTooltip) && tooltipText}
      contentClassName={tooltipClassName}
    >
      <TextStyle {...propsWithContent} ref={textMountCallback} />
    </Tooltip>
  );
}

export function OverflowText({ className, ...tail }: OverflowTextProps) {
  return <OverflowTextImpl {...tail} className={className} key={className} />;
}
