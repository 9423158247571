import * as SliderImpl from '@radix-ui/react-slider';
import { defineMessages, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { Tooltip } from '../../DesignSystem/Feedback/Tooltip';

const messages = defineMessages({
  adjustZoomLevel: {
    id: 'hEhE3v',
    defaultMessage: 'Adjust zoom level',
  },
  zoomLevel: {
    id: '6GminY',
    defaultMessage: 'Zoom level',
  },
});

export function Slider({
  setScaleValue,
  scaleValue,
  minZoomLevel = 0.5,
  maxZoomLevel = 3,
  zoomStep = 0.25,
  variant = 'primary',
}: {
  setScaleValue: (value: number) => void;
  scaleValue: number;
  minZoomLevel?: number;
  maxZoomLevel?: number;
  zoomStep?: number;
  variant?: 'primary' | 'secondary';
}) {
  const { formatMessage } = useIntl();

  return (
    <SliderImpl.Root
      className="relative flex h-2 w-20 touch-none select-none items-center"
      min={minZoomLevel}
      max={maxZoomLevel}
      step={zoomStep}
      value={[scaleValue]}
      onValueChange={(values) => setScaleValue(values[0])}
    >
      <SliderImpl.Track className="relative h-0.5 grow rounded-full bg-gray-5">
        <SliderImpl.Range className="absolute h-full rounded-full bg-gray-1" />
      </SliderImpl.Track>
      <Tooltip tooltipText={formatMessage(messages.adjustZoomLevel)}>
        <SliderImpl.Thumb
          className={twMerge(
            `block h-2 w-2 rounded-full shadow-[0_1px_8px] shadow-gray-8 focus:shadow-[0_0_0_3px] focus:outline-none`,
            variant === 'primary' && 'bg-primary-5 focus:shadow-primary-7',
            variant === 'secondary' && 'bg-gray-5 focus:shadow-gray-7'
          )}
          aria-label={formatMessage(messages.zoomLevel)}
        />
      </Tooltip>
    </SliderImpl.Root>
  );
}
