import * as ReactContextMenu from '@radix-ui/react-context-menu';
import type { ReactNode } from 'react';

import { TextStyle } from '../../DesignSystem/Feedback/TextStyle';
import { Icon, type IconNode } from './Icon';

type ContextMenuProps = {
  trigger: ReactNode;
  menuItems: {
    id: string;
    text: string;
    icon: IconNode;
  }[];
  onMenuItemClick: (id: string) => void;
};

export function ContextMenu({
  trigger,
  menuItems,
  onMenuItemClick,
}: ContextMenuProps) {
  return (
    <ReactContextMenu.Root>
      <ReactContextMenu.Trigger>{trigger}</ReactContextMenu.Trigger>
      <ReactContextMenu.Portal>
        <ReactContextMenu.Content className="w-52 overflow-hidden rounded-2xl bg-gray-1 shadow-xl-down ring-opacity-5 focus:outline-none">
          {menuItems.map((item) => (
            <ReactContextMenu.Item
              className="group flex h-10 w-full cursor-pointer select-none items-center gap-2 rounded-lg px-4 py-2 hover:bg-gray-3 focus:outline-none data-[highlighted]:bg-gray-3 data-[highlighted]:text-gray-5"
              key={item.id}
              onClick={() => onMenuItemClick(item.id)}
            >
              <Icon
                icon={item.icon}
                className="h-4 w-4 stroke-2 group-hover:text-gray-10"
              />
              <TextStyle subdued>{item.text}</TextStyle>
            </ReactContextMenu.Item>
          ))}
        </ReactContextMenu.Content>
      </ReactContextMenu.Portal>
    </ReactContextMenu.Root>
  );
}
