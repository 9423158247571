import { QuickParticipationEditor } from '../Editors/QuickParticipation/QuickParticipation';
import { Block } from './Block';
import { BlockList } from './BlockList';
import { Footer } from './Footer';
import { Label } from './Label';
import { Modal } from './Modal';
import { ItemWrapper, MemberItem } from './Option';

export const QuickParticipation = {
  Block,
  BlockList,
  Editor: QuickParticipationEditor,
  Footer,
  Label,
  Modal,
  ItemWrapper,
  MemberItem,
};
