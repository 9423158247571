import {
  ArrowLeftIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import {
  CalendarDate,
  getLocalTimeZone,
  parseDate,
  today,
} from '@internationalized/date';
import { cva } from 'class-variance-authority';
import dayjs from 'dayjs';
import type { ChangeEvent, ReactElement } from 'react';
import { useState } from 'react';
import {
  Button as ReactAriaButton,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  Heading,
  RangeCalendar,
} from 'react-aria-components';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TextStyle } from '../Feedback/TextStyle';
import { Button } from '../Inputs/Button';
import { IconButton } from '../Inputs/IconButton';
import { messages } from './messages';

export type SelectedOption = {
  id: string;
  value?: {
    end: string;
    start: string;
  };
};

export type DateRangePickerProps = {
  minDate?: Date;
  maxDate?: Date;
  onClearAllClick?: () => void;
  selectedOption?: SelectedOption | null;
  onChange?: (selectedOption: SelectedOption | null) => void;
  showFooter?: boolean;
  datePicker?: ReactElement;
  onOptionSelect?: (e: string) => void;
  onClose?: () => void;
  onSetShowCalendar?: (showCalendar: boolean) => void;
  className?: {
    title?: string;
    header?: string;
  };
  duration?: string;
};

export const options = [
  { id: 'today', label: messages.todayLabel },
  { id: 'yesterday', label: messages.yesterdayLabel },
  { id: 'last7Days', label: messages.last7DaysLabel },
  { id: 'last30Days', label: messages.last30DaysLabel },
  { id: 'custom', label: messages.customLabel },
];

const calendarCellClassName = cva(
  'flex h-6 w-6 items-center justify-center text-gray-9 text-sm',
  {
    variants: {
      isSelected: {
        true: '',
      },
      isDisabled: {
        true: '!text-gray-7 cursor-not-allowed',
      },
      isSelectionStart: {
        true: 'bg-primary-6 rounded-l-xl text-gray-1',
      },
      isSelectionEnd: {
        true: 'bg-primary-6 text-gray-1 rounded-r-xl',
      },
      isOutsideMonth: {
        true: '!text-gray-7',
      },
    },
    compoundVariants: [
      {
        isSelected: true,
        isSelectionStart: true,
        className: 'bg-primary-2 !text-gray-1',
      },
      {
        isSelected: true,
        isSelectionEnd: true,
        className: 'bg-primary-2 !text-gray-1',
      },
      {
        isSelected: true,
        isSelectionEnd: false,
        isSelectionStart: false,
        className: 'bg-primary-2 text-primary-6',
      },
    ],
  }
);

export function DateRangePicker({
  minDate,
  maxDate,
  onChange,
  onClearAllClick,
  showFooter = true,
  selectedOption: defaultSelectedOption,
  datePicker,
  onOptionSelect,
  onClose,
  className,
  onSetShowCalendar,
  duration,
}: DateRangePickerProps) {
  const parsedMaxDate = maxDate
    ? new CalendarDate(
        maxDate.getFullYear(),
        maxDate.getMonth() + 1,
        maxDate.getDate()
      )
    : undefined;

  const parsedMinDate = minDate
    ? new CalendarDate(
        minDate.getFullYear(),
        minDate.getMonth() + 1,
        minDate.getDate()
      )
    : undefined;

  let [value, setValue] = useState({
    end: parseDate(
      defaultSelectedOption?.value?.end ?? today(getLocalTimeZone()).toString()
    ),
    start: parseDate(
      defaultSelectedOption?.value?.start ??
        today(getLocalTimeZone()).toString()
    ),
  });

  const { formatMessage } = useIntl();

  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    defaultSelectedOption?.id
  );

  const handleSetShowCalendar = (showCalendar: boolean) => {
    setShowCalendar(showCalendar);
    onSetShowCalendar?.(showCalendar);
  };

  const onBackButtonClick = () => {
    handleSetShowCalendar(false);
    if (datePicker) {
      setValue({
        end: parseDate(
          defaultSelectedOption?.value?.end ??
            today(getLocalTimeZone()).toString()
        ),
        start: parseDate(
          defaultSelectedOption?.value?.start ??
            today(getLocalTimeZone()).toString()
        ),
      });
    }
  };

  const onOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    onOptionSelect?.(e.target.value);
    setSelectedOption(e.target.value);
    handleSetShowCalendar(e.target.value === 'custom');
  };

  return (
    <section className="flex w-svw flex-col py-1 md:w-full md:min-w-[280px]">
      {!showCalendar ? (
        <div>
          {options.map((option) => (
            <div
              key={option.id}
              className="flex h-[40px] w-full items-center gap-2 rounded-md px-3 py-2 outline-none hover:rounded-none hover:bg-gray-2 focus:bg-gray-2"
            >
              <input
                type="radio"
                id={option.id}
                name="option"
                value={option.id}
                onChange={onOptionChange}
                className="cursor-pointer"
                checked={selectedOption === option.id}
                onClick={() => {
                  handleSetShowCalendar(option.id === 'custom');
                }}
              />
              <div className="flex w-full items-center justify-between">
                <label
                  htmlFor={option.id}
                  className="w-full cursor-pointer text-sm font-normal text-gray-8"
                >
                  {formatMessage(option.label)}
                  {option.id === 'custom' && (
                    <>
                      {duration !== undefined
                        ? duration.length
                          ? `: ${duration}`
                          : duration
                        : selectedOption === option.id &&
                          `: ${dayjs(value.start.toString()).format(
                            'MM-DD-YYYY'
                          )} - ${dayjs(value.end.toString()).format(
                            'MM-DD-YYYY'
                          )}`}
                    </>
                  )}
                </label>
                {option.id === 'custom' && (
                  <ChevronRightIcon className="ml-3 h-3 w-3 cursor-pointer text-gray-8" />
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={twMerge('pt-1', className?.header)}>
          <div>
            <div className="flex px-2">
              <IconButton
                size="xSmall"
                variation="tertiaryLite"
                onClick={onBackButtonClick}
              >
                <ArrowLeftIcon className="h-4 w-4" aria-hidden="true" />
              </IconButton>
              <TextStyle
                className={twMerge('ml-2 text-gray-9', className?.title)}
              >
                {datePicker
                  ? formatMessage(messages.customDateRange)
                  : formatMessage(messages.customLabel)}
              </TextStyle>
              {Boolean(onClose) && (
                <IconButton
                  size="xSmall"
                  variation="tertiaryLite"
                  className="absolute right-2 top-2"
                  onClick={onClose}
                >
                  <XMarkIcon className="h-4 w-4 stroke-gray-8" />
                </IconButton>
              )}
            </div>
            {datePicker ?? (
              <RangeCalendar
                value={value}
                onChange={(e) => {
                  if (!e) {
                    return;
                  }
                  setValue(e);
                  setSelectedOption('custom');
                }}
                minValue={parsedMinDate}
                maxValue={parsedMaxDate}
                visibleDuration={{ months: 2 }}
                allowsNonContiguousRanges={false}
                className="flex flex-col items-center justify-center px-2"
              >
                <header className="mb-3 mt-2 flex items-center">
                  <ReactAriaButton slot="previous">
                    <ChevronDoubleLeftIcon className="h-4 w-4" />
                  </ReactAriaButton>
                  <Heading className="mx-2 flex w-full min-w-[200px] justify-center text-sm font-medium text-gray-9" />
                  <ReactAriaButton slot="next">
                    <ChevronDoubleRightIcon className="h-4 w-4" />
                  </ReactAriaButton>
                </header>
                <div className="flex gap-2">
                  <div className="rounded-lg border-2 border-gray-5 p-2">
                    <CalendarGrid>
                      <CalendarGridHeader>
                        {(day) => (
                          <CalendarHeaderCell className="text-gray-9">
                            {day}
                          </CalendarHeaderCell>
                        )}
                      </CalendarGridHeader>
                      <CalendarGridBody>
                        {(date) => (
                          <CalendarCell
                            date={date}
                            className={({
                              isDisabled,
                              isSelected,
                              isSelectionEnd,
                              isOutsideMonth,
                              isSelectionStart,
                            }) =>
                              calendarCellClassName({
                                isDisabled,
                                isSelected,
                                isOutsideMonth,
                                isSelectionEnd,
                                isSelectionStart,
                              })
                            }
                          />
                        )}
                      </CalendarGridBody>
                    </CalendarGrid>
                  </div>
                  <div className="rounded-lg border-2 border-gray-5 p-2">
                    <CalendarGrid offset={{ months: 1 }}>
                      <CalendarGridHeader>
                        {(day) => (
                          <CalendarHeaderCell className="text-gray-9">
                            {day}
                          </CalendarHeaderCell>
                        )}
                      </CalendarGridHeader>
                      <CalendarGridBody>
                        {(date) => (
                          <CalendarCell
                            date={date}
                            className={({
                              isDisabled,
                              isSelected,
                              isSelectionEnd,
                              isOutsideMonth,
                              isSelectionStart,
                            }) =>
                              calendarCellClassName({
                                isDisabled,
                                isSelected,
                                isOutsideMonth,
                                isSelectionEnd,
                                isSelectionStart,
                              })
                            }
                          />
                        )}
                      </CalendarGridBody>
                    </CalendarGrid>
                  </div>
                </div>
              </RangeCalendar>
            )}
          </div>
        </div>
      )}
      {Boolean(showFooter) && (
        <footer className="mb-2 mt-3 flex gap-2 px-2">
          <Button
            variation="secondaryEmphasized"
            className="flex-1"
            onClick={() => {
              if (selectedOption) {
                onChange?.({
                  id: selectedOption,
                  value: {
                    end: value.end.toString(),
                    start: value.start.toString(),
                  },
                });
              } else {
                onChange?.(null);
              }
            }}
            size="small"
          >
            {formatMessage(messages.doneButtonLabel)}
          </Button>
          <Button
            variation="secondaryLite"
            disabled={!selectedOption}
            onClick={() => {
              setValue({
                end: parseDate(today(getLocalTimeZone()).toString()),
                start: parseDate(today(getLocalTimeZone()).toString()),
              });
              onClearAllClick?.();
              setSelectedOption(undefined);
            }}
            size="small"
          >
            {formatMessage(messages.clearAllButtonLabel)}
          </Button>
        </footer>
      )}
    </section>
  );
}
