export function RecognitionParticipationLoader() {
  return (
    <div className="border-blue-300 shadow mx-auto h-full w-full px-6 pb-4">
      <div className="flex flex-col gap-6">
        {[...Array(3)].map((item, index) => {
          return (
            <div
              className="flex w-full animate-pulse flex-col gap-4"
              key={index}
            >
              <div className="bg-slate-200 h-4 w-44 rounded-lg bg-gray-4"></div>
              <div className="bg-slate-200 h-6 w-16 rounded-full bg-gray-4"></div>
            </div>
          );
        })}
        <div className="flex w-full flex-col gap-4">
          <div className="bg-slate-200 h-4 w-44 animate-pulse rounded-full bg-gray-4"></div>
          <div className="h-[150px] w-full rounded-lg border border-gray-5 p-4">
            <div className="bg-slate-200 h-4 w-full animate-pulse rounded-full bg-gray-4"></div>
          </div>
        </div>
        <div className="flex animate-pulse items-center justify-between">
          <div className="bg-slate-200 h-4 w-44 rounded-lg bg-gray-4"></div>
          <div className="bg-slate-200 h-8 w-40 rounded-lg bg-gray-4"></div>
        </div>
      </div>
    </div>
  );
}
