import type { PropsWithChildren } from 'react';

export function Label({
  children,
  htmlFor,
}: PropsWithChildren & { htmlFor?: string }) {
  return (
    <label className="text-sm font-medium text-gray-9" htmlFor={htmlFor}>
      {children}
    </label>
  );
}
