import { PencilIcon } from '@heroicons/react/24/outline';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { TextStyle } from '../../../DesignSystem/Feedback/TextStyle';

export type EditBannerProps = {
  show: boolean;
};

export function EditBanner({ show }: EditBannerProps) {
  if (!show) {
    return null;
  }
  return (
    <div className="mx-6 flex gap-2 rounded-lg bg-primary-2 p-2">
      <div className="h-full self-center">
        <PencilIcon className="h-6 w-6" />
      </div>
      <TextStyle variant="sm-regular">
        <FormattedMessage
          defaultMessage="We all make mistakes! However, you can only edit the <b>core value</b> or <b>message</b>. Recipients and points cannot be edited."
          id="GLkXu/"
          values={{
            b: (chunks: ReactNode) => {
              return <span className="font-bold">{chunks}</span>;
            },
          }}
        />
      </TextStyle>
    </div>
  );
}
