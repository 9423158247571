import {
  createContext,
  type Dispatch,
  type SetStateAction,
  useContext,
} from 'react';

const RefContext = createContext<HTMLElement | null>(null);
const RefSetterContext = createContext<
  [HTMLElement | null, Dispatch<SetStateAction<HTMLElement | null>>] | null
>(null);

export const RefProvider = RefContext.Provider;
export const RefSetterProvider = RefSetterContext.Provider;

export const useRefContainer = () => {
  return useContext(RefContext);
};

export const useRefSetterContext = () => {
  return useContext(RefSetterContext);
};
