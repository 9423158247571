import {
  Modal as ModalImpl,
  type ModalProps as ModalImplProps,
} from '../../../DesignSystem/Feedback/Modal';

type ModalProps = Pick<
  ModalImplProps,
  'children' | 'onClose' | 'isOpen' | 'title'
>;

export const Modal = (props: ModalProps) => {
  return (
    <ModalImpl
      headerClassName="py-4 px-6"
      bodyClassName="p-0 flex flex-col gap-2 overflow-hidden flex-1 isolate"
      headerSeparatorClassName="px-0"
      className="flex !w-[min(100vw,571px)] flex-col overflow-hidden rounded-lg sm:max-w-[unset]"
      {...props}
    />
  );
};
