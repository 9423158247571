import {
  type FileUploadMetaData,
  type FileUploadOptions,
  getFileExtensionsForMediaTypes,
} from '@assembly-web/services';
import AwsS3 from '@uppy/aws-s3';
import { Uppy } from '@uppy/core';
import ImageEditor from '@uppy/image-editor';
import { create } from 'zustand';

import {
  defaultCroppedCanvasOptions,
  defaultCropperActions,
  defaultCropperOptions,
  defaultUploadOptions,
} from './config';

type UppyStore = {
  uppyInstances: Record<string, Uppy<FileUploadMetaData> | undefined>;
  createOrGetUppyInstance: (
    options: Omit<FileUploadOptions, 'title' | 'subTitle'>
  ) => Uppy<FileUploadMetaData>;
  clearUppyInstance: (key: string) => void;
};

export const useUppyStore = create<UppyStore>((set, get) => ({
  uppyInstances: {},
  clearUppyInstance: (key) => {
    set((state) => {
      const currentInstances = state.uppyInstances;
      if (currentInstances[key]) {
        currentInstances[key] = undefined;
      }
      return {
        uppyInstances: currentInstances,
      };
    });
  },
  createOrGetUppyInstance: (
    options: Omit<FileUploadOptions, 'title' | 'subTitle'>
  ) => {
    const { key } = options;
    const currentInstances = get().uppyInstances;
    if (currentInstances[key]) {
      return currentInstances[key];
    }

    const {
      getUploadUrlForFile,
      uploadOptions: userUploadOptions = {},
      cropperOptions: userCropperOptions = {},
    } = options;

    const uploadOptions = {
      ...defaultUploadOptions,
      ...userUploadOptions,
    };

    const mergedCroppedCanvasOptions = {
      ...defaultCroppedCanvasOptions,
      ...userCropperOptions.croppedCanvasOptions,
    };

    const mergedCropperOptions = {
      ...defaultCropperOptions,
      ...userCropperOptions,
      croppedCanvasOptions: mergedCroppedCanvasOptions,
    };

    const mergedCropperActions = {
      ...defaultCropperActions,
      ...userCropperOptions.cropperActions,
    };

    const newInstance = new Uppy<FileUploadMetaData>({
      restrictions: {
        maxFileSize: uploadOptions.maxFileSize,
        maxNumberOfFiles: uploadOptions.maxNumberOfFiles,
        allowedFileTypes: getFileExtensionsForMediaTypes(
          uploadOptions.allowedFileTypes
        ),
        maxTotalFileSize: uploadOptions.maxTotalFileSize,
      },
      autoProceed: uploadOptions.autoUpload,
      allowMultipleUploadBatches: true,
      allowMultipleUploads: true,
    })
      .use(ImageEditor, {
        quality: 0.8,
        actions: mergedCropperActions,
        cropperOptions: mergedCropperOptions,
      })
      .use(AwsS3, {
        shouldUseMultipart: false,
        getUploadParameters: async (file) => {
          const { uploadUrl, location } = await getUploadUrlForFile(
            file.name ?? ''
          );

          file.meta.location = location;

          return {
            method: 'PUT',
            url: uploadUrl as string,
            headers: {
              'Content-Type': file.type,
            },
          };
        },
      });

    set((state) => ({
      uppyInstances: {
        ...state.uppyInstances,
        [key]: newInstance,
      },
    }));
    return newInstance;
  },
}));
